import $axiosProtected from '../../../common/utils/axiosUtils';

export const getDoctorDetailsById = async (payload: any) => {
    return await $axiosProtected().post('/doctor/id', payload);
};

// preferred_username to be post
export const getDoctorDetailsByUsername = async (payload: any) => {
    return await $axiosProtected().post('/doctor/username', payload);
};
