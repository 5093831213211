import { useEffect, useMemo, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import toast, { Toaster } from 'react-hot-toast';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import {
  categoryValidationSchema,
  initalValues,
} from '../models/CategoryModel';
import useMutateRequest from '../../../../hooks/getMutation.query';
import { createCourseCategory } from '../../../../services/web-content/WebContent.hook';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../../common/utils/genericErrors';

const CategoryPage = () => {
  // Toast
  const [open, setOpen] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [msg, setMsg] = useState<boolean>(false);

  const { isLoading, isError, data, error, mutate, isSuccess }: any =
    useMutateRequest((data: any) => createCourseCategory(data));

  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error?.response?.data?.message]
  );

  const successToastMsg = () => {
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          {/* {successMsg} */}
          Successfully Created Course Category!!
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'success-toast',
      }
    );
  };

  const errorToastMsg = () => {
    toast.error(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
          {errorMsg}
          {/* Failed to Create Focused Group !! */}
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'error-toast',
      }
    );
  };

  const onSubmit = (values: any) => {
    // console.log(values);
    try {
      // Remove double quotes
      const payload = {
        categoryName: values.categoryName,
      };

      mutate(payload, {
        onSuccess(data: any) {
          setSpinner(true);
          setOpen(true);
          setMsg(true);

          setTimeout(() => {
            setSpinner(false);
            // redirectTo.push("/training/content-management");
          }, 3000);
        },
        onError(error: any) {
          setSpinner(true);
          setOpen(true);
          setMsg(false);

          setTimeout(() => {
            setSpinner(false);
            // redirectTo.push("/training/content-management");
          }, 3000);
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster />
      {isSuccess && open === true && successToastMsg()}
      {isError && open === true && errorToastMsg()}

      <div>
        <div className="d-flex justify-content-center">
          <h1 className="fs-1 fw-bolder m-0">Create Course Category</h1>
        </div>
        <hr className="h-2px" />

        {isLoading || spinner === true ? (
          <div className="card w-100 h-250px d-flex flex-column align-items-center justify-content-center p-15">
            <ClipLoader size={70} speedMultiplier={0.6} />
          </div>
        ) : (
          <div className="">
            <Formik
              initialValues={initalValues}
              validationSchema={categoryValidationSchema}
              onSubmit={onSubmit}
            >
              {(formProps) => {
                return (
                  <Form>
                    <div className="p-5">
                      {/* Course Category Name*/}
                      <div className="fv-row mb-10">
                        <label className="fw-bolder form-label required">
                          Course Category Name
                        </label>

                        <Field
                          name="categoryName"
                          placeholder="ex: Training"
                          className="form-control form-control-lg form-control-solid"
                        />
                        <div className="text-danger mt-2">
                          <ErrorMessage name="categoryName" />
                        </div>
                      </div>

                      <div className="mt-5 d-flex align-items-center justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-md btn-primary w-250px"
                        >
                          Create Course Category
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default CategoryPage;
