import PatientCounterPage from './PatientCounterPage';
import { PatientTable } from './table/PatientTable';
import { useEffect, useMemo, useState } from 'react';
import { useNavbar } from '../../context/NavbarContext';
import useGetRequest from '../../hooks/getRequest.query';
import { getAllCHWByFacility } from '../../services/focused-group/FocusedGroup.hook';
import { getAllPatient } from '../../services/patient/PatientManagement.hook';
import { convertToUtcTimestamp } from '../../common/utils';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../common/utils/genericErrors';

const PatientPage = () => {
  const { selectedFacility } = useNavbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const [filterData, setFilterData]: any = useState({
    status: '',
    fromDate: '',
    toDate: '',
    chwId: '',
    patientType: '',
    name: '',
  });

  const facilityCode = localStorage.getItem('facilityCode');

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    isError,
    error: ErrorMsg,
  }: any = useGetRequest(
    ['getAllPatient', pageNumber, pageLimit],
    async () =>
      await getAllPatient({
        facilityCode,
        page: pageNumber,
        limit: pageLimit,
        status: filterData?.status,
        patientType: filterData?.patientType,
        fromDate:
          filterData?.fromDate !== ''
            ? convertToUtcTimestamp(filterData?.fromDate)
            : '',
        toDate:
          filterData?.toDate !== ''
            ? convertToUtcTimestamp(filterData?.toDate)
            : '',
        chwId: filterData?.chwId,
        name: filterData?.name,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const totalPatientsCount = data?.data?.results;
  const patientData = data?.data?.data?.patients;
  const totalAvailablePages = Math.ceil(totalPatientsCount / pageLimit);
  const patientErrorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(ErrorMsg?.response?.data?.message)
        ? getErrorMessage(ErrorMsg?.response?.status)
        : ErrorMsg?.response?.data?.message,
    [ErrorMsg]
  );

  const {
    data: chwList,
    refetch: chwRefetch,
    isFetching: isCHWFetching,
  }: any = useGetRequest(
    'allCHWData',
    async () =>
      await getAllCHWByFacility({
        facilityCode,
        page: 1,
        limit: 20,
      }),
    {
      retry: false,
      // staleTime: 100000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const chwData = chwList?.data?.data?.chw;

  useEffect(() => {
    refetch();
    chwRefetch();
  }, [filterData, selectedFacility]);

  return (
    <>
      <div>
        <PatientCounterPage
          isLoading={isLoading}
          isFetching={isFetching}
          totalPatients={totalPatientsCount}
        />
      </div>
      <div className="mt-5">
        <PatientTable
          filterData={filterData}
          pageLimit={pageLimit}
          currentPageNumber={pageNumber}
          handlePageNumber={setPageNumber}
          totalPatientEntites={totalPatientsCount}
          totalPages={totalAvailablePages}
          isPatientLoader={isLoading}
          isPatientFetcher={isFetching}
          isPatientError={isError}
          patientErrorMsg={patientErrorMsg}
          bodyProps={patientData}
          setFilterData={setFilterData}
          chwData={chwData}
          isCHWFetching={isCHWFetching}
          setPageLimit={setPageLimit}
          setPageNumber={setPageNumber}
        />
      </div>
    </>
  );
};

export default PatientPage;
