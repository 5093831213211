import { useState } from 'react';
import BookingDetails from './components/appointment-details/BookingDetails';
import PatientDetails from './components/appointment-details/PatientDetails';
import Vitals from './components/appointment-details/Vitals';
import PrescriptionModal from './components/appointment-details/PrescriptionModal';

function AppointmentDetails() {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isPrescriptionFormOpen, setIsPrescriptionFormOpen] =
        useState<boolean>(false);
    const handleModal = () => {
        setIsModalOpen((prev) => !prev);
    };
    const openPrescription = () => {
        setIsPrescriptionFormOpen(true);
        // setIsModalOpen(false);
    };
    const closePrescription = () => {
        setIsPrescriptionFormOpen(false);
    };
    return (
        <div
            data-testid="appointment-container"
            className="overflow-hidden d-flex flex-column gap-5 bg-white rounded"
        >
            {isModalOpen && (
                <PrescriptionModal
                    handleModal={handleModal}
                    openPrescription={openPrescription}
                />
            )}

            {!isPrescriptionFormOpen && (
                <>
                    <PatientDetails id={'27951545324524'} />
                    <Vitals />
                    <BookingDetails />
                    <div className="d-flex justify-content-end gap-5 pb-10 pe-5">
                        {/* <button className="btn btn-light-danger btn-md">
                            Cancel
                        </button> */}
                        <button
                            className="btn btn-primary btn-md"
                            onClick={openPrescription}
                        >
                            Add prescription
                        </button>
                    </div>
                </>
            )}
            {/* {isPrescriptionFormOpen && !isModalOpen && (
                <PrescriptionForm onCancel={closePrescription} />
            )} */}
        </div>
    );
}

export default AppointmentDetails;
