import { useEffect, useMemo, useState } from 'react';
import { useNavbar } from '../../context/NavbarContext';
import useGetRequest from '../../hooks/getRequest.query';
import useSearchParams from '../../hooks/useSearchParams';
import { getHealthCards } from '../../services/HealthCard.hook';

import HealthCardTable from './table/HealthCardTable';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../common/utils/genericErrors';

const HealthCardPage = () => {
  const { selectedFacility } = useNavbar();
  const { batchId: id }: any = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit] = useState(10);

  const {
    data,
    isLoading,
    isFetching,
    isError: isHealthCardError,
    error: HealthCardError,
    refetch,
  }: any = useGetRequest(
    ['getHealthCardsData', pageNumber, id],
    () =>
      getHealthCards({
        batchId: id,
        page: pageNumber,
        limit: pageLimit,
      }),
    { staleTime: 100000, refetchOnWindowFocus: false, enabled: !!id }
  );

  const healthCardsData = useMemo(() => data?.data?.data?.healthCards, [data]);
  const totalHealthCardEntites = data?.data?.results;
  const totalAvailablePages = Math.ceil(totalHealthCardEntites / pageLimit);
  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(HealthCardError?.response?.data?.message)
        ? getErrorMessage(HealthCardError?.response?.status)
        : HealthCardError?.response?.data?.message,
    [HealthCardError?.response?.data?.message]
  );
  const successMsg = data?.data?.message;

  useEffect(() => {
    if (id) refetch();
  }, [id, refetch]);

  return (
    <div className="card mb-5 mb-xl-10">
      <HealthCardTable
        bodyProps={healthCardsData}
        isLoading={isLoading}
        isFetching={isFetching}
        isHealthCardError={isHealthCardError}
        errorMsg={errorMsg}
        successMsg={successMsg}
        currentPageNumber={pageNumber}
        totalHealthCardPages={totalAvailablePages}
        totalHealthCardEntites={totalHealthCardEntites}
        pageLimit={pageLimit}
        handlePageNumber={setPageNumber}
      />
    </div>
  );
};

export default HealthCardPage;
