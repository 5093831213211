import React from 'react';
import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../../../components/Table';
import { capitalize } from 'lodash';

type Props = {
  productsArr: any[];
  isError: boolean;
  error: string | undefined;
};
const tableNames = [
  'Product',
  'Manufacturer',
  'Unit/Strip',
  'Strip Price',
  'Total Strips',
  'Amount',
];

const checkEmpty = (str: string | number) => {
  return str ? str : '--';
};
const PurchaseOrderDetailsTable = (props: Props) => {
  return (
    <>
      <Table>
        <TableHeadRow>
          <TableHead width={140} align="center">
            SL NO
          </TableHead>
          {tableNames?.map((name) => {
            return (
              <TableHead width={180} key={name} align="left">
                {name.toUpperCase()}
              </TableHead>
            );
          })}
        </TableHeadRow>
        <TableBodyContainer isLoading={false} isError={false} errorMessage={''}>
          {props.productsArr?.map((ele: any, idx: number) => {
            return (
              <TableRow collapsible={false}>
                <TableCell align="center">{idx + 1}</TableCell>
                <TableCell>{ele?.productId?.productName}</TableCell>
                <TableCell>
                  {capitalize(ele?.productId?.manufacturerName)}
                </TableCell>
                <TableCell>{checkEmpty(ele?.unitsPerStrip)}</TableCell>
                <TableCell>{checkEmpty(ele?.pricePerStrip)}</TableCell>
                <TableCell>{ele?.totalStrips}</TableCell>
                <TableCell>
                  {ele?.pricePerStrip
                    ? ele?.pricePerStrip * ele?.totalStrips
                    : 0 * ele?.totalStrips}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBodyContainer>
      </Table>
    </>
  );
};

export default PurchaseOrderDetailsTable;
