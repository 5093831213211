import * as Yup from 'yup';

export const validationSchemaForAddGrn = Yup.object().shape({
    product: Yup.string().required('Please select a product'),
    vendorName: Yup.string().required('Vendor name is required'),
    invoiceNumber: Yup.number().required('Invoice number is required'),
    invoiceDate: Yup.date()
        .max(new Date(), 'Invoice date must be before the current date')
        .required('Invoice date is required'),
    batch: Yup.string().required('Batch is required'),
    expiryDate: Yup.date()
        .required('Expiry date is required')
        // .test('is-required', 'Expiry date is required', function (value: any) {
        //     return value === undefined;
        // })
        .test(
            'is-more-than-30-days',
            'Expiry date must be more than 30 days from the current date',
            function (value: any) {
                const currentDate = new Date();
                const thirtyDaysFromNow = new Date();
                thirtyDaysFromNow.setDate(currentDate.getDate() + 30);
                return value && value > thirtyDaysFromNow;
            }
        ),
    unitsPerStrip: Yup.number().required('Units per strip is required'),
    noOfStrips: Yup.number().required('No. of strips is required'),
    freeStrips: Yup.number(),
    gstTotal: Yup.string().required('GST total is required'),
    pricePerStrip: Yup.number()
        .required('Price per strip is required')
        .test(
            'is-non-zero',
            'Price per strip must be greater than 0',
            function (value: any) {
                return value > 0;
            }
        )
        .test(
            'is-less-than-mrp',
            'Price per strip should be less than MRP per strip',
            function (value: any) {
                const mrpPerStrip = this.parent.mrpPerStrip;
                return value !== undefined && mrpPerStrip !== undefined
                    ? value < mrpPerStrip
                    : true;
            }
        ),

    mrpPerStrip: Yup.number()
        .required('MRP per strip is required')
        .test(
            'is-non-zero',
            'MRP per strip must be greater than 0',
            function (value: any) {
                return value > 0;
            }
        )
        .test(
            'is-more-than-price-per-strip',
            'MRP per strip should be more than Price per strip',
            function (value: any) {
                const pricePerStrip = this.parent.pricePerStrip;
                return value !== undefined && pricePerStrip !== undefined
                    ? value > pricePerStrip
                    : true;
            }
        ),

    discountPercent: Yup.number().test(
        'is-less-than-100',
        'Discount should be less than 100',
        function (value: any) {
            return value < 100;
        }
    ),

    hsnCode: Yup.string(),
    rackNo: Yup.string().required('Rack number is required'),
    boxNo: Yup.string().required('Box number is required'),
});
