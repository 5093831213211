import { ErrorMessage, Field } from 'formik';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import { Draggable } from 'react-beautiful-dnd';

import _ from 'lodash';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';

const Questions = ({
  sectionIndex,
  index,
  values,
  push,
  remove,
  questionArray,
  setFieldValue,
  sections,
  QuestionProps,
  setErrors,
}: any) => {
  const [hoverEffect, setHoverEffect] = useState(false);
  const [clickEffect, setClickEffect] = useState(false);
  const [image, setImage] = useState<File | null>();
  const [preview, setPreview]: any = useState<string | null | undefined>();
  const fileRef = useRef<HTMLInputElement>(null);

  let emptyErrorsObj = _.isEmpty(QuestionProps?.form?.errors);
  let documentErrors =
    QuestionProps?.form?.errors?.sections &&
    QuestionProps?.form?.errors?.sections[sectionIndex] &&
    QuestionProps?.form?.errors?.sections[sectionIndex]?.questions[index] &&
    QuestionProps?.form?.errors?.sections[sectionIndex]?.questions[index]
      ?.documentValue;

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);
  const handleOnChange = (event: any) => {
    const file = event?.target?.files[0];
    if (file && file?.type?.substr(0, 11) === 'application') {
      setFieldValue(
        `sections[${sectionIndex}].questions[${index}].documentValue`,
        event?.target?.files[0]
      );
      setImage(file);
    } else {
      setImage(null);
      setErrors({
        ...QuestionProps.form.errors,
        sections: {
          ...QuestionProps.form.errors.sections,
          [sectionIndex]: {
            ...QuestionProps.form.errors.sections?.[sectionIndex],
            questions: {
              ...QuestionProps.form.errors.sections?.[sectionIndex]?.questions,
              [index]: {
                ...QuestionProps.form.errors.sections?.[sectionIndex]
                  ?.questions?.[index],
                documentValue: 'Please upload a file', // set error message for documentValue
              },
            },
          },
        },
      });
      setErrors({
        ...QuestionProps.form.errors,
        sections: {
          ...QuestionProps.form.errors.sections,
          [sectionIndex]: {
            ...QuestionProps.form.errors.sections?.[sectionIndex],
            questions: {
              ...QuestionProps.form.errors.sections?.[sectionIndex]?.questions,
              [index]: {
                ...QuestionProps.form.errors.sections?.[sectionIndex]
                  ?.questions?.[index],
                documentValue: 'Please upload a file', // set error message for documentValue
              },
            },
          },
        },
      });
    }
  };

  return (
    <>
      <Draggable key={index} draggableId={'drag-' + index} index={index}>
        {(provided, snapshot) => (
          <>
            <div
              className="d-flex w-100"
              style={{
                ...provided.draggableProps.style,
                boxShadow: snapshot.isDragging ? '0 0 5.4rem #f33d3d' : 'none',
                backgroundColor: snapshot.isDragging ? 'red' : 'none',
              }}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <div
                onFocus={() => setClickEffect(!clickEffect)}
                onBlur={() => setClickEffect(!clickEffect)}
                onMouseEnter={() => setHoverEffect(!hoverEffect)}
                onMouseLeave={() => setHoverEffect(!hoverEffect)}
                style={{
                  // margin: "10px",
                  border:
                    clickEffect === false && hoverEffect
                      ? '2px solid blue'
                      : '0px',
                  boxShadow: clickEffect ? '0 0 .4rem #3b3838' : 'none',
                }}
                // key={sectionIndex}
                className="card w-100 me-15 mb-5 p-5 d-flex flex-column position-relative"
              >
                <div className="d-flex w-100">
                  <div
                    className={`fs-2 form-label mb-5 ${
                      values.sections[`${sectionIndex}`].questions[`${index}`]
                        .questionRequired === true
                        ? 'required'
                        : null
                    }`}
                  >
                    Lesson {index + 1}
                  </div>
                  <div
                    style={{
                      position: 'relative',
                    }}
                    {...provided.dragHandleProps}
                  >
                    <i
                      style={{
                        position: 'absolute',
                        left: '400px',
                        cursor: 'move',
                      }}
                      className="fs-2 fas fa-grip-lines text-muted"
                    ></i>
                  </div>
                </div>
                <div className="d-flex fv-row h-60px">
                  {/* Question Name */}
                  <div className="fv-row col-lg-8">
                    <label className="fw-bolder form-label required">
                      Name
                    </label>
                    <Field
                      name={`sections[${sectionIndex}].questions[${index}].questionName`}
                      placeholder="Enter the Document / Video Name"
                      className="form-control form-control-lg form-control-solid"
                    />

                    <div className="text-danger text-center mb-5">
                      <ErrorMessage
                        name={`sections[${sectionIndex}].questions[${index}].questionName`}
                      />
                    </div>
                  </div>
                  {/* Question Type */}
                  <div className="fv-row w-450px ms-5">
                    <label className="fw-bolder form-label required">
                      Type
                    </label>
                    <Field
                      as="select"
                      name={`sections[${sectionIndex}].questions[${index}].questionType`}
                      className="form-select form-select-lg form-select-solid cursor-pointer"
                    >
                      <option value="Document">Documents</option>
                      <option value="Video">Video</option>
                    </Field>
                    <div className="text-danger mt-2">
                      <ErrorMessage
                        name={`sections[${sectionIndex}].questions[${index}].questionType`}
                      />
                    </div>
                  </div>
                  {/* Here Buttons */}

                  {hoverEffect && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '40%',
                        right: '-25px',
                      }}
                      className="card p-5 d-flex align-items-center justify-content-center"
                    >
                      <i
                        onClick={() =>
                          push({
                            questionName: '',
                            questionType: 'Document',
                            documentValue: '',
                            videoLink: '',
                          })
                        }
                        className="fs-2 fas fa-plus text-hover-primary cursor-pointer"
                      ></i>
                    </div>
                  )}
                </div>

                {/* Video Type */}
                <div className="d-flex mt-2 w-100 p-2">
                  {/* Paste Youtube link */}

                  {values.sections[`${sectionIndex}`].questions[`${index}`]
                    .questionType === 'Video' && (
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      {/* Lazy load the default thumbnail */}
                      <div className="">
                        {values.sections[`${sectionIndex}`].questions[
                          `${index}`
                        ].videoLink === '' ? (
                          <span className="">
                            <img
                              src={toAbsoluteUrl(
                                '/media/ikure/content-builder/youtube.svg'
                              )}
                              alt="default_video"
                              className="w-300px h-125px"
                            />
                          </span>
                        ) : (
                          <ReactPlayer
                            height="125px"
                            width="300px"
                            url={
                              values.sections[`${sectionIndex}`].questions[
                                `${index}`
                              ].videoLink
                            }
                          />
                        )}
                      </div>
                      <div className="position-relative">
                        <label className="fw-bolder form-label required">
                          Paste Youtube Link
                        </label>
                        <Field
                          name={`sections[${sectionIndex}].questions[${index}].videoLink`}
                          placeholder="e.g. https://www.youtube.com/watch?v=k8C5wYObTvo"
                          className="form-control form-control-lg form-control-solid w-500px"
                        />
                        <div className="text-danger text-center mt-2 position-absolute w-100">
                          <ErrorMessage
                            name={`sections[${sectionIndex}].questions[${index}].videoLink`}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Upload Document */}
                  {values?.sections[`${sectionIndex}`]?.questions[`${index}`]
                    ?.questionType === 'Document' && (
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      {/* Lazy load Default Document */}
                      <div className="">
                        <img
                          src={toAbsoluteUrl(
                            '/media/ikure/content-builder/document.svg'
                          )}
                          alt="default_doc"
                          className="w-200px h-100px"
                        />
                      </div>
                      <div className="position-relative mt-2">
                        <label className="fw-bolder form-label required mb-0">
                          File
                        </label>
                        <input
                          ref={fileRef}
                          // hidden
                          type="file"
                          className="form-control form-control-lg  form-control-solid w-500px"
                          accept="application/*"
                          onChange={handleOnChange}
                        />
                        <div className="text-danger position-absolute w-100 text-center">
                          {emptyErrorsObj === false && documentErrors}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Delete Icon */}
                  <div className="ms-15 fv-row w-150px d-flex align-items-center form-check form-switch form-check-solid form-check-custom">
                    {index > 0 && (
                      <span onClick={() => remove(index)}>
                        <button className="btn btn-md btn-danger">
                          <i className="fs-2 fas fa-trash-alt text-hover-danger cursor-pointer"></i>
                          Delete
                        </button>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Draggable>
    </>
  );
};

export default Questions;
