import { Link } from 'react-router-dom';
import { CHWStatusProps } from '../types/Status.Types';
import CustomTooltip from '../../../components/CustomTooltip';
import {
    TableBodyContainer,
    Table,
    TableCell,
    TableHeadRow,
    TableHead,
    TableRow,
} from '../../../components/Table';
import TableErrorComponent from '../../../components/Error/TableError';

const badgeLabel = {
    success: 'success',
    warning: 'warning',
    danger: 'danger',
    info: 'info',
    primary: 'primary',
};

const tableHeadTitles = [
    'CHW NAME',
    'PHONE NO',
    'GRADE',
    'FACILITY',
    'STATUS',
    'ACTION',
];
const CHWStatus = ({
    className,
    tableTitle,
    tableSubTitle,
    dataList,
    dataListMsg,
    isLoading,
    isFetching,
    isError,
    errorMsg,
}: CHWStatusProps) => {
    const facilityCode: any = localStorage.getItem('facilityCode');
    return (
        <div className={`card ${className}`}>
            <div className="card-header border-0 d-flex justify-content-between pt-5 px-3">
                <div className="py-4">
                    <div className="fs-1 fw-bolder ">{tableTitle}</div>
                    <div className="fs-5 text-black-50 fw-bolder mt-2">
                        {tableSubTitle}
                    </div>
                </div>
                <div className="card-toolbar">
                    <div className="">
                        <Link
                            to={'/chw/chw-management'}
                            className="btn btn-sm btn-primary"
                        >
                            <span className="fs-5 fw-bolder">See more</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="table-responsive h-300px px-3">
                {isError ? (
                    <TableErrorComponent
                        errorMessage={
                            (errorMsg as string) ?? 'Failed to fetch CHW data'
                        }
                    />
                ) : (
                    <Table>
                        <TableHeadRow>
                            <TableHead align="center" width={50}>
                                NO
                            </TableHead>
                            {tableHeadTitles.map((ele) => (
                                <TableHead key="ele" width={200}>
                                    {ele}
                                </TableHead>
                            ))}
                        </TableHeadRow>
                        {dataList?.length === 0 ? (
                            <tbody
                                style={{
                                    height: '300px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    left: '300px',
                                    top: '100px',
                                }}
                            >
                                <h1 className="text-danger">{dataListMsg}</h1>
                            </tbody>
                        ) : (
                            <TableBodyContainer
                                isError={isError}
                                isLoading={isLoading}
                            >
                                {dataList?.map((item: any, index: any) => {
                                    const chwLocation =
                                        item?.fieldData?.length === 0
                                            ? 'No Data'
                                            : item?.fieldData?.street +
                                              ', ' +
                                              item?.fieldData?.locality +
                                              ', ' +
                                              item?.fieldData?.district +
                                              ', ' +
                                              item?.fieldData?.state;

                                    //   Facility name logic
                                    const facilityList =
                                        item?.facilityData?.map((d: any) => {
                                            return {
                                                name:
                                                    d?.facilityFirstName +
                                                    ' ' +
                                                    d?.facilityLastName,
                                                code: d?.facilityCode,
                                            };
                                        });

                                    const facilityNames = facilityList
                                        ?.map((d: any) => {
                                            const name = d?.name;
                                            return name
                                                ? name.charAt(0).toUpperCase() +
                                                      name.slice(1)
                                                : '';
                                        })
                                        .join(', ');
                                    const singleFacility = facilityList?.find(
                                        (facility: any) =>
                                            facility?.code === facilityCode
                                    );
                                    const chwFacilitiesTooltip =
                                        facilityCode === ''
                                            ? facilityNames
                                            : singleFacility?.name
                                                  .charAt(0)
                                                  .toUpperCase() +
                                              singleFacility?.name?.slice(1);

                                    const chwFacilities =
                                        facilityCode === ''
                                            ? facilityList?.length > 1
                                                ? facilityNames?.split(',')[0] +
                                                  ' ..'
                                                : facilityNames?.split(',')[0]
                                            : singleFacility?.name
                                                  .charAt(0)
                                                  .toUpperCase() +
                                              singleFacility?.name?.slice(1);

                                    return (
                                        <TableRow
                                            key={index}
                                            collapsible={false}
                                        >
                                            <TableCell align="center">
                                                {index + 1}
                                            </TableCell>

                                            <TableCell>
                                                {item?.firstName +
                                                ' ' +
                                                item?.middleName +
                                                ' ' +
                                                item?.lastName
                                                    ? item?.firstName +
                                                      ' ' +
                                                      item?.middleName +
                                                      ' ' +
                                                      item?.lastName
                                                    : 'N/A'}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    item?.demographicDetails
                                                        ?.telecom
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {item?.grade
                                                    ? item?.grade
                                                    : 'N/A'}
                                            </TableCell>
                                            <TableCell>
                                                <CustomTooltip
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                    title={chwFacilitiesTooltip}
                                                >
                                                    <span className="border-none text-capitalize">
                                                        <span className="border-none text-capitalize">
                                                            {item?.facilityData &&
                                                            chwFacilities
                                                                ? chwFacilities
                                                                : '--'}
                                                        </span>
                                                    </span>
                                                </CustomTooltip>
                                            </TableCell>
                                            <TableCell>
                                                <span
                                                    className={`badge badge-light-${
                                                        item?.fieldStatus ===
                                                        'OnField'
                                                            ? badgeLabel.success
                                                            : badgeLabel.danger
                                                    } text-center `}
                                                >
                                                    {item?.fieldStatus ===
                                                    'OnField' ? (
                                                        <CustomTooltip
                                                            title={chwLocation}
                                                            placement="top"
                                                        >
                                                            <span className="border-none">
                                                                On Field
                                                            </span>
                                                        </CustomTooltip>
                                                    ) : (
                                                        <span className="border-none">
                                                            Off Field
                                                        </span>
                                                    )}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    state={{ item }}
                                                    to={{
                                                        pathname: `/chw/overview`,
                                                        search: `?chwId=${item?.chwId}`,
                                                    }}
                                                    className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                                                >
                                                    DETAILS
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBodyContainer>
                        )}
                    </Table>
                )}
            </div>
        </div>
    );
};

export { CHWStatus };
