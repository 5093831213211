import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import useMutateRequest from '../../../../../hooks/getMutation.query';
import TableErrorComponent from '../../../../../components/Error/TableError';
import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../../../../components/Table';
import GRNDetailsTable from './GRNDetailsTable';
import CollapsibleTableIcon from '../../../../../components/IconAnimations/CollapsibleTableIcon';

import SearchBox from '../SearchBox';
import GRNFilterList from '../GRNFilterList';
import useGetRequest from '../../../../../hooks/getRequest.query';
import { getGRNs } from '../../../../../services/supply-chain/grn';

import Pagination from '../../../../../components/Pagination';
import { calcPurchaseAmount } from '../grnUtils';
import { searchGRN } from '../../../../../services/supply-chain/grn/SearchGRN.services';
import { useNavbar } from '../../../../../context/NavbarContext';
import FilterTile from '../../../../../components/filter-tile';
import {
  convertToUtcTimestamp,
  convertUtcTimestampToLocalDate,
  //   dateFormatter,
} from '../../../../../common/utils';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../../../common/utils/genericErrors';

const GRNList = () => {
  const { selectedFacility } = useNavbar();
  const [filterValues, setFilterValues] = useState({
    fromDate: '',
    toDate: '',
    vendorId: '',
  });

  const [clicked, setClicked]: any = useState('0');

  const handleToggle = (index: any) => {
    if (clicked === index) {
      return setClicked('0');
    }
    setClicked(index);
  };
  const facilityCode = localStorage.getItem('facilityCode') as string;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  // API to fetch list of GRN's
  const { data, isLoading, isFetching, isError, error, refetch }: any =
    useGetRequest(
      ['getGRNs', page, limit, filterValues, facilityCode, selectedFacility],
      () =>
        getGRNs({
          active: 'Active',
          facilityCode: selectedFacility?.facilityCode ?? facilityCode,
          page,
          limit,
          fromDate:
            filterValues.fromDate !== ''
              ? convertToUtcTimestamp(filterValues.fromDate)
              : '',
          toDate:
            filterValues.toDate !== ''
              ? convertToUtcTimestamp(filterValues.toDate)
              : '',
          vendorId: filterValues.vendorId,
        }),
      {
        retry: false,
        staleTime: 100000,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      }
    );
  const [apiData, setApiData]: any = useState<any>();
  const refreshPage = isLoading || isFetching;
  const errorMsg: any = isErrorMsgNotReadable(error?.response?.data?.message)
    ? getErrorMessage(error?.response?.status)
    : error?.response?.data?.message;

  const { mutate } = useMutateRequest(
    (data: { grnId: string; facilityCode: string }) => searchGRN(data)
  );

  useEffect(() => {
    setApiData(data);
  }, [data]);

  const totalSumAmount =
    apiData && calcPurchaseAmount(apiData?.data?.data?.grn?.products);
  return (
    <div className="card d-flex flex-column">
      <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
        <div className="my-2 d-flex align-items-center justify-content-center h-50px">
          <h1>GRN LIST</h1>
          <i
            onClick={() => {
              refetch();
              setFilterValues({
                fromDate: '',
                toDate: '',
                vendorId: '',
              });
            }}
            className="fas fa-redo fs-3 cursor-pointer ms-5"
          ></i>
        </div>
        <div className="card-toolbar">
          {/* Search Bar */}
          <div className="py-2 me-20">
            <SearchBox
              mutate={mutate}
              setApiData={setApiData}
              setPage={setPage}
            />
          </div>

          {/* Filter Options */}
          <div>
            <GRNFilterList
              filterValues={filterValues}
              setFilterValues={setFilterValues}
            />
          </div>
        </div>
      </div>
      <FilterTile
        filterData={filterValues}
        keyNames={{
          fromDate: 'Start Date',
          toDate: 'End Date',
          vendorId: 'Vendor Id',
        }}
        clearAll={() =>
          setFilterValues({
            fromDate: '',
            toDate: '',
            vendorId: '',
          })
        }
      />
      {isError ? (
        <TableErrorComponent errorMessage={errorMsg} />
      ) : (
        <>
          <Table>
            <TableHeadRow>
              <TableHead width={50} align="center">
                -
              </TableHead>
              <TableHead width={50} align="center">
                SL No
              </TableHead>
              <TableHead width={50}>GRN NO</TableHead>
              <TableHead width={50}>GRN DATE</TableHead>
              <TableHead width={50}>VENDOR NAME</TableHead>
              <TableHead width={50}>PO ID</TableHead>
              <TableHead width={50}>AMOUNT</TableHead>
              <TableHead width={50}>INVOICE ID</TableHead>
              <TableHead width={50}>INVOICE FILE</TableHead>
              {/* <TableHead width={50}>PAYMENT STATUS</TableHead> */}
            </TableHeadRow>
            {isLoading ? (
              <tbody
                style={{
                  height: '400px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  left: '500px',
                  top: '50px',
                }}
              >
                <ClipLoader size={70} speedMultiplier={0.6} />
              </tbody>
            ) : isError === true ? (
              <tbody
                style={{
                  height: '400px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  left: '400px',
                  top: '5px',
                }}
              >
                <h1 className="text-danger">
                  {isError === true ? errorMsg : 'Something went wrong!!'}
                </h1>
              </tbody>
            ) : Array?.isArray(apiData?.data?.data?.grn) ? (
              <TableBodyContainer isLoading={refreshPage}>
                {apiData?.data?.data?.grn?.map((data: any, index: number) => {
                  const totalAmount = calcPurchaseAmount(data?.products);
                  // console.log("main", data);
                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        collapsible
                        show={clicked === index}
                        onClick={() => handleToggle(index)}
                        collapsibleBody={
                          <>
                            {clicked === index && (
                              <div
                                style={{
                                  width: '120%',
                                }}
                                className="card p-6 d-flex flex-column justify-content-center ms-5"
                              >
                                <GRNDetailsTable data={data?.products} />
                              </div>
                            )}
                          </>
                        }
                      >
                        {/* <tr onClick={() => handleToggle(index)}> */}
                        <TableCell hover align="center">
                          <CollapsibleTableIcon isClicked={clicked === index} />
                        </TableCell>
                        <TableCell align="center">
                          {page && (page - 1) * limit + index + 1}
                        </TableCell>
                        <TableCell>
                          {data?.grnId ? data?.grnId : '--'}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: '150px',
                            }}
                          >
                            {data?.createdAt
                              ? convertUtcTimestampToLocalDate(data?.createdAt)
                              : 'N/A'}
                          </div>
                        </TableCell>
                        <TableCell>
                          {data?.vendorReferenceId?.vendorName
                            ? data?.vendorReferenceId?.vendorName
                            : '--'}
                        </TableCell>
                        <TableCell>
                          {data?.purchaseOrderId ? data?.purchaseOrderId : '--'}
                        </TableCell>
                        <TableCell>
                          {totalAmount ? totalAmount : '--'}
                        </TableCell>
                        <TableCell>
                          {data?.invoiceNumber ? data?.invoiceNumber : '--'}
                        </TableCell>
                        {/* <TableCell hover>
                                                    {data?.invoice.status ? (
                                                        <span>
                                                            {data?.invoice
                                                                ? data?.invoice
                                                                      .fileURL
                                                                : "N/A"}
                                                        </span>
                                                    ) : (
                                                        <span>X</span>
                                                    )}
                                                </TableCell> */}
                        <TableCell align="right">
                          {data?.invoiceFile !== '' ? (
                            <>
                              <a
                                href={data?.invoiceFile}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i
                                  // onClick={handleAlert}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  className="fs-2 fas fa-arrow-circle-down text-hover-primary"
                                ></i>
                              </a>
                            </>
                          ) : (
                            <i className="fs-2 fas fa-times-circle text-hover-danger"></i>
                          )}
                        </TableCell>
                        {/* <TableCell>
                                                    <span
                                                        className={`badge badge-light-${
                                                            data
                                                                ?.purchaseOrderReferenceId
                                                                ?.paymentStatus !==
                                                            "Due"
                                                                ? "success"
                                                                : "danger"
                                                        } text-center `}
                                                    >
                                                        {data
                                                            ?.purchaseOrderReferenceId
                                                            ?.paymentStatus
                                                            ? data
                                                                  ?.purchaseOrderReferenceId
                                                                  ?.paymentStatus
                                                            : "N/A"}
                                                    </span>
                                                </TableCell> */}
                        {/* </tr> */}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBodyContainer>
            ) : (
              <TableBodyContainer isLoading={refreshPage}>
                {/* TODO : to fix now */}

                <>
                  <TableRow
                    collapsible
                    show={true}
                    // onClick={() => handleToggle(index)}
                    collapsibleBody={
                      <>
                        {/* {clicked === index && ( */}
                        <div
                          style={{
                            width: '120%',
                          }}
                          className="card p-6 d-flex flex-column justify-content-center ms-5"
                        >
                          <GRNDetailsTable
                            data={apiData?.data?.data?.grn?.products}
                          />
                        </div>
                        {/* )} */}
                      </>
                    }
                  >
                    {/* <tr onClick={() => handleToggle(index)}> */}
                    <TableCell hover align="center">
                      <CollapsibleTableIcon isClicked={clicked} />
                    </TableCell>
                    <TableCell align="center">1</TableCell>
                    <TableCell>
                      {apiData?.data?.data?.grn?.grnId
                        ? apiData?.data?.data?.grn?.grnId
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <span>
                        {apiData?.data?.data?.grn?.createdAt
                          ? convertUtcTimestampToLocalDate(
                              apiData?.data?.data?.grn?.createdAt
                            )
                          : 'N/A'}
                      </span>
                    </TableCell>
                    <TableCell>
                      {apiData?.data?.data?.grn?.vendorReferenceId?.vendorName
                        ? apiData?.data?.data?.grn?.vendorReferenceId
                            ?.vendorName
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {apiData?.data?.data?.grn?.purchaseOrderId
                        ? apiData?.data?.data?.grn?.purchaseOrderId
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {totalSumAmount ? totalSumAmount : '--'}
                    </TableCell>
                    <TableCell>
                      {apiData?.data?.data?.grn?.invoiceNumber
                        ? apiData?.data?.data?.grn?.invoiceNumber
                        : '--'}
                    </TableCell>
                    {/* <TableCell hover>
                                                {data?.invoice.status ? (
                                                    <span>
                                                        {data?.invoice
                                                            ? data?.invoice
                                                                  .fileURL
                                                            : "N/A"}
                                                    </span>
                                                ) : (
                                                    <span>X</span>
                                                )}
                                            </TableCell> */}
                    <TableCell align="right">
                      {apiData?.data?.data?.grn?.invoiceFile !== '' ? (
                        <>
                          <a
                            href={apiData?.data?.data?.grn?.invoiceFile}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i
                              // onClick={handleAlert}
                              style={{
                                cursor: 'pointer',
                              }}
                              className="fs-2 fas fa-arrow-circle-down text-hover-primary"
                            ></i>
                          </a>
                        </>
                      ) : (
                        <i className="fs-2 fas fa-times-circle text-hover-danger"></i>
                      )}
                    </TableCell>
                    {/* <TableCell>
                                                <span
                                                    className={`badge badge-light-${
                                                        data
                                                            ?.purchaseOrderReferenceId
                                                            ?.paymentStatus !==
                                                        "Due"
                                                            ? "success"
                                                            : "danger"
                                                    } text-center `}
                                                >
                                                    {data
                                                        ?.purchaseOrderReferenceId
                                                        ?.paymentStatus
                                                        ? data
                                                              ?.purchaseOrderReferenceId
                                                              ?.paymentStatus
                                                        : "N/A"}
                                                </span>
                                            </TableCell> */}
                    {/* </tr> */}
                  </TableRow>
                </>
              </TableBodyContainer>
            )}
          </Table>
          <Pagination
            dataCount={apiData?.data?.results}
            handleCallback={(val: number | string | any) => {
              setLimit(val);
            }}
            handleNext={() =>
              setPage((prevPageNumber: number) => prevPageNumber + 1)
            }
            handlePrev={() =>
              setPage((prevPageNumber: number) => prevPageNumber - 1)
            }
            nextDisabled={page === Math.ceil(apiData?.data?.results / limit)}
            pageNumber={page}
            pageLimit={limit}
            prevDisabled={page === 1}
          />
        </>
      )}
    </div>
  );
};

export default GRNList;
