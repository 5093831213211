// Add/Create Task
// export const createTask = async (payload: any) => {
//   return await $axiosProtected().post("/task/store", payload);
// };

import $axiosProtected, { payload } from '../common/utils/axiosUtils';

// Get All Task
export const getAllTaskByFacility = async (payload: any) => {
    return await $axiosProtected().get(
        `/scheduler/task/facility/get?facilityCode=${payload.facilityCode}&chw=${payload.chwId}&taskStatus=${payload.status}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&limit=${payload.limit}&page=${payload.page}&taskType=${payload.taskType}`
    );
};

// Get chw list
export const getAllCHW = async (payload: any) => {
    return await $axiosProtected().get(
        `/chwm/facility/list/all?facilityCode=${payload.facilityCode}`
    );
};

// Get chw list
export const getTaskDetails = async (payload: any) => {
    return await $axiosProtected().get(
        `/scheduler/task/id/get/web-portal?taskId=${payload.taskId}`
        // `/scheduler/task/id/get/web-portal?taskId=6239e5d2d72059cf96566b42`
    );
};

// Get All Task Insights
export const getTaskInsightByFacility = async (payload: any) => {
    return await $axiosProtected().get(
        `/scheduler/task/facility/insights/get?facilityCode=${payload.facilityCode}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    );
};

export const getIkureConnectList = async (payload: any) => {
    return await $axiosProtected().get(
        `scheduler/requests/task/get?page=${payload.page}&limit=${payload.limit}&facilityCode=${payload.facilityCode}`
    );
};
