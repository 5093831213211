import React, { useMemo } from "react";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../components/Table";
import TableErrorComponent from "../../../components/Error/TableError";
import Pagination from "../../../components/Pagination";
import { convertUtcTimestampToLocalDate } from "../../../common/utils";

type Props = {
    data: any;
    page: number;
    limit: number;
    setPage: Function;
    isError: boolean;
    setLimit: Function;
    isLoading: boolean;
};
const tableHeadings = [
    "SL NO",
    "PATIENT NAME",
    "ORDER ID",
    "PAYMENT TYPE",
    "DATE",
    "RECEIVED BY",
    "RECEIVED AT",
    "AMOUNT",
    "CASH RECEIPT",
];

const PaymentTable = ({
    data,
    page,
    limit,
    setPage,
    isError,
    setLimit,
    isLoading,
}: Props) => {
    const totalPages = Math.ceil(data?.data?.results / limit);
    const paymentData = useMemo(() => data?.data?.data?.salesOrder, [data]);
    return (
        <div>
            {false ? (
                <TableErrorComponent errorMessage={""} />
            ) : (
                <div className="card-body py-3">
                    <Table>
                        <TableHeadRow>
                            {tableHeadings.map((ele) => (
                                <TableHead
                                    width={
                                        ele.toLowerCase() === "rows" ||
                                        ele.toLowerCase() === "sl no"
                                            ? 50
                                            : 100
                                    }
                                    align={
                                        ele.toLowerCase() === "rows"
                                            ? "center"
                                            : "left"
                                    }
                                    key={ele}
                                >
                                    {ele}
                                </TableHead>
                            ))}
                        </TableHeadRow>
                        <TableBodyContainer
                            isLoading={isLoading}
                            isError={false}
                            errorMessage={"chwErrorMsg || searchErrorMsg"}
                        >
                            {paymentData &&
                                paymentData.length > 0 &&
                                paymentData.map((ele: any, idx: number) => (
                                    <TableRow collapsible={false} key={idx}>
                                        <TableCell align="center">
                                            {page &&
                                                (page - 1) * limit + idx + 1}
                                        </TableCell>
                                        <TableCell>
                                            {ele?.patientData[0]?.name
                                                ? ele?.patientData[0]?.name
                                                : "--"}
                                        </TableCell>
                                        <TableCell>{ele?.orderId}</TableCell>
                                        <TableCell>
                                            {ele?.payments?.paymentType
                                                ? ele?.payments?.paymentType
                                                : "--"}
                                        </TableCell>
                                        <TableCell>
                                            {ele?.createdAt
                                                ? convertUtcTimestampToLocalDate(
                                                      ele?.createdAt
                                                  )
                                                : "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {ele?.createdByRole === "CHW"
                                                ? `${ele?.chwData[0]?.firstName} ${ele?.chwData[0]?.lastName}`
                                                : `${ele?.facilityManagerData[0]?.firstName} ${ele?.facilityManagerData[0]?.lastName}`}
                                        </TableCell>
                                        <TableCell>
                                            {" "}
                                            {ele?.salesOrderType
                                                ? ele?.salesOrderType
                                                : "--"}
                                        </TableCell>
                                        <TableCell>
                                            {ele?.payments?.amount}
                                        </TableCell>
                                        <TableCell>
                                            {ele?.payments?.receiptFile ? (
                                                <>
                                                    <a
                                                        href={
                                                            ele?.payments
                                                                ?.receiptFile
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <i
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            className="fs-2 fas fa-arrow-circle-down text-hover-primary"
                                                        ></i>
                                                    </a>
                                                </>
                                            ) : (
                                                <i className="fs-2 fas fa-times-circle text-hover-danger"></i>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBodyContainer>
                    </Table>
                    {!isError && (
                        <Pagination
                            dataCount={data?.data?.results}
                            handleCallback={(val: number | string) => {
                                setLimit(val as number);
                            }}
                            handleNext={() =>
                                setPage(
                                    (prevPageNumber: number) =>
                                        prevPageNumber + 1
                                )
                            }
                            handlePrev={() =>
                                setPage(
                                    (prevPageNumber: number) =>
                                        prevPageNumber - 1
                                )
                            }
                            nextDisabled={page === totalPages}
                            pageNumber={page}
                            pageLimit={limit}
                            prevDisabled={page === 1}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default PaymentTable;
