import { useEffect, useMemo, useState } from 'react';
import { useNavbar } from '../../context/NavbarContext';
import useGetRequest from '../../hooks/getRequest.query';
import { getAllDoctorsWithPagination } from '../../services/doctor/DoctorManagement.hook';

import { DoctorTable } from './table/DoctorTable';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../common/utils/genericErrors';

const DoctorPage = () => {
  const { selectedFacility } = useNavbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const { data, isLoading, isFetching, refetch, isError, error }: any =
    useGetRequest(
      ['getAllDoctorsWithPagination', pageNumber, pageLimit],
      async () =>
        await getAllDoctorsWithPagination({
          page: pageNumber,
          limit: pageLimit,
        }),
      {
        retry: false,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    refetch();
  }, [selectedFacility]);

  const totalDoctorEntites = data?.data?.results;
  const doctorData = data?.data?.data?.doctor;

  const totalAvailablePages = Math.ceil(totalDoctorEntites / pageLimit);
  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error]
  );

  return (
    <>
      <div className="mt-5">
        <DoctorTable
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPageNumber={pageNumber}
          setCurrentPageNumber={setPageNumber}
          totalDoctorEntites={totalDoctorEntites}
          totalAvailablePages={totalAvailablePages}
          isLoading={isLoading}
          isFetching={isFetching}
          isError={isError}
          errorMsg={errorMsg}
          bodyProps={doctorData}
          refetch={refetch}
        />
      </div>
    </>
  );
};

export default DoctorPage;
