import React, { useMemo } from 'react';
import {
    AutoCompleteInput,
    SelectMenu,
    TextInput,
} from '../../../../../components/Inputs';
import { useFormik } from 'formik';
import { validationSchemaForAddGrn } from './addGRNFormikValidations';
import ErrorMessageText from '../../../../../components/ErrorMessageText/ErrorMessageText';
import { IProductsArr } from '..';
import { IProduct, IProps } from './IForms';
import useGetRequest from '../../../../../hooks/getRequest.query';
import { getAllProducts } from '../../../../../services/supply-chain/product-master/MasterProduct.services';
import { getAllVendors } from '../../../../../services/supply-chain/vendor/Vendor.services';
import { capitalize } from 'lodash';

const AddProductForm = ({
    setProductsArr,
    setInvoiceDetails,
    setApiProductArr,
}: IProps) => {
    const formik = useFormik({
        initialValues: {
            product: '',
            productId: '',
            vendorName: '',
            vendorId: '',
            invoiceNumber: '',
            invoiceDate: '',
            batch: '',
            expiryDate: '',
            unitsPerStrip: '',
            noOfStrips: '',
            freeStrips: '',
            gstTotal: '0',
            pricePerStrip: '',
            mrpPerStrip: '',
            discountPercent: '0',
            hsnCode: '',
            rackNo: '',
            boxNo: '',
            vendorObj: { label: '', year: '' },
            productObj: { label: '', year: '' },
        },
        validationSchema: validationSchemaForAddGrn,
        onSubmit: (values: IProduct, { resetForm }) => {
            // Handle form submission
            setProductsArr((prevValue: IProductsArr) => {
                // Check if the product being added is already in the array
                const productIndex = prevValue.findIndex(
                    (ele) => ele.product === values.product
                );

                // If product exists in the array
                if (productIndex !== -1) {
                    // Check if the batch number is different for the same product
                    if (prevValue[productIndex].batch !== values.batch) {
                        return [...prevValue, values]; // Add the product
                    } else {
                        // Same product with the same batch, do not add
                        return prevValue;
                    }
                } else {
                    // Product doesn't exist in the array, check if the batch exists
                    const batchIndex = prevValue.findIndex(
                        (ele) => ele.batch === values.batch
                    );
                    if (batchIndex !== -1) {
                        // Batch exists but with different product, allow addition
                        return [...prevValue, values]; // Add the product
                    } else {
                        // Product and batch are both new, allow addition
                        return [...prevValue, values]; // Add the product
                    }
                }
            });

            const payload = {
                productId: values.productId,
                batch: values.batch,
                // vendor: values.vendorId,
                rackNo: values.rackNo,
                boxNo: values.boxNo,
                expiry: values.expiryDate,
                unitsPerStrip: +values.unitsPerStrip,
                totalQuantity: +values.noOfStrips,
                freeQuantity: +values.freeStrips,
                GST: +values.gstTotal,
                IGST: 0,
                CGST: +values.gstTotal / 2,
                SGST: +values.gstTotal / 2,
                pricePerUnit: (
                    (+values.pricePerStrip * +values.noOfStrips) /
                    (+values.noOfStrips * +values.unitsPerStrip)
                ).toFixed(2),
                pricePerStrip: values.pricePerStrip,
                ratePerUnit: +(
                    (+values.mrpPerStrip * +values.noOfStrips) /
                    (+values.noOfStrips * +values.unitsPerStrip)
                ).toFixed(2),
                ratePerStrip: +values.mrpPerStrip,
                discount: +values.discountPercent,
            };

            // Created this as Api accepts a different structure than UI
            setApiProductArr((prevValue: IProductsArr) => {
                // Check if the product being added is already in the array
                const productIndex = prevValue.findIndex(
                    (ele) => ele.productId === values.productId
                );

                // If product exists in the array
                if (productIndex !== -1) {
                    // Check if the batch number is different for the same product
                    if (prevValue[productIndex].batch !== values.batch) {
                        return [...prevValue, payload]; // Add the product
                    } else {
                        // Same product with the same batch, do not add
                        return prevValue;
                    }
                } else {
                    // Product doesn't exist in the array, check if the batch exists
                    const batchIndex = prevValue.findIndex(
                        (ele) => ele.batch === payload.batch
                    );
                    if (batchIndex !== -1) {
                        // Batch exists but with different product, allow addition
                        return [...prevValue, payload]; // Add the product
                    } else {
                        // Product and batch are both new, allow addition
                        return [...prevValue, payload]; // Add the product
                    }
                }
            });

            setInvoiceDetails({
                invoiceNumber: values.invoiceNumber,
                invoiceDate: values.invoiceDate,
                vendorId: values.vendorId,
            });

            formik.setFieldValue('batch', '');
            formik.setFieldValue('product', '');
            formik.setFieldValue('productId', '');
            formik.setFieldValue('expiryDate', '');
            formik.setFieldValue('unitsPerStrip', '');
            formik.setFieldValue('noOfStrips', '');
            formik.setFieldValue('freeStrips', '');
            formik.setFieldValue('gstTotal', '0');
            formik.setFieldValue('pricePerStrip', '');
            formik.setFieldValue('mrpPerStrip', '');
            formik.setFieldValue('discountPercent', '0');
            formik.setFieldValue('hsnCode', '');
            formik.setFieldValue('rackNo', '');
            formik.setFieldValue('boxNo', '');
            formik.setFieldValue('productObj', { label: '', year: '' });
        },
    });

    const { data }: any = useGetRequest('getAllProductList', () =>
        getAllProducts({ page: 1, limit: 1000 })
    );

    const productList = useMemo(
        () =>
            data
                ? data?.data?.data?.products.map((ele: any) => ({
                      label: capitalize(ele?.productName),
                      productId: ele._id,
                  }))
                : [{ label: '', productId: '' }],
        [data]
    );

    const { data: vendorData } = useGetRequest('getAllVendors', () =>
        getAllVendors({ page: 1, limit: 1000 })
    );

    const vendorList = useMemo(
        () =>
            vendorData
                ? vendorData?.data?.data?.vendors.map((ele: any) => ({
                      label: capitalize(ele.vendorName),
                      vendorId: ele.vendorId,
                  }))
                : [{ label: '', vendorId: '' }],
        [vendorData]
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row mb-3">
                {/* <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="grnNo"
                        label="GRN Number"
                        placeholder="0"
                        type="number"
                        min="0"
                        name="grnNo"
                        onChange={formik.handleChange("grnNo")}
                        // value={formik.values.grnNo}
                        value={grn}
                    />
                    {formik.touched.grnNo && formik.errors.grnNo && (
                        <ErrorMessageText message={formik.errors.grnNo} />
                    )}
                </div> */}
                <div className="col">
                    <AutoCompleteInput
                        label="Vendor Name"
                        name="vendorName"
                        data-testid="vendor-name"
                        onChange={(
                            e: any,
                            value: { label: string; vendorId: string }
                        ) => {
                            formik.setFieldValue('vendorName', value.label);
                            formik.setFieldValue('vendorObj', value);
                            formik.setFieldValue('vendorId', value.vendorId);
                        }}
                        value={formik.values.vendorObj}
                        optionsArr={vendorList}
                        required
                        className="mb-3"
                    />
                    {formik.touched.vendorName && formik.errors.vendorName && (
                        <ErrorMessageText message={formik.errors.vendorName} />
                    )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="invoiceNumber"
                        label="Invoice Number"
                        data-testid="invoice-number"
                        placeholder="0"
                        type="number"
                        min="0"
                        name="invoiceNumber"
                        onChange={formik.handleChange('invoiceNumber')}
                        value={formik.values.invoiceNumber}
                        required
                    />
                    {formik.touched.invoiceNumber &&
                        formik.errors.invoiceNumber && (
                            <ErrorMessageText
                                message={formik.errors.invoiceNumber}
                            />
                        )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="invoiceDate"
                        label="Invoice Date"
                        placeholder="0"
                        type="date"
                        data-testid="invoice-date"
                        name="invoiceDate"
                        onChange={formik.handleChange('invoiceDate')}
                        value={formik.values.invoiceDate}
                        required
                    />
                    {formik.touched.invoiceDate &&
                        formik.errors.invoiceDate && (
                            <ErrorMessageText
                                message={formik.errors.invoiceDate}
                            />
                        )}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-2">
                    <AutoCompleteInput
                        label={'Product'}
                        name="product"
                        data-testid="product-menu"
                        required
                        onChange={(
                            e: any,
                            value: { label: string; productId: string }
                        ) => {
                            formik.setFieldValue('product', value.label);
                            formik.setFieldValue('productObj', value);
                            formik.setFieldValue('productId', value.productId);
                        }}
                        value={formik.values.productObj}
                        optionsArr={productList}
                        className="mb-3"
                    />
                    {formik.touched.product && formik.errors.product && (
                        <ErrorMessageText message={formik.errors.product} />
                    )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="batch"
                        label="Batch"
                        data-testid="batch-input"
                        placeholder="0"
                        type="text"
                        name="batch"
                        onChange={formik.handleChange('batch')}
                        value={formik.values.batch}
                        required
                    />
                    {formik.touched.batch && formik.errors.batch && (
                        <ErrorMessageText message={formik.errors.batch} />
                    )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="expiryDate"
                        label="Expiry Date"
                        placeholder="0"
                        type="date"
                        onClick={() => formik.setFieldTouched('expiryDate')}
                        data-testid="expiry-date"
                        name="expiryDate"
                        onChange={formik.handleChange('expiryDate')}
                        value={formik.values.expiryDate}
                        required
                    />
                    {formik.touched.expiryDate && formik.errors.expiryDate && (
                        <ErrorMessageText message={formik.errors.expiryDate} />
                    )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="unitsPerStrip"
                        label="Units/Strip"
                        min="0"
                        placeholder="0"
                        type="number"
                        data-testid="unit-input"
                        name="unitsPerStrip"
                        onChange={formik.handleChange('unitsPerStrip')}
                        value={formik.values.unitsPerStrip}
                        required
                    />
                    {formik.touched.unitsPerStrip &&
                        formik.errors.unitsPerStrip && (
                            <ErrorMessageText
                                message={formik.errors.unitsPerStrip}
                            />
                        )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="noOfStrips"
                        label="No of Strips"
                        data-testid="no-of-strips-input"
                        placeholder="0"
                        type="number"
                        min="0"
                        name="noOfStrips"
                        onChange={formik.handleChange('noOfStrips')}
                        value={formik.values.noOfStrips}
                        required
                    />
                    {formik.touched.noOfStrips && formik.errors.noOfStrips && (
                        <ErrorMessageText message={formik.errors.noOfStrips} />
                    )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="freeStrips"
                        label="Free Strips"
                        data-testid="free-strips"
                        placeholder="0"
                        min="0"
                        type="number"
                        name="freeStrips"
                        onChange={formik.handleChange('freeStrips')}
                        value={formik.values.freeStrips}
                    />
                    {formik.touched.freeStrips && formik.errors.freeStrips && (
                        <ErrorMessageText message={formik.errors.freeStrips} />
                    )}
                </div>
                <div className="col">
                    <SelectMenu
                        label="GST Total%"
                        name="gstTotal"
                        data-testid="gst-total"
                        onChange={formik.handleChange('gstTotal')}
                        className="mb-3"
                        value={formik.values.gstTotal}
                        required
                    >
                        <option value="0">0</option>
                        <option value="5">5</option>
                        <option value="12">12</option>
                        <option value="18">18</option>
                        <option value="28">28</option>
                    </SelectMenu>
                    {formik.touched.gstTotal && formik.errors.gstTotal && (
                        <ErrorMessageText message={formik.errors.gstTotal} />
                    )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="pricePerStrip"
                        label="Price/Strip"
                        placeholder="0"
                        data-testid="price-input"
                        type="number"
                        min="0"
                        name="pricePerStrip"
                        onChange={formik.handleChange('pricePerStrip')}
                        value={formik.values.pricePerStrip}
                        required
                    />
                    {formik.touched.pricePerStrip &&
                        formik.errors.pricePerStrip && (
                            <ErrorMessageText
                                message={formik.errors.pricePerStrip}
                            />
                        )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="mrpPerStrip"
                        label="MRP/Strip"
                        data-testid="mrp-strip"
                        placeholder="0"
                        type="number"
                        min="0"
                        name="mrpPerStrip"
                        onChange={formik.handleChange('mrpPerStrip')}
                        value={formik.values.mrpPerStrip}
                        required
                    />
                    {formik.touched.mrpPerStrip &&
                        formik.errors.mrpPerStrip && (
                            <ErrorMessageText
                                message={formik.errors.mrpPerStrip}
                            />
                        )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="discount"
                        data-testid="disc-input"
                        label="Discount %"
                        placeholder="0"
                        type="number"
                        min="0"
                        name="discountPercent"
                        onChange={formik.handleChange('discountPercent')}
                        value={formik.values.discountPercent}
                    />
                    {formik.touched.discountPercent &&
                        formik.errors.discountPercent && (
                            <ErrorMessageText
                                message={formik.errors.discountPercent}
                            />
                        )}
                </div>

                {/* <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="hsnCode"
                        label="HSN Code"
                        placeholder="0"
                        data-testid="hsn-code"
                        type="text"
                        name="hsnCode"
                        onChange={formik.handleChange("hsnCode")}
                        value={formik.values.hsnCode}
                    />
                    {formik.touched.hsnCode && formik.errors.hsnCode && (
                        <ErrorMessageText message={formik.errors.hsnCode} />
                    )}
                </div> */}
                <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="rackNo"
                        label="Rack No"
                        data-testid="rack-no"
                        placeholder="0"
                        type="text"
                        min="0"
                        name="rackNo"
                        onChange={formik.handleChange('rackNo')}
                        value={formik.values.rackNo}
                        required
                    />
                    {formik.touched.rackNo && formik.errors.rackNo && (
                        <ErrorMessageText message={formik.errors.rackNo} />
                    )}
                </div>
                <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="boxNo"
                        label="Box No"
                        placeholder="0"
                        type="text"
                        min="0"
                        data-testid="box-no"
                        name="boxNo"
                        onChange={formik.handleChange('boxNo')}
                        value={formik.values.boxNo}
                        required
                    />
                    {formik.touched.boxNo && formik.errors.boxNo && (
                        <ErrorMessageText message={formik.errors.boxNo} />
                    )}
                </div>
                <div className="col-sm-1">
                    <button
                        className="btn btn-primary"
                        type="submit"
                        data-testid="add-btn"
                    >
                        +
                    </button>
                </div>
            </div>
        </form>
    );
};

export default AddProductForm;
