import { allRoles, permittedRoles } from '../rbac/types/rbac.types';

const userRole = localStorage.getItem('userRole');

export const sidebarConfig = [
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        hasTitle: false,
        nestedMenu: false,
        divider: false,
        menuItems: [
            {
                to: '/dashboard',
                hasBullet: false,
                // icon: "/media/ikure/home.svg",
                icon: 'bi-house-door',
                title: 'HOME',
                fontIcon: 'bi-app-indicator',
            },
        ],
    },
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        hasTitle: false,
        nestedMenu: true,
        divider: false,
        subMenu: {
            to: '/chw',
            // icon: "/media/ikure/chw.svg",
            icon: 'bi-people',
            title: 'CHW',
        },
        menuItems: [
            {
                to: '/chw/chw-management',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'CHW MANAGEMENT',
            },
            {
                to: '/chw/chw-onboarding',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'CHW ONBOARDING',
            },
        ],
    },
    {
        acceptedRole: [permittedRoles.organizationAdmin],
        hasTitle: false,
        nestedMenu: true,
        divider: false,
        subMenu: {
            to: '/doctor',
            // icon: "/media/ikure/doctor.svg",
            icon: 'bi-person',
            title: 'DOCTOR',
        },
        menuItems: [
            {
                to: '/doctor/doctor-management',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'DOCTOR MANAGEMENT',
            },
            {
                to: '/doctor/doctor-onboarding',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'DOCTOR ONBOARDING',
            },
        ],
    },
    {
        acceptedRole: [permittedRoles.organizationAdmin],
        hasTitle: false,
        nestedMenu: true,
        divider: true,
        subMenu: {
            to: '/facility',
            icon: 'bi-buildings',
            title: 'FACILITY',
        },
        menuItems: [
            {
                to: '/facility/facility-management',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'FACILITY MANAGEMENT',
            },
            {
                to: '/facility/facility-onboarding',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'FACILITY ONBOARDING',
            },
            {
                to: '/facility/facility-manager-onboarding',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'MANAGER ONBOARDING',
            },
        ],
    },
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        moduleName: 'Stock Management',
        subMenu: {
            to: '/inventory-management',
            icon: 'bi-bag-plus',
            title: 'STOCK',
        },
        hasTitle: true,
        nestedMenu: true,
        divider: true,
        menuItems: [
            {
                to: '/facility-stock/list',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'FACILITY STOCK',
            },
            {
                to: '/chw-stock/list',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'CHW STOCK',
            },
        ],
    },
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        moduleName: 'Ecommerce Management',
        subMenu: {
            to: '/inventory-management',
            icon: 'bi-cart-dash',
            title: 'SUPPLY CHAIN',
        },
        hasTitle: true,
        nestedMenu: true,
        divider: true,
        menuItems: [
            {
                to: '/indent/indent-list',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'INDENT LIST',
            },
            {
                to: '/purchase-order/list',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'PURCHASE ORDER',
            },
            {
                to: '/goods-receipt-note/list',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'GRN LIST',
            },
            {
                to: '/sales-order/list',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'SALES ORDER',
            },
            {
                to: '/master-product/list',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'PRODUCT LIST',
            },
            {
                to: '/payment-list',
                hasBullet: true,
                fontIcon: 'bi-layers',
                title: 'PAYMENT LIST',
            },
        ],
    },
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
            permittedRoles.doctor,
        ],
        hasTitle: true,
        nestedMenu: true,
        divider: true,
        moduleName: 'PATIENT MANAGEMENT',
        subMenu: {
            to: '/patient',
            icon: 'bi-person-circle',
            title: 'PATIENT',
        },
        menuItems: [
            {
                to: '/patient/patient-management',
                title: 'PATIENT MANAGEMENT',
                hasBullet: true,
                fontIcon: 'bi-layers',
                hide: userRole === 'DOCTOR' ? true : false,
            },
            {
                to: '/patient/patient-appointment',
                title: 'APPOINTMENTS LIST',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
        ],
    },
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        hasTitle: true,
        nestedMenu: true,
        divider: true,
        moduleName: 'FOCUSED GROUP MANAGEMENT',
        subMenu: {
            to: '/focused-group',
            icon: 'bi-collection',
            title: 'FOCUSED GROUP',
        },
        menuItems: [
            {
                to: '/focused-group/management',
                title: 'MANAGEMENT',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
            {
                to: '/focused-group/create-focused-group',
                title: 'CREATE FOCUSED GROUP',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
        ],
    },
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        hasTitle: true,
        nestedMenu: true,
        divider: true,
        subMenu: {
            to: '/healthcard',
            icon: 'bi-person-vcard',
            title: 'HEALTH CARD',
        },
        moduleName: 'HEALTH CARD MANAGEMENT',
        menuItems: [
            {
                to: '/healthcard/list',
                title: 'HEALTH CARD LIST',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
            {
                to: '/batch/list',
                title: 'BATCH LIST',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
            {
                to: '/healthcard/unsused',
                title: 'UNUSED HEALTHCARDS',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
            {
                to: '/healthcard/generate',
                title: 'GENERATE HEALTHCARD',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
        ],
    },
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        hasTitle: true,
        nestedMenu: true,
        divider: true,
        subMenu: {
            to: '/task',
            icon: 'bi-list-check',
            title: 'TASK MANAGEMENT',
        },
        moduleName: 'TASK MANAGEMENT',
        menuItems: [
            {
                to: '/task/task-list',
                title: 'TASK LIST',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
            {
                to: '/task/unassigned-task-list',
                title: 'UNASSIGNED TASK LIST',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
            {
                to: '/task/task-insights',
                title: 'TASK INSIGHTS',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
            {
                to: '/task/ikure-connect',
                title: 'IKURE CONNECT',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
        ],
    },
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        // acceptedRole: allRoles,
        hasTitle: true,
        nestedMenu: true,
        divider: true,
        subMenu: {
            to: '/training',
            icon: 'bi-journal-bookmark-fill',
            title: 'TRAINING MANAGEMENT',
        },
        moduleName: 'TRAINING MANAGEMENT',
        menuItems: [
            {
                to: '/training/course-management',
                title: 'MANAGE COURSE',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
            {
                to: '/training/create-course',
                title: 'CREATE COURSE',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
            {
                to: '/training/categories',
                title: 'CREATE CATEGORIES',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
            {
                to: '/training/category-list',
                title: 'CATEGORY LIST',
                hasBullet: true,
                fontIcon: 'bi-layers',
            },
        ],
    },
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        hasTitle: true,
        nestedMenu: false,
        divider: false,
        moduleName: 'REPORT MANAGEMENT',
        menuItems: [
            {
                to: '/report/report-management',
                title: 'REPORT LIST',
                icon: 'bi-folder',
                hasBullet: false,
                fontIcon: 'bi-layers',
            },
        ],
    },
    {
        acceptedRole: [
            permittedRoles.organizationAdmin,
            permittedRoles.facilityManager,
        ],
        hasTitle: true,
        nestedMenu: false,
        divider: true,
        moduleName: 'ANALYTICS',
        menuItems: [
            {
                to: '/performance-analysis',
                title: 'PERFORMANCE MONITORING',
                icon: 'bi-graph-up',
                hasBullet: false,
                fontIcon: 'bi-layers',
            },
        ],
    },
];
