import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Autocomplete, Menu, TextField } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../../components/Table';
import CustomTooltip from '../../../components/CustomTooltip';
import useMutateRequest from '../../../hooks/getMutation.query';
import useGetRequest from '../../../hooks/getRequest.query';
import useSearchParams from '../../../hooks/useSearchParams';
import { getPatientTasks } from '../../../services/patient/PatientManagement.hook';
import { getAllCHW } from '../../../services/TaskManagement.hook';
import Pagination from '../../../components/Pagination';
import TableErrorComponent from '../../../components/Error/TableError';
import FilterTile from '../../../components/filter-tile';
import { convertUtcTimestampToLocalDate } from '../../../common/utils';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../common/utils/genericErrors';

const filterSchema = Yup.object().shape({
  chwId: Yup.string().label('CHW Id'),
  taskStatus: Yup.string().label('Status'),
  fromDate: Yup.date().label('From Date'),
  toDate: Yup.date().label('To Date'),
  taskType: Yup.string(),
});

const PatientTaskListTable = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterData, setFilterData]: any = useState({
    chwId: '',
    taskStatus: '',
    fromDate: '',
    toDate: '',
    taskType: '',
  });
  const { pId: id }: any = useSearchParams();
  const {
    data,
    isLoading,
    error,
    mutate,
    isError: isErrorPatientTaskList,
  }: any = useMutateRequest((data: any) =>
    getPatientTasks({
      page: currentPageNumber,
      limit: pageLimit,
      uhid: Number(id),
      chwId: filterData?.chwId,
      taskStatus: filterData?.taskStatus,
      fromDate: filterData?.fromDate,
      toDate: filterData?.toDate,
      taskType: filterData?.taskType,
    })
  );
  const getData = () => {
    try {
      const payload = {
        uhid: Number(id),
        chwId: filterData?.chwId,
        taskStatus: filterData?.taskStatus,
        fromDate: filterData?.fromDate,
        toDate: filterData?.toDate,
        taskType: filterData?.taskType,
      };

      mutate(payload, {
        onSuccess: (data: any) => {},
        onError: (error: any) => {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) getData();
  }, [id, currentPageNumber, filterData]);

  // API Utils
  const patientTaskListDetails: any = data?.data?.data?.task;
  const totalPatientTaskListEntites = data?.data?.results;

  const totalPatientTaskListPages = Math.ceil(
    totalPatientTaskListEntites / pageLimit
  );

  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error]
  );

  const successMsg = data?.data?.data?.message;

  // Get CHW Details
  const fd = localStorage.getItem('facilityCode');

  const chwQueryParams = {
    facilityCode: fd,
  };

  const { data: chwInfo }: any = useGetRequest(
    ['allCHWData', currentPageNumber],
    async () =>
      await getAllCHW({
        ...chwQueryParams,
      }),
    {
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );

  const chwData = chwInfo?.data?.data?.chws;

  const chwList = chwData?.map((item: any) => {
    return {
      label: '' + item.demographicDetails.telecom + '',
      chwId: item.chwId,
    };
  });

  const [chwSelect, setChwSelect] = useState('');

  // Begins Menu Here
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFilterSubmit = (values: any, onSubmitProps: any) => {
    try {
      const payload = {
        chwId: chwSelect,
        taskStatus: values.taskStatus,
        fromDate: values.fromDate,
        toDate: values.toDate,
        taskType: values.taskType,
      };
      setFilterData(payload);
      // onSubmitProps.resetForm();
      handleClose();
    } catch (error) {
      onSubmitProps.resetForm();
      handleClose();
    }
  };

  const clearData = () => {
    const payload = {
      chwId: '',
      status: '',
      fromDate: '',
      toDate: '',
      taskType: '',
    };
    setCurrentPageNumber(1);
    setFilterData(payload);
  };

  return (
    <>
      <div className="card text-dark">
        <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
          <div className="d-flex align-items-center justify-content-center h-50px">
            <h1>TASK LIST</h1>
            <i
              onClick={() => clearData()}
              className="fas fa-redo fs-3 cursor-pointer ms-5"
            ></i>
          </div>

          <div className="card-toolbar">
            <button onClick={handleClick} className="btn btn-sm btn-primary">
              <i className="fas fa-filter"></i>
              FILTER
            </button>

            {/* Here */}
            <div className="">
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Formik
                  initialValues={filterData}
                  validationSchema={filterSchema}
                  onSubmit={onFilterSubmit}
                  enableReinitialize
                >
                  <Form>
                    <div className="py-5 px-8">
                      <div className="w-250px mt-5 mb-8">
                        <label className="form-label fs-4 fw-bolder">
                          CHW NUMBER
                        </label>
                        <Autocomplete
                          className="fw-bolder"
                          disablePortal
                          id="combo-box-demo"
                          options={chwList}
                          getOptionLabel={(option) => option.label}
                          sx={{
                            width: 249,
                            fontWeight: 600,
                          }}
                          onChange={(e: object, value: any | null) => {
                            if (value) {
                              setChwSelect(value?.chwId);
                            } else {
                              setChwSelect('');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="fw-bolder mt-2"
                              name="chwId"
                              label="SELECT CHW"
                            />
                          )}
                        />
                        <div className="text-danger mt-5">
                          <ErrorMessage name="chwId" />
                        </div>
                      </div>
                      <div className="w-250px mt-5 mb-8">
                        <label className="form-label fs-4 fw-bolder">
                          STATUS
                        </label>
                        <Field
                          as="select"
                          className="form-select form-select-lg form-select-solid"
                          name="taskStatus"
                        >
                          <option value="">All</option>
                          <option value="To Do">TO DO</option>
                          <option value="In Progress">IN PROGRESS</option>
                          <option value="Done">DONE</option>
                          <option value="Cancelled">CANCELLED</option>
                        </Field>
                        <div className="text-danger mt-2">
                          <ErrorMessage name="taskStatus" />
                        </div>
                      </div>
                      <div className="w-250px mt-5 mb-8">
                        <label className="form-label fs-4 fw-bolder">
                          TASK TYPE
                        </label>
                        <Field
                          as="select"
                          className="form-select form-select-lg form-select-solid"
                          name="taskType"
                        >
                          <option value="">All</option>
                          <option value="Clinic">Clinic</option>
                          <option value="Camp">Camp</option>
                          <option value="Doorstep">Doorstep</option>
                          <option value="Community">Community</option>
                          <option value="Telehealth">Telehealth</option>
                        </Field>
                        <div className="text-danger mt-2">
                          <ErrorMessage name="status" />
                        </div>
                      </div>
                      <div className="w-250px mt-5 mb-8">
                        <label className="form-label fs-4 fw-bolder">
                          FROM DATE
                        </label>
                        <Field
                          id="fromDate"
                          as="input"
                          type="date"
                          name="fromDate"
                          className="form-control"
                        ></Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="fromDate" />
                        </div>
                      </div>
                      <div className="w-250px mt-5 mb-8">
                        <label className="form-label fs-4 fw-bolder">
                          TO DATE
                        </label>
                        <Field
                          id="toDate"
                          as="input"
                          type="date"
                          name="toDate"
                          className="form-control"
                        ></Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="toDate" />
                        </div>
                      </div>
                    </div>
                    <div className="m-5 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        className="btn btn-sm w-250px btn-primary"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </Form>
                </Formik>
              </Menu>
            </div>
          </div>
        </div>

        <FilterTile
          filterData={filterData}
          keyNames={{
            chwId: 'CHW Id',
            taskStatus: 'Task Status',
            fromDate: 'Start Date',
            toDate: 'End Date',
            taskType: 'Task Type',
          }}
          clearAll={() =>
            setFilterData({
              chwId: '',
              taskStatus: '',
              fromDate: '',
              toDate: '',
              taskType: '',
            })
          }
        />
        {isErrorPatientTaskList ? (
          <TableErrorComponent errorMessage={errorMsg || 'No Task Exists'} />
        ) : (
          <div className="card-body py-3">
            <Table>
              <TableHeadRow>
                <TableHead width={50} align={'center'}>
                  SL NO
                </TableHead>
                <TableHead width={120}>PATIENT NAME</TableHead>
                <TableHead width={120}>CHW NAME</TableHead>
                <TableHead width={120}>ACTIVITY NAME</TableHead>
                <TableHead width={120}>TASK TYPE</TableHead>
                <TableHead width={120}>DATE</TableHead>
                <TableHead width={100}>STATUS</TableHead>
                <TableHead width={100}>ACTIONS</TableHead>
              </TableHeadRow>
              {isErrorPatientTaskList === true ? (
                <tbody
                  style={{
                    height: '400px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: '450px',
                    top: '200px',
                  }}
                >
                  <h1 className="text-danger">
                    {isErrorPatientTaskList ? errorMsg : successMsg}
                  </h1>
                </tbody>
              ) : patientTaskListDetails?.length < 0 ? (
                <tbody
                  style={{
                    height: '400px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: '400px',
                    top: '200px',
                  }}
                >
                  <h1>{successMsg}</h1>
                </tbody>
              ) : patientTaskListDetails?.length === 0 ? (
                <tbody
                  style={{
                    height: '400px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: '450px',
                    top: '200px',
                  }}
                >
                  <h1 className="text-danger">No Data Found</h1>
                </tbody>
              ) : Array?.isArray(patientTaskListDetails) ? (
                <TableBodyContainer
                  isLoading={isLoading}
                  isError={isErrorPatientTaskList}
                >
                  {patientTaskListDetails?.map((item: any, index: any) => {
                    let allVitalNames = item?.activity
                      ?.map((activityName: any) => {
                        return activityName?.activityDetails;
                      })
                      ?.flat();

                    let vitalNames = allVitalNames?.map((allVitalName: any) => {
                      return `${allVitalName?.vitalName}`;
                    });

                    let firstVitalNames = allVitalNames?.map(
                      (allVitalName: any) => {
                        return `${allVitalName?.vitalName} ...`;
                      }
                    )[0];

                    let firstVitalName = allVitalNames?.map(
                      (allVitalName: any) => {
                        return `${allVitalName?.vitalName}`;
                      }
                    )[0];

                    return (
                      <TableRow collapsible={false} key={index}>
                        <TableCell align="center">
                          {currentPageNumber &&
                            (currentPageNumber - 1) * pageLimit + index + 1}
                        </TableCell>
                        <TableCell>{item?.patientData?.name}</TableCell>
                        <TableCell>{item?.chwData?.name}</TableCell>
                        <TableCell hover>
                          <CustomTooltip
                            title={vitalNames?.join(', ')}
                            placement="top"
                          >
                            <span className="border-none text-capitalize">
                              {allVitalNames?.length > 1 !== true
                                ? firstVitalName
                                : firstVitalNames}
                            </span>
                          </CustomTooltip>
                        </TableCell>
                        <TableCell>{item?.taskType}</TableCell>
                        <TableCell>
                          {item?.taskDate
                            ? convertUtcTimestampToLocalDate(
                                item?.taskDate,
                                'MMMM D, YYYY'
                              )
                            : 'N/A'}
                        </TableCell>

                        <TableCell>
                          <span
                            className={`badge badge-light-${
                              item?.taskStatus === 'Done'
                                ? 'success'
                                : item?.taskStatus === 'Cancelled'
                                ? 'danger'
                                : item?.taskStatus === 'In Progress'
                                ? 'info'
                                : item?.taskStatus === 'To Do'
                                ? 'primary'
                                : null
                            } text-center `}
                          >
                            {item?.taskStatus}
                          </span>
                        </TableCell>

                        <TableCell>
                          <Link
                            to={`/task/task-details?taskId=${item?._id}`}
                            className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                          >
                            DETAILS
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBodyContainer>
              ) : (
                <tbody
                  style={{
                    height: '400px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: '400px',
                    top: '200px',
                  }}
                >
                  <h1 className="text-danger">{patientTaskListDetails}</h1>
                </tbody>
              )}
            </Table>

            {/* Pagination */}

            {isErrorPatientTaskList && (
              <Pagination
                dataCount={totalPatientTaskListEntites}
                handleCallback={(val: number | string) => {
                  setPageLimit(+val);
                }}
                handleNext={() =>
                  setCurrentPageNumber(
                    (prevPageNumber: number) => prevPageNumber + 1
                  )
                }
                handlePrev={() =>
                  setCurrentPageNumber(
                    (prevPageNumber: number) => prevPageNumber - 1
                  )
                }
                nextDisabled={currentPageNumber === totalPatientTaskListPages}
                pageNumber={currentPageNumber}
                pageLimit={pageLimit}
                prevDisabled={currentPageNumber === 1}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PatientTaskListTable;
