import { useEffect, useMemo, useState } from 'react';
import { useNavbar } from '../../context/NavbarContext';
import useGetRequest from '../../hooks/getRequest.query';
import { getAllBatches } from '../../services/HealthCard.hook';

import BatchListTable from './table/BatchListTable';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../common/utils/genericErrors';

const BatchListPage = () => {
  const { selectedFacility } = useNavbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const facilityCode = localStorage.getItem('facilityCode');

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    error: BatchDataError,
    isError: isBatchDataError,
  }: any = useGetRequest(
    ['getBatchData', pageNumber, pageLimit],
    () => getAllBatches({ facilityCode, page: pageNumber, limit: pageLimit }),
    { retry: false, staleTime: 100000, refetchOnWindowFocus: false }
  );

  const totalBatchListEntites = data?.data?.results;
  const batchListData = data?.data?.data?.batchList;
  const totalAvailablePages = Math.ceil(totalBatchListEntites / pageLimit);
  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(BatchDataError?.response?.data?.message)
        ? getErrorMessage(BatchDataError?.response?.status)
        : BatchDataError?.response?.data?.message,
    [BatchDataError?.response?.data?.message]
  );
  const successMsg = data?.data?.data?.message;

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedFacility]);
  return (
    <div>
      <BatchListTable
        bodyProps={batchListData}
        isLoading={isLoading}
        isFetching={isFetching}
        isBatchDataError={isBatchDataError}
        errorMsg={errorMsg}
        successMsg={successMsg}
        currentPageNumber={pageNumber}
        totalBatchListPages={totalAvailablePages}
        totalBatchListEntites={totalBatchListEntites}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        setCurrentPageNumber={setPageNumber}
      />
    </div>
  );
};

export default BatchListPage;
