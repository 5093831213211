import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import useGetRequest from '../../../../hooks/getRequest.query';
import {
  getAllCoursesCategories,
  getAllCoursesSubcategory,
} from '../../../../services/web-content/WebContent.hook';

const Categories = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  // Get List of all Course Category
  const {
    data: coursesCategoryList,
    refetch: refetchCoursesCategory,
    isSuccess: responseSuccess,
  }: any = useGetRequest(
    'getAllCoursesCategories',
    async () => await getAllCoursesCategories(),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );

  // Get List of all Course Subcategory
  const {
    data: coursesSubcategoryList,
    refetch: refetchCoursesSubcategory,
    isLoading,
    isFetching,
  }: any = useGetRequest(
    'getAllCoursesSubcategory',
    async () =>
      await getAllCoursesSubcategory({
        categoryId: selectedCategoryId,
      }),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    refetchCoursesSubcategory();
    refetchCoursesCategory();
  }, [selectedCategoryId]);

  const coursesCategories = Array.from(
    new Set(
      coursesCategoryList?.data?.data?.category.map(
        (item: any) => item.categoryName
      )
    )
  ).map((name) =>
    coursesCategoryList?.data?.data?.category.find(
      (item: any) => item.categoryName === name
    )
  );
  const coursesSubcategories = coursesSubcategoryList?.data?.data?.subCategory;

  return (
    <>
      <div className="p-5">
        <div className="d-flex">
          <div
            style={{ width: '50%' }}
            className="w-500px btn-hover-primary card p-5 h-400px d-flex flex-column align-items-center justify-content-center"
          >
            {coursesCategories?.map((coursesCategory: any, index: number) => {
              return (
                <div
                  className={
                    coursesCategory?._id === selectedCategoryId
                      ? 'text-capitalize w-250px text-left fs-4 fw-bolder me-5 color-dark text-primary active cursor-pointer'
                      : 'text-capitalize w-250px text-left fs-4 fw-bolder me-5 color-dark cursor-pointer'
                  }
                  onClick={() => setSelectedCategoryId(coursesCategory?._id)}
                >
                  {`${index + 1} . ${coursesCategory?.categoryName}`}
                </div>
              );
            })}
          </div>

          {selectedCategoryId !== '' ? (
            <>
              {isLoading || isFetching === true ? (
                <div
                  style={{ width: '50%' }}
                  className="card h-400px d-flex flex-column align-items-center justify-content-center p-5"
                >
                  <ClipLoader size={70} speedMultiplier={0.6} />
                </div>
              ) : (
                <div
                  style={{ width: '50%' }}
                  className="card ms-5 p-5  h-400px d-flex flex-column justify-content-center align-items-center"
                >
                  {coursesSubcategories?.map(
                    (coursesSubcategory: any, index: any) => {
                      return (
                        <div className="bg-white w-450px me-5 d-flex flex-column justify-content-center align-items-center btn-color-dark">
                          <div className="w-200px">
                            <h1 className="fs-3 fw-bolder text-left text-capitalize">
                              {`${index + 1} . ${
                                coursesSubcategory?.subCategoryName
                              }`}
                            </h1>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </>
          ) : (
            <div
              style={{ width: '50%' }}
              className="card ms-5 p-5  h-400px d-flex flex-column justify-content-center"
            >
              <h3 className="fs-4 fw-bolder text-center w-100 text-danger">
                No Category is Selected
              </h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Categories;
