import React, { useEffect, useMemo, useState } from 'react';
import PurchaseOrderListFilter from './purchaseOrderListFilter';
// import SearchBox from "./SearchBox";
import PurchaseOrderListTable from './PurchaseOrderListTable';
import useGetRequest from '../../../../hooks/getRequest.query';
import {
  getAllPurchaseOrderList,
  getPoBySearch,
} from '../../../../services/supply-chain/purchase-order/purchaseOrderList';
// import dayjs from 'dayjs';
import useMutateRequest from '../../../../hooks/getMutation.query';
import SearchBox from './SearchBox';
import { useNavbar } from '../../../../context/NavbarContext';
import FilterTile from '../../../../components/filter-tile';
import { convertToUtcTimestamp } from '../../../../common/utils';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../../common/utils/genericErrors';

type Props = {};

const PurchaseOrderList = (props: Props) => {
  const { selectedFacility } = useNavbar();
  const facilityCode = localStorage.getItem('facilityCode') as string;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [apiData, setApiData] = useState<any>();

  const [filterValues, setFilterValues] = useState({
    status: '',
    fromDate: '',
    toDate: '',
    vendorId: '',
  });
  const { mutate } = useMutateRequest(
    (val: { purchaseOrderId: string; facilityCode: string }) =>
      getPoBySearch(val)
  );
  const { data, isLoading, isError, error }: any = useGetRequest(
    [
      'getAllPurchaseOrderList',
      facilityCode,
      page,
      limit,
      filterValues,
      selectedFacility,
    ],
    () =>
      getAllPurchaseOrderList({
        facilityCode: selectedFacility?.facilityCode
          ? selectedFacility?.facilityCode
          : facilityCode,
        page,
        limit,
        fromDate:
          filterValues.fromDate !== ''
            ? convertToUtcTimestamp(filterValues.fromDate)
            : '',
        toDate:
          filterValues.toDate !== ''
            ? convertToUtcTimestamp(filterValues.toDate)
            : '',
        vendorId: filterValues.vendorId,
        poOrderStatus: filterValues.status,
      })
  );
  const payload = {
    status: '',
    fromDate: '',
    toDate: '',
    vendorId: '',
  };

  useEffect(() => {
    setApiData(data);
  }, [data]);

  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error]
  );

  return (
    <div className="card text-dark p-4">
      <div>
        <h1>
          Purchase Order List
          <i
            onClick={() => {
              setFilterValues(payload);
              setApiData(data);
            }}
            className="mt-2 fas fa-redo fs-3 cursor-pointer ms-5"
          ></i>
        </h1>
      </div>
      <div className="row justify-content-end">
        <div className="col-8">
          <SearchBox
            mutate={mutate}
            setApiData={setApiData}
            setPage={setPage}
          />
        </div>
        <div className="col-sm-auto mt-4">
          <PurchaseOrderListFilter
            setPage={setPage}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
          />
        </div>

        <div className="mt-4">
          <FilterTile
            filterData={filterValues}
            keyNames={{
              fromDate: 'Start Date',
              toDate: 'End Date',
              status: 'Status',
              vendorId: 'Vendor Id',
            }}
            clearAll={() =>
              setFilterValues({
                status: '',
                fromDate: '',
                toDate: '',
                vendorId: '',
              })
            }
          />
        </div>
        <div className="mt-4">
          {!isError && apiData?.data ? (
            <PurchaseOrderListTable
              filterValues={filterValues}
              isLoading={isLoading}
              data={apiData?.data}
              setPage={setPage}
              setLimit={setLimit}
              page={page}
              limit={limit}
              errorMsg={errorMsg}
              isError={isError}
            />
          ) : (
            <div className="p-5 bg-white d-flex align-items-center justify-content-center h-450px">
              <h1 className="text-danger text-nowrap">
                {errorMsg ? errorMsg : 'Could not load data'}
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderList;
