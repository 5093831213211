import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';

import UnassignedTaskTable from './table/UnassignedTaskTable';
import { useNavbar } from '../../context/NavbarContext';
import useGetRequest from '../../hooks/getRequest.query';
import { getAllUnassignedTasks } from '../../services/task/UnassignedTasks.hook';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../common/utils/genericErrors';

const UnassignedTaskPage = () => {
  // const today = format(new Date(), 'yyyy-MM-dd');
  const { selectedFacility } = useNavbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterData, setFilterData]: any = useState({
    date: '',
  });

  const facilityCode = localStorage.getItem('facilityCode');

  const { data, isLoading, isFetching, refetch, isError, error }: any =
    useGetRequest(
      ['getAllUnassignedTasks', pageNumber, pageLimit],
      async () =>
        await getAllUnassignedTasks({
          facilityCode,
          page: pageNumber,
          limit: pageLimit,
          date: filterData?.date,
        }),
      {
        retry: false,
        staleTime: 100000,
        refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, facilityCode, selectedFacility]);

  const totalUnassignedTasks = data?.data?.results;
  const taskData = data?.data?.data;
  const totalAvailablePages = Math.ceil(totalUnassignedTasks / pageLimit);
  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error]
  );

  return (
    <>
      <UnassignedTaskTable
        pageLimit={pageLimit}
        currentPageNumber={pageNumber}
        setCurrentPageNumber={setPageNumber}
        totalCHWEntites={totalUnassignedTasks}
        totalAvailablePages={totalAvailablePages}
        isTasksLoading={isLoading}
        isTasksFetching={isFetching}
        isTaskError={isError}
        errorMsg={errorMsg}
        bodyProps={taskData}
        setFilterData={setFilterData}
        refetch={refetch}
        setPageLimit={setPageLimit}
      />
    </>
  );
};

export default UnassignedTaskPage;
