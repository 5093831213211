import { Link } from 'react-router-dom';

const PatientDetails = ({ id = '123' }) => {
    const patientData = {
        name: 'John Doe',
        gender: 'Male',
        age: 32,
        status: 'Scheduled',
        remarks: 'Test',
        symptoms: 'Headache',
    };

    const getStatusClass = (status: any) => {
        switch (status.toLowerCase()) {
            case 'scheduled':
                return 'bg-primary';
            case 'completed':
                return 'bg-success';
            case 'cancelled':
                return 'bg-danger';
            default:
                return 'bg-secondary';
        }
    };

    return (
        <div className="card shadow-sm">
            <div className="card-header bg-white border-bottom-2 pb-3">
                <h2 className="fw-bold fs-2 card-title text-uppercase text-center">
                    PATIENT DETAILS
                </h2>
            </div>

            <div className="card-body p-4">
                <div className="row">
                    {/* Left Side - Patient Profile */}
                    <div className="col-md-4 border-end">
                        <div className="text-center mb-4">
                            <div className="position-relative d-inline-block">
                                <div
                                    className="rounded-circle bg-light d-flex align-items-center justify-content-center"
                                    style={{ width: '150px', height: '150px' }}
                                >
                                    <i className="fas fa-user-circle fa-5x text-secondary"></i>
                                </div>
                            </div>

                            <h5 className="mt-3 mb-1 fw-bold text-primary">
                                <Link to={`/patient/overview?pId=${id}`}>
                                    {patientData.name}
                                </Link>
                            </h5>
                            <span
                                className={`badge ${getStatusClass(
                                    patientData.status
                                )} rounded-pill px-3 py-2`}
                            >
                                {patientData.status}
                            </span>
                        </div>
                    </div>

                    {/* Right Side - Patient Details */}
                    <div className="col-8 ps-md-4">
                        <div className="row g-4 fs-5 mt-3">
                            <div className="col-md-6 ">
                                <div className="mb-4">
                                    <div className="d-flex align-items-center mb-3">
                                        <span className="text-muted me-2 w-25">
                                            Gender:
                                        </span>
                                        <span className="fw-semibold">
                                            {patientData.gender}
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center mb-3">
                                        <span className="text-muted me-2 w-25">
                                            Age:
                                        </span>
                                        <span className="fw-semibold">
                                            {patientData.age} years
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-4">
                                    <div className="d-flex align-items-center mb-3">
                                        <span className="text-muted me-2 w-25">
                                            Remarks:
                                        </span>
                                        <span className="fw-semibold">
                                            {patientData.remarks}
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center mb-3">
                                        <span className="text-muted me-2 w-25">
                                            Symptoms:
                                        </span>
                                        <span className="fw-semibold">
                                            {patientData.symptoms}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Video Call Button */}
                        <div className="mt-6 pt-6 border-top d-flex align-items-center">
                            <button className="btn btn-success btn-md d-inline-flex align-items-center">
                                <i className="fas fa-video me-2"></i>
                                Start Video Call
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientDetails;
