import { useMemo, useState } from 'react';
import { Field, ErrorMessage, Form, Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
// import { Autocomplete, TextField } from "@mui/material";

import { IndentInitalValues, IndentSchema } from '../IndentSchema';
import { splitString } from '../../../../../common/utils';
import useGetRequest from '../../../../../hooks/getRequest.query';
import { getAllProducts } from '../../../../../services/supply-chain/product-master/MasterProduct.services';
import _ from 'lodash';
import { AutoCompleteInput } from '../../../../../components/Inputs';

const IndentForm = ({ setProductDetails }: any) => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const title = status === 'Draft' ? 'Edit' : 'Create';

  const [selectedProductDetails, setSelectedProductDetails]: any = useState({});

  // const submitStep = (values: typeof IndentInitalValues) => {
  const submitStep = (values: any, { resetForm }: any) => {
    const prodName = splitString(values?.name, 0);
    const genericName = splitString(values?.name, 1);
    const manufacturerName = splitString(values?.name, 2);
    const productId = splitString(values?.name, 3);
    // setProductDetails([
    //     ...productDetails,
    // {
    //     searchType: values?.searchType,
    //     productName,
    //     genericName,
    //     manufactureName,
    //     units: values?.units,
    //     noOfStrips: values?.noOfStrips,
    // },
    // ]);

    setProductDetails((prevValue: any) => {
      if (prevValue) {
        return [
          ...prevValue,
          {
            productId,
            searchType: values?.searchType,
            productName: prodName,
            genericName,
            manufacturerName,
            unitPerStrip: values?.unitPerStrip,
            noOfstrips: values?.noOfStrips,
            totalUnit: values?.unitPerStrip * values?.noOfStrips,
          },
        ];
      }
      return prevValue;
    });

    resetForm();
    setSelectedProductDetails({});
  };

  const handleInputKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  // Products API
  const [currentPageNumber] = useState(1);
  const [pageLimit] = useState(40);
  const [productName, setProductName] = useState('');
  const { data }: any = useGetRequest(
    ['getAllProducts', pageLimit, productName],
    () =>
      getAllProducts({
        page: currentPageNumber,
        limit: pageLimit,
        productName: productName,
      }),
    { staleTime: 100000, refetchOnWindowFocus: false }
  );

  const productList = useMemo(() => {
    return data?.data?.data?.products.length > 0
      ? data?.data?.data?.products?.map((item: any) => {
          return {
            productId: item?._id,
            productName: item?.productName,
            label: item?.productName,
            genericName: item?.genericName,
            manufacturerName: item?.manufacturerName,
          };
        })
      : [];
  }, [data?.data?.data?.products]);

  return (
    <div className="w-100">
      <Formik
        validationSchema={IndentSchema}
        initialValues={IndentInitalValues}
        onSubmit={submitStep}
      >
        {(formikProps) => {
          return (
            <Form className="card" onKeyDown={handleInputKeyDown}>
              <h2 className="p-3 fw-bolder text-dark">{title} Indent</h2>
              <div className="d-flex justify-content-between w-100 p-5 pb-0">
                <div className="fv-row mb-10 " style={{ width: 300 }}>
                  <label className="form-label required">Product Name</label>
                  <AutoCompleteInput
                    // label={"Product Name"}
                    name="product"
                    data-testid="patient-menu"
                    // @ts-ignore
                    onInputChange={(e: any) => {
                      _.debounce(
                        // @ts-ignore
                        () => setProductName(e?.target?.value),
                        2000
                      )();
                    }}
                    onChange={(e: any, value: any) => {
                      // formik.setFieldValue("patientName", value.label);
                      // formik.setFieldValue("patientObj", value);
                      formikProps?.setFieldValue('product', value.label);
                      setSelectedProductDetails(value);
                      formikProps?.setFieldValue(
                        'name',
                        `${value?.productName};${value?.genericName};${value?.manufacturerName};${value?.productId}`
                      );
                    }}
                    value={formikProps?.values.product}
                    optionsArr={productList}
                    className="mb-3"
                  />

                  <div className="text-danger mt-2">
                    <ErrorMessage name="name" />
                  </div>
                </div>

                {/*  Number of Units */}
                <div className="fv-row mb-10">
                  <label className="form-label required">Units per strip</label>

                  <Field
                    type="input"
                    data-testid="units"
                    name="unitPerStrip"
                    placeholder="Enter required units"
                    className="form-control form-control-lg form-control-solid"
                  />
                  <div className="text-danger mt-2">
                    <ErrorMessage name="unitPerStrip" />
                  </div>
                </div>

                {/* No of strips */}
                <div className="fv-row mb-10">
                  <label className="form-label required">
                    Number of strips
                  </label>

                  <Field
                    type="input"
                    data-testid="noOfStrips"
                    name="noOfStrips"
                    placeholder="Enter number of strips"
                    className="form-control form-control-lg form-control-solid"
                  />
                  <div className="text-danger mt-2">
                    <ErrorMessage name="noOfStrips" />
                  </div>
                </div>

                {/* Add button */}
                <div className="fv-row mb-10">
                  <button type="submit" className="btn btn-primary">
                    Add
                  </button>
                </div>
              </div>
              <div className="d-flex px-5 justify-content-between w-100">
                <div className="fv-row mb-10">
                  <label className="form-label">Generic Name</label>

                  <input
                    readOnly
                    type="input"
                    placeholder="Generic Name"
                    defaultValue={selectedProductDetails?.genericName}
                    data-testid="middle-name"
                    className="form-control form-control-lg form-control-solid"
                  />
                </div>
                <div className="fv-row mb-10">
                  <label className="form-label">Manufacture Name</label>

                  <input
                    type="input"
                    readOnly
                    placeholder="Manufacture Name"
                    defaultValue={selectedProductDetails?.manufacturerName}
                    data-testid="middle-name"
                    className="form-control form-control-lg form-control-solid"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default IndentForm;
