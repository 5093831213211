interface GenericErrors {
  [statusCode: number]: string;
}

const genericErrors: GenericErrors = {
  400: 'Oops! Something went wrong with your request. Please check and try again.',
  401: 'You need to sign in to access this content.',
  403: `Access denied. You don’t have permission to view this.`,
  404: 'We couldn’t find what you’re looking for.',
  408: 'Request timed out. Please check your connection and try again.',
  429: 'You’re going a bit fast! Please wait and try again later.',
  500: 'Sorry, something went wrong on our end. Please try again soon.',
  502: 'Temporary issue. The server had trouble processing your request. Try again soon.',
  503: 'Service is currently unavailable. Please check back later.',
  504: 'Request timed out. The server took too long to respond. Try again later.',
};
export function getErrorMessage(statusCode: number) {
  // Check if the status code exists in our generic error object
  return genericErrors[statusCode];
  // ||
  // 'An unexpected error occurred. Please try again later.'
}

export function isErrorMsgNotReadable(message: string) {
  if (!message) return true;
  if (message?.includes('{') || message?.includes('[')) {
    return true;
  } else {
    return false;
  }
}
