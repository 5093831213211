import { useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import toast from 'react-hot-toast';
import useGetRequest from '../../../../hooks/getRequest.query';
import { getFacilityList } from '../../../../services/facility/Facility.hook';
import {
  convertUtcTimestampToLocalDate,
  //   dateFormatter,
} from '../../../../common/utils';

// type routeProps = {
//   id: string;
// };

const DoctorProfileDetails = ({ doctorData, isLoading }: any) => {
  //   const { id } = useParams<routeProps>();
  const doctorDetails = doctorData;
  const doctorFacilities = doctorDetails?.facility;

  const dateOfJoining = convertUtcTimestampToLocalDate(
    doctorDetails?.createdAt
  );

  let { postalCode, country, district, state, town } =
    doctorDetails?.addresses || {};

  const doctorAddress =
    doctorDetails?.addresses &&
    `${town} , ${district} , ${postalCode} , ${state} , ${country}`;

  const { data, refetch }: any = useGetRequest(
    'getAllFacilites-doctorProfile',
    async () =>
      await getFacilityList({
        page: 1,
        limit: 50,
      }),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const facilityData = data?.data?.data?.facility;

  const getFacilityNames = () => {
    return facilityData?.filter((facility: any) => {
      return doctorFacilities?.some(
        (doctorFacility: any) =>
          doctorFacility?.facilityCode === facility?.facilityCode
      );
      // ?.map((o: any) => `${o?.facilityFirstName}  ${o?.facilityLastName}`);
    });
  };

  let facilityNames = getFacilityNames()?.map(
    (facility: any) =>
      `${facility?.facilityFirstName}  ${facility?.facilityLastName}`
  );

  let facilityName = facilityNames?.join(', ');

  const downloadPresciption = () =>
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          Presciption Downloaded
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
      }
    );
  return (
    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <div className="card-header cursor-pointer">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Doctor Details</h3>
        </div>
      </div>
      {isLoading ? (
        <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : (
        <div className="card-body p-9">
          {/* Doctor qualification */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">
              Doctor qualification
            </label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark">
                {doctorDetails?.qualification
                  ? doctorDetails?.qualification
                  : 'N/A'}
              </span>
            </div>
          </div>

          {/* Date of joining */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">
              Date of joining
            </label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark">
                {dateOfJoining ?? 'N/A'}
              </span>
            </div>
          </div>

          {/* Organization */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">
              Organization
              <i
                className="fas fa-exclamation-circle ms-1 fs-7"
                data-bs-toggle="tooltip"
                title="Active organization"
              ></i>
            </label>

            <div className="col-lg-8">
              {doctorDetails?.organization ? (
                doctorDetails?.organization?.map((organizationDetails: any) => {
                  return (
                    <>
                      <span className="fw-bolder fs-6  text-dark">
                        {organizationDetails?.organizationId}
                      </span>
                    </>
                  );
                })
              ) : (
                <span className="fw-bolder fs-6  text-dark">N/A</span>
              )}
            </div>
          </div>

          {/* Specialization */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">
              Specialization
            </label>
            <div className="col-lg-8">
              {doctorDetails?.specialization ? (
                doctorDetails?.specialization?.map(
                  (specializationDetails: any) => {
                    return (
                      <>
                        <span className="fw-bolder fs-6  text-dark">
                          {specializationDetails?.specializationName}
                        </span>
                      </>
                    );
                  }
                )
              ) : (
                <span className="fw-bolder fs-6  text-dark">N/A</span>
              )}
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Facility Name</label>
            <div className="col-lg-8">
              {doctorDetails?.facility ? (
                <span className="fw-bolder fs-6  text-dark">
                  {facilityName}
                </span>
              ) : (
                <span className="fw-bolder fs-6  text-dark">N/A</span>
              )}
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">
              License Number
            </label>

            <div className="col-lg-8 fv-row">
              <span className="fw-bolder fs-6">
                {doctorDetails?.demographicDetails?.license?.licenseNumber
                  ? doctorDetails?.demographicDetails?.license?.licenseNumber
                  : 'N/A'}
              </span>
            </div>
          </div>

          {/* Address */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Address</label>

            <div className="col-lg-8 fv-row">
              <span className="fw-bolder fs-6">
                {doctorDetails?.addresses && doctorAddress
                  ? doctorAddress
                  : 'N/A'}
              </span>
            </div>
          </div>

          {/*  License Details */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">
              License Details
            </label>

            <div className="col-lg-8 fv-row">
              <a
                href={
                  doctorDetails?.demographicDetails?.license?.licenseLocation
                }
                target="_blank"
                rel="noreferrer"
              >
                <i
                  title="Download the license"
                  onClick={downloadPresciption}
                  style={{ cursor: 'pointer' }}
                  className="fs-2 fas fa-arrow-circle-down text-hover-primary"
                ></i>
              </a>
            </div>
          </div>

          {/*  License Provider */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">
              License Provider
            </label>

            <div className="col-lg-8 fv-row">
              <span className="fw-bolder fs-6 text-dark">
                {doctorDetails?.demographicDetails?.license?.licenseProvider
                  ? doctorDetails?.demographicDetails?.license?.licenseProvider
                  : 'N/A'}
              </span>
            </div>
          </div>

          {/* Gender */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Gender</label>

            <div className="col-lg-8 d-flex align-items-center">
              <span className="fw-bolder fs-6 me-2">
                {doctorDetails?.demographicDetails?.sex
                  ? doctorDetails?.demographicDetails?.sex
                  : 'N/A'}
              </span>
            </div>
          </div>

          {/* Contact Phone */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Contact Phone</label>

            <div className="col-lg-8 d-flex align-items-center">
              <span className="fw-bolder fs-6 me-2">
                {doctorDetails?.demographicDetails?.telecom
                  ? doctorDetails?.demographicDetails?.telecom
                  : 'N/A'}
              </span>
            </div>
          </div>

          {/* Email Id */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Email Id</label>

            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark text-hover-primary">
                {doctorDetails?.demographicDetails?.email
                  ? doctorDetails?.demographicDetails?.email
                  : 'N/A'}
              </span>
            </div>
          </div>

          <div className="row mb-10">
            <label className="col-lg-4 fw-bold text-muted">
              Communication Language
            </label>
            <div className="col-lg-8">
              <span className="fw-bolder fs-6 text-dark">
                {doctorDetails?.demographicDetails?.communication?.language
                  ? doctorDetails?.demographicDetails?.communication?.language
                  : 'N/A'}
              </span>
            </div>
          </div>

          <div className="row mb-10">
            <label className="col-lg-4 fw-bold text-muted">
              Consultation Fee
            </label>
            <div className="col-lg-8 d-flex flex-column">
              <div className="col-lg-6">
                <label className="w-175px fw-bold text-muted">
                  Online Consultation
                </label>
                <span className="ms-5 fw-bolder fs-6 text-dark">
                  {doctorDetails?.consultationFee?.onlineConsultation
                    ? doctorDetails?.consultationFee?.onlineConsultation
                    : 'N/A'}
                </span>
              </div>
              <div className="col-lg-6">
                <label className="w-175px fw-bold text-muted">
                  Physcial Consultation
                </label>
                <span className="ms-5 fw-bolder fs-6 text-dark">
                  {doctorDetails?.consultationFee?.physicalConsultation
                    ? doctorDetails?.consultationFee?.physicalConsultation
                    : 'N/A'}
                </span>
              </div>
            </div>
          </div>

          <div className="row mb-10">
            <label className="col-lg-4 fw-bold text-muted">
              Consultation Limit
            </label>
            <div className="col-lg-8 d-flex flex-column">
              <div className="col-lg-6">
                <label className="w-175px fw-bold text-muted">
                  Online Consultation
                </label>
                <span className="ms-5 fw-bolder fs-6 text-dark">
                  {doctorDetails?.consultationLimit?.onlineConsultation
                    ? doctorDetails?.consultationLimit?.onlineConsultation
                    : 'N/A'}
                </span>
              </div>
              <div className="col-lg-6">
                <label className="w-175px fw-bold text-muted">
                  Physcial Consultation
                </label>
                <span className="ms-5 fw-bolder fs-6 text-dark">
                  {doctorDetails?.consultationLimit?.physicalConsultation
                    ? doctorDetails?.consultationLimit?.physicalConsultation
                    : 'N/A'}
                </span>
              </div>
            </div>
          </div>

          {/* Signature */}
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold text-muted">Signature</label>

            <div className="col-lg-8 fv-row">
              <img
                src={
                  doctorDetails?.signature ? doctorDetails?.signature : 'N/A'
                }
                alt="licenseLocation"
                width="150px"
                height="150px"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DoctorProfileDetails;
