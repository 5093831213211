import { Toaster } from "react-hot-toast";
import { AppProviders } from "./providers/AppProviders";
import Routes from "./routing/Routes";

const App = () => {
    return (
        <AppProviders>
            <Toaster />
            <Routes />
        </AppProviders>
    );
};

export { App };
