import { createContext, useContext, useEffect, useState } from 'react';
import decode from 'jwt-decode';
import {
    ModuleScopesType,
    ModuleType,
    SubModuleType,
} from '../common/config/rbac/types/rbac.types';

type RBACContextProviderProps = {
    children: React.ReactNode;
};

type RBACContextType = {
    userRole: any;
    accessibleModules: any;
    // getModules: (modules: ModuleType, submodules?: any) => void;
    getModules: (
        modules: ModuleType,
        submodules?: SubModuleType
    ) => ModuleScopesType[];
    // getModules: any;
    // checkModuleScope: any;
    checkModuleScope: (
        scopeArray: ModuleScopesType[],
        scope: ModuleScopesType
    ) => any;
    getFacilityStatus: () => string | undefined;
};

const RBACContext = createContext({} as RBACContextType);

export const RBACProvider = ({ children }: RBACContextProviderProps) => {
    const [userRole, setUserRole] = useState('');
    const [accessibleModules, setAccessibleModules]: any = useState([]);

    useEffect(() => {
        setLoggedInRole();
        setModulesByRole();
    }, []);

    // Pull organization plan from localStorage
    const checkOrgPlan = () => {
        return localStorage.getItem('organizationPlan');
    };

    // Get Role from the token
    const setLoggedInRole = () => {
        try {
            const accessToken = JSON.parse(
                localStorage.getItem('access_token') || '{}'
            );
            //@ts-ignore
            const { realm_access }: any = decode(accessToken);

            let role = '';
            if (realm_access?.roles.indexOf('FACILITY_MANAGER') !== -1) {
                role = 'FACILITY_MANAGER';
            } else if (realm_access?.roles.indexOf('DOCTOR') !== -1) {
                role = 'DOCTOR';
            } else {
                role = 'ORGANIZATION_ADMIN';
            }
            // realm_access?.roles.indexOf("FACILITY_MANAGER") !== -1
            //   ? "FACILITY_MANAGER"
            //   : "ORGANIZATION_ADMIN";
            setUserRole(role);
            localStorage.setItem('userRole', role);
        } catch (error) {
            console.log(error);
        }
    };

    // Get the resources from the token
    const setModulesByRole = () => {
        try {
            const accessToken = JSON.parse(
                localStorage.getItem('access_token') || '{}'
            );
            //@ts-ignore
            const { resource_access }: any = decode(accessToken);
            const userModules = resource_access;
            setAccessibleModules(userModules);
        } catch (error) {
            console.log(error);
        }
    };

    // Get the resources from the token
    const getModules = (modules: ModuleType, submodules?: SubModuleType) => {
        try {
            const accessToken = JSON.parse(
                localStorage.getItem('access_token') || '{}'
            );
            //@ts-ignore
            const { resource_access } = decode(accessToken);
            const userModules = resource_access[modules];
            if (submodules) {
                // checkModuleScope(modules, submodules);
                const subModules = resource_access[modules][submodules];
                return subModules;
            }
            return userModules;
        } catch (error) {
            console.log(error);
        }
    };

    // Check module scope [ Create , Update , Read, Delete]
    const checkModuleScope = (
        scopeArray: ModuleScopesType[],
        scope: ModuleScopesType
    ) => {
        try {
            let m = scopeArray?.includes(scope);
            return m;
        } catch (error) {
            console.log(error);
        }
    };

    // check for facilitycode in resource_access
    const getFacilityStatus = () => {
        try {
            const accessToken = JSON.parse(
                localStorage.getItem('access_token') || '{}'
            );
            //@ts-ignore
            const { resource_access } = decode(accessToken);
            const exist = resource_access?.Facility?.facilityCode;
            return exist?.length > 0 ? 'active' : 'unactive';
        } catch (error) {
            console.log(error);
        }
    };
    const value = {
        userRole,
        accessibleModules,
        getModules,
        checkModuleScope,
        getFacilityStatus,
    };
    return (
        <RBACContext.Provider value={value}>{children}</RBACContext.Provider>
    );
};

export const useRBAC = () => {
    return useContext(RBACContext);
};
