import * as Yup from 'yup';

const IMAGE_SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bmp',
];

export type courseLandingPageTypes = {
  courseTitle: string;
  courseDescription: string;
  courseLanguage: string;
  courseDuration: string;
  chwGrade: string;
  courseCategory: string;
  courseSubcategory: string;
  coursePrimaryFocus: string;
  courseImage: string;
};

export const initalValues: courseLandingPageTypes = {
  courseTitle: '',
  courseDescription: '',
  courseLanguage: '',
  courseDuration: '',
  chwGrade: '',
  courseCategory: '',
  courseSubcategory: '',
  coursePrimaryFocus: '',
  courseImage: '',
};

export const courseLandingPageValidationSchema = Yup.object({
  courseTitle: Yup.string().required('Course title is required'),
  courseDescription: Yup.string().required('Course description is required'),
  courseDuration: Yup.string().required('Course Duration is required'),
  courseLanguage: Yup.string().required('Course language is required'),
  chwGrade: Yup.string().required('CHW grade is required'),
  courseCategory: Yup.string().required('Course category is required'),
  courseSubcategory: Yup.string().required('Course Subcategory is required'),
  coursePrimaryFocus: Yup.string().required(
    'Course primarily thought is required'
  ),
  courseImage: Yup.mixed()
    .nullable()
    .required('Course Image is required')
    .test(
      'FILE_SIZE',
      'Uploaded file is too big.',
      (value) => !value || (value && value.size <= 1024 * 1024)
    )
    .test(
      'FILE_FORMAT',
      'Uploaded file has unsupported format.',
      (value) =>
        !value || (value && IMAGE_SUPPORTED_FORMATS.includes(value?.type))
    ),
});
