import { useEffect, useMemo, useState } from 'react';
import { prevMonth } from '../../../common/utils/datesUtils';
import { useNavbar } from '../../../context/NavbarContext';
import useGetRequest from '../../../hooks/getRequest.query';

import {
  getFacilityOverviewViaChart,
  getFacilityOverviewViaTable,
} from '../../../services/performance-analysis/PerformanceAnalysis.hook';

import KPITable from './table-view/KPITable';
import KPIChart from './chart-view/KPIChart';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../common/utils/genericErrors';

const KPIAnalysisPage = ({ analysis }: any) => {
  let defaultData = prevMonth && prevMonth[0]?.monthYear;
  const { selectedFacility } = useNavbar();

  const [period, setPeriod] = useState<string>(defaultData);
  const handlePeriod = (event: any) => {
    setPeriod(event.target.value);
  };

  const splitMonthAndYear = period?.split(' ');
  const splitMonth = splitMonthAndYear && splitMonthAndYear[0];
  const splitYear = splitMonthAndYear && splitMonthAndYear[1];
  const facilityCode: any = localStorage.getItem('facilityCode');

  // Chart View API
  const {
    data: chartData,
    isLoading: isChartLoading,
    isFetching: isChartFetching,
    isError: isChartError,
    error: chartError,
    refetch: chartRefetcher,
  }: any = useGetRequest(
    'getFacilityOverviewViaChart',
    () =>
      getFacilityOverviewViaChart({
        facilityCode,
        month: splitMonth,
        year: splitYear,
        targetName: analysis,
      }),
    { staleTime: 100000, refetchOnWindowFocus: false }
  );

  const chartViewData = [chartData?.data?.data?.targets];

  const custuomChartViewData = chartViewData?.map((cd: any) => {
    return {
      TargetName: cd?.targetName,
      Target: cd?.target,
      Achievment: cd?.achievment,
      totalTargetsRemaining: cd?.totalTargetsRemaining,
      Name: cd?.name,
    };
  });

  const chartErrorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(chartError?.response?.data?.message)
        ? getErrorMessage(chartError?.response?.status)
        : chartError?.response?.data?.message,
    [chartError]
  );

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  // Table View API
  const {
    data: tableData,
    isLoading: isTableLoading,
    isFetching: isTableFetching,
    isError: isTableError,
    error: tableError,
    refetch: tableRefetcher,
  }: any = useGetRequest(
    ['getFacilityOverviewViaTable', pageLimit],
    () =>
      getFacilityOverviewViaTable({
        facilityCode,
        month: splitMonth,
        year: splitYear,
        page: currentPageNumber,
        limit: pageLimit,
        targetName: analysis,
      }),
    { staleTime: 100000, refetchOnWindowFocus: false }
  );

  const tableViewData = tableData?.data?.data?.target;
  const totalEntites = tableData?.data?.results;
  const totalPages = Math.ceil(totalEntites / pageLimit);
  const tableErrorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(tableError?.response?.data?.message)
        ? getErrorMessage(tableError?.response?.status)
        : tableError?.response?.data?.message,
    [tableError]
  );

  const successMsg = tableData?.data?.data?.message;

  useEffect(() => {
    tableRefetcher();
    chartRefetcher();
  }, [period, defaultData, analysis, selectedFacility]);

  return (
    <>
      {/* Month Selection */}

      <div className="fv-row d-flex align-items-end card p-5">
        <select
          onChange={handlePeriod}
          className="w-200px text-start form-select form-select-lg form-select-solid"
        >
          {prevMonth?.map((month: any) => {
            return (
              <>
                <option
                  selected={period === month?.monthYear}
                  value={month?.monthYear}
                >
                  {month?.monthAndYear}
                </option>
              </>
            );
          })}
        </select>
      </div>

      <div className="card w-100">
        <KPIChart
          isChartLoading={isChartLoading}
          isChartFetching={isChartFetching}
          isChartError={isChartError}
          chartErrorMsg={chartErrorMsg}
          customChartViewData={custuomChartViewData}
        />
      </div>
      <div className="mt-5">
        {!isTableError && (
          <KPITable
            isTableFetching={isTableFetching}
            isTableLoading={isTableLoading}
            successMsg={successMsg}
            currentPageNumber={currentPageNumber}
            setCurrentPageNumber={setCurrentPageNumber}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            tableErrorMsg={tableErrorMsg}
            isTableError={isTableError}
            totalPages={totalPages}
            totalEntites={totalEntites}
            tableViewData={tableViewData}
          />
        )}
      </div>
    </>
  );
};

export default KPIAnalysisPage;
