import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../helpers';
import { useAuth } from '../../../../context/AuthContext';
import { useNavbar } from '../../../../context/NavbarContext';
import { RBACComponent } from '../../../../common/config/rbac/components/RBACComponent';
import { permittedRoles } from '../../../../common/config/rbac/types/rbac.types';
import TokenService from '../../../../common/utils/TokenService';
import { getDoctorDetailsByUsername } from '../../../../services/doctor/features/GetDoctorDetails.hook';
import useMutateRequest from '../../../../hooks/getMutation.query';
import { useEffect } from 'react';

const HeaderUserMenu = () => {
    const { handleAuth } = useAuth();
    const { loggedInUser, userPhoto, userRole, handleUserId } = useNavbar();

    const organizationId = JSON.parse(
        localStorage.getItem('organizationId') || '{}'
    );

    const removeUserAccess = () => {
        // Screen 2 ( Logged Inuser )
        TokenService.removeUser();
        handleAuth(false);
        if (organizationId) {
            window.location.href = '/auth/login';
        } else {
            window.location.href = '/';
        }
    };

    const { data, isLoading, mutate, isError, error }: any = useMutateRequest(
        async (data: any) => await getDoctorDetailsByUsername(data)
    );
    const getData = () => {
        try {
            const payload = {
                userName: loggedInUser?.preferred_username,
            };

            mutate(payload, {
                onSuccess: (data: any) =>
                    handleUserId(data?.data?.data?.doctor?._id),
            });
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (userRole === 'DOCTOR') getData();
    }, [loggedInUser]);
    const id = data?.data?.data?.doctor?._id;

    return (
        <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
            data-kt-menu="true"
        >
            <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-3">
                    <div className="symbol symbol-50px me-5">
                        <img
                            src={
                                userPhoto
                                    ? userPhoto
                                    : toAbsoluteUrl('/media/ikure/blank.png')
                            }
                            alt={loggedInUser?.userName}
                        />
                    </div>

                    <div className="d-flex flex-column">
                        <div className="fw-bolder d-flex align-items-center fs-5">
                            {loggedInUser?.userName}
                        </div>
                        <span className="mt-1 badge badge-info">
                            {userRole}
                        </span>
                    </div>
                </div>
            </div>

            <div className="menu-item px-5">
                <RBACComponent
                    whichRoles={[
                        permittedRoles?.organizationAdmin,
                        permittedRoles?.facilityManager,
                    ]}
                >
                    <Link to={'/chw/chw-management'} className="menu-link px-5">
                        <i className="far fa-folder-open fs-2 mx-2"></i>
                        CHW Management
                    </Link>
                </RBACComponent>
            </div>
            <RBACComponent whichRoles={[permittedRoles?.organizationAdmin]}>
                <div className="menu-item px-5">
                    <Link to={'/settings'} className="menu-link px-5">
                        <i className="fas fa-cog fs-2 mx-2"></i>
                        Settings
                    </Link>
                </div>
            </RBACComponent>
            <RBACComponent whichRoles={[permittedRoles?.doctor]}>
                {id && (
                    <div className="menu-item px-5">
                        <Link
                            to={`/doctor/overview?dId=${id}`}
                            className="menu-link px-5"
                        >
                            <i className="fa fa-user fs-2 mx-2"></i>
                            Profile
                        </Link>
                    </div>
                )}
            </RBACComponent>
            {/* <div className="separator my-1"></div> */}
            <div className="menu-item px-5">
                <span
                    onClick={removeUserAccess}
                    className="menu-link px-5 border-none text-primary ms-3"
                >
                    <i className="fas fa-sign-out-alt fs-3 me-2"></i>
                    Logout
                </span>
            </div>
        </div>
    );
};

export { HeaderUserMenu };
