import { useEffect, useMemo, useState } from 'react';

import { HealthCardTypeTable } from './HealthCardTypeTable';
import { useNavbar } from '../../../context/NavbarContext';
import useGetRequest from '../../../hooks/getRequest.query';
import { getListOfAllPrimarySubscription } from '../../../services/HealthCard.hook';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../common/utils/genericErrors';

const HealthCardTypePage = () => {
  const { selectedFacility } = useNavbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const facilityCode = localStorage.getItem('facilityCode');

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    isError: isCHWError,
    error: ErrorMsg,
  }: any = useGetRequest(
    ['getListOfAllPrimarySubscription', pageNumber, pageLimit],
    async () =>
      await getListOfAllPrimarySubscription({
        facilityCode,
        page: pageNumber,
        limit: pageLimit,
      }),
    {
      // retry: false,
      // staleTime: 100000,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [facilityCode, selectedFacility]);

  const totalCHWEntites = data?.data?.results;
  const patientData = data?.data?.data?.patient;
  const totalAvailablePages = data?.data?.results; // Math.ceil(totalCHWEntites / pageLimit);
  const chwErrorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(ErrorMsg?.response?.data?.message)
        ? getErrorMessage(ErrorMsg?.response?.status)
        : ErrorMsg?.response?.data?.message,
    [ErrorMsg?.response?.data?.message]
  );

  return (
    <>
      <div className="mt-5">
        <HealthCardTypeTable
          pageLimit={pageLimit}
          currentPageNumber={pageNumber}
          handlePageNumber={setPageNumber}
          totalCHWEntites={totalCHWEntites}
          totalGetCHWPages={totalAvailablePages}
          getPatientLoader={isLoading}
          getPatientFetcher={isFetching}
          isCHWError={isCHWError}
          chwErrorMsg={chwErrorMsg}
          bodyProps={patientData}
          refetch={refetch}
          setPageLimit={setPageLimit}
        />
      </div>
    </>
  );
};

export default HealthCardTypePage;
