import InfoCard from '../../components/InfoCard';
import { FacilityOverview } from './components/FacilityOverview';
import { CHWStatus } from './tables/CHWStatus';

import SkeletonCard from '../../components/skeleton/SkeletonCard';

import { useEffect, useMemo, useState } from 'react';

import ClipLoader from 'react-spinners/ClipLoader';
import { useNavbar } from '../../context/NavbarContext';
import { permittedRoles } from '../../common/config/rbac/types/rbac.types';
import { useRBAC } from '../../context/RBACContext';
import useGetRequest from '../../hooks/getRequest.query';
import { getDashboardData } from '../../services/Dashboard.hook';
// import { getTasksTarget } from '../../services/task/TaskManage.hook';
import { useFilterRange } from '../../context/FilterRangeContext';
// import { useFeatureValue } from '@growthbook/growthbook-react';
// import { hasAccessToModule } from '../../rbac';
// import { Permissions } from '../../rbac/types';
import {
    getErrorMessage,
    isErrorMsgNotReadable,
} from '../../common/utils/genericErrors';

const DashboardPage = () => {
    const { period } = useFilterRange();
    const {
        //selectedFacility,
        handleUserPhoto,
    } = useNavbar();

    const { userRole } = useRBAC();
    const [pageNo] = useState(0);

    const facilityCode = localStorage.getItem('facilityCode') || '';
    const loggedInData = {
        page: pageNo,
        limit: 10,
        facilityCode: facilityCode,
    };

    const {
        data: dashboardData,
        isLoading,
        isFetching,
        isError: errorBoolean,
        error: dashboardError,
        refetch,
    }: any = useGetRequest(
        ['dashboardDatas', facilityCode],
        async () =>
            await getDashboardData({
                ...loggedInData,
                period,
            }),
        {
            refetchOnWindowFocus: false,
            enabled: !!facilityCode,
        }
    );

    const dashboard = dashboardData?.data?.data?.homePage;
    const taskData = dashboardData?.data?.data?.homePage?.totalTaskCounts;
    const chwData = dashboardData?.data?.data?.homePage?.chwLists;
    const totalNumberOfCHW =
        dashboardData?.data?.data?.homePage?.chwLists?.length;

    const photo = dashboardData?.data?.data?.homePage?.photo;
    const facilityManagerId =
        dashboardData?.data?.data?.homePage?.facilityManagerId;
    const facilityList = dashboardData?.data?.data?.homePage?.facility;
    let dF = dashboardData?.data?.data?.homePage?.facility[0];

    useEffect(() => {
        localStorage.setItem('facilityManagerId', facilityManagerId || '');
        localStorage.setItem('photo', photo);
        handleUserPhoto(photo);
        if (userRole === permittedRoles.facilityManager) {
            if (!facilityCode) {
                let facilityCode = dF?.facilityCode;
                let facilityName =
                    dF?.facilityFirstName + ' ' + dF?.facilityLastName;
                localStorage.setItem('facilityCode', facilityCode);
                localStorage.setItem('facilityName', facilityName);
            }
        }
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period, facilityList, dF, photo, facilityCode, facilityManagerId]);

    // This section is for patient counter
    const patientnewCount = Number(
        dashboard?.totalPatientCounts.patientCounter
    );
    const patientOld = Number(
        dashboard?.totalPatientCounts.patientCounterPrevious
    );
    const patientDecreaseVal = patientnewCount - patientOld;
    const patientRateChange = Math.round(
        (patientDecreaseVal / patientnewCount) * 100
    ).toFixed(1);
    let patientRateChangeVal;

    if (isNaN(parseFloat(patientRateChange))) {
        patientRateChangeVal = '0';
    } else {
        // patientRateChangeVal = patientRateChange;
        patientRateChangeVal = parseFloat(patientRateChange);
        // Limit the rate change value to not exceed 100%
        if (patientRateChangeVal > 100) {
            patientRateChangeVal = 100;
        } else if (patientRateChangeVal < -100) {
            patientRateChangeVal = -100;
        }
    }

    if (patientRateChange === '-Infinity') {
        patientRateChangeVal = -100;
    }

    let patientTextData;

    if (patientDecreaseVal > 0) {
        patientTextData = 'Increase';
    }

    if (patientDecreaseVal === 0) {
        patientTextData = 'Increase';
    }

    if (patientDecreaseVal < 0) {
        patientTextData = 'Decrease';
    }
    //--- Patient counter section end ---//

    // This is section is for vitals collected/counter
    const VitalNewCount = Number(
        dashboard?.totalVitalCounts.vitalsCountByFacility
    );
    const VitalOld = Number(
        dashboard?.totalVitalCounts.vitalsCountByFacilityprevious
    );
    const vitalDecreaseVal = VitalNewCount - VitalOld;
    const vitalRateChange = Math.round(
        (vitalDecreaseVal / VitalNewCount) * 100.0
    ).toFixed(1);

    let vitalRateChangeVal;
    if (isNaN(parseFloat(vitalRateChange))) {
        vitalRateChangeVal = '0';
    } else {
        vitalRateChangeVal = parseFloat(vitalRateChange);
        if (vitalRateChangeVal > 100) {
            vitalRateChangeVal = 100;
        } else if (vitalRateChangeVal < -100) {
            vitalRateChangeVal = -100;
        }
    }

    if (vitalRateChange === '-Infinity') {
        vitalRateChangeVal = -100;
    }

    let vitalTextData;
    if (vitalDecreaseVal > 0) {
        vitalTextData = 'Increase';
    }

    if (vitalDecreaseVal === 0) {
        vitalTextData = 'Increase';
    }

    if (vitalDecreaseVal < 0) {
        vitalTextData = 'Decrease';
    }
    //--- Vitals counter end ---//

    // This is section is for healthcards collected/counter

    const healthcardNewCount = Number(
        dashboard?.totalHealthCardCounts?.healthCardCounter
    );
    const healthcardOldCount = Number(
        dashboard?.totalHealthCardCounts?.previousHealthCardCounter
    );
    const healthcardDecreaseVal = healthcardNewCount - healthcardOldCount;
    const healthcardRateChange = Math.round(
        (healthcardDecreaseVal / healthcardNewCount) * 100.0
    ).toFixed(1);

    let healthcardRateChangeVal;
    if (isNaN(parseFloat(healthcardRateChange))) {
        healthcardRateChangeVal = '0';
    } else {
        healthcardRateChangeVal = parseFloat(healthcardRateChange);

        if (healthcardRateChangeVal > 100) {
            healthcardRateChangeVal = 100;
        } else if (healthcardRateChangeVal < -100) {
            healthcardRateChangeVal = -100;
        }
    }
    if (healthcardRateChange === '-Infinity') {
        healthcardRateChangeVal = -100;
    }
    let healthcardTextData;
    if (healthcardDecreaseVal > 0) {
        healthcardTextData = 'Increase';
    }
    if (healthcardDecreaseVal === 0) {
        healthcardTextData = 'Increase';
    }
    if (healthcardDecreaseVal < 0) {
        healthcardTextData = 'Decrease';
    }
    //--- Healthcard counter end ---//

    const cardList = [
        {
            title: 'TOTAL PATIENTS',
            totalCount: dashboard
                ? dashboard?.totalPatientCounts.patientCounter
                : 'No Data !!',
            arrow: dashboard
                ? dashboard?.totalPatientCounts.patientCounter ===
                  dashboard?.totalPatientCounts.patientCounterPrevious
                    ? 'equal'
                    : dashboard?.totalPatientCounts.patientCounter <
                      dashboard?.totalPatientCounts.patientCounterPrevious
                    ? 'down'
                    : 'up'
                : null,

            description: dashboard
                ? `${
                      dashboard?.totalPatientCounts.patientCounter <
                      dashboard?.totalPatientCounts.patientCounterPrevious
                          ? patientRateChangeVal + '% ' + patientTextData
                          : patientRateChangeVal + '% ' + patientTextData
                  } from last ${period}`
                : `Data not available for last ${period}`,
        },
        {
            title: 'VITALS COLLECTED',
            totalCount: dashboard
                ? dashboard?.totalVitalCounts.vitalsCountByFacility
                : 'No Data !!',
            arrow: dashboard
                ? dashboard?.totalVitalCounts.vitalsCountByFacility ===
                  dashboard?.totalVitalCounts.vitalsCountByFacilityprevious
                    ? 'equal'
                    : dashboard?.totalVitalCounts.vitalsCountByFacility <
                      dashboard?.totalVitalCounts.vitalsCountByFacilityprevious
                    ? 'down'
                    : 'up'
                : null,
            description: dashboard
                ? `${
                      dashboard?.totalVitalCounts.vitalsCountByFacility <
                      dashboard?.totalVitalCounts.vitalsCountByFacilityprevious
                          ? vitalRateChangeVal + '% ' + vitalTextData
                          : vitalRateChangeVal + '% ' + vitalTextData
                  } from last ${period}`
                : `Data not available for last ${period}`,
        },
        {
            title: 'HEALTH CARD',
            totalCount: dashboard
                ? dashboard?.totalHealthCardCounts?.healthCardCounter
                : 'No Data !!',
            arrow: dashboard
                ? dashboard?.totalHealthCardCounts?.healthCardCounter ===
                  dashboard?.totalHealthCardCounts?.previousHealthCardCounter
                    ? 'equal'
                    : dashboard?.totalHealthCardCounts?.healthCardCounter <
                      dashboard?.totalHealthCardCounts
                          ?.previousHealthCardCounter
                    ? 'down'
                    : 'up'
                : null,
            description: dashboard
                ? `${
                      dashboard?.totalHealthCardCounts?.healthCardCounter <
                      dashboard?.totalHealthCardCounts
                          ?.previousHealthCardCounter
                          ? healthcardRateChangeVal + '% ' + healthcardTextData
                          : healthcardRateChangeVal + '% ' + healthcardTextData
                  } from last ${period}`
                : `Data not available for last ${period}`,
        },
    ];

    // API Call

    //   const [pageNumber] = useState(1);
    //   const [pageLimit] = useState(10);

    //   const {
    //     // data,
    //     refetch: taskRefetch,
    //   }: // isError,
    //   // error: taskError,
    //   any = useGetRequest(
    //     ['getTasksTarget-dashboard', pageNumber, facilityCode],
    //     async () =>
    //       await getTasksTarget({
    //         page: pageNumber,
    //         limit: pageLimit,
    //         limitType: 'perDay',
    //         facilityCode: facilityCode,
    //       }),
    //     {
    //       retry: false,
    //       staleTime: 100000,
    //       refetchOnWindowFocus: false,
    //       enabled: !!facilityCode,
    //     }
    //   );

    //   useEffect(() => {
    //     taskRefetch();
    //   }, [selectedFacility]);

    //   const taskTargetData = data?.data?.data?.taskManagement;

    //   const teleconsultationTarget = taskTargetData?.filter((item: any) => {
    //     return item?.targetName === 'Teleconsultation';
    //   });

    //   const patientRegistrationTarget = taskTargetData?.filter((item: any) => {
    //     return item?.targetName === 'PatientRegistration';
    //   });

    //   let teleconsultationLimits =
    //     teleconsultationTarget && teleconsultationTarget[0]?.limits[0]?.limit;

    //   let patientLimits =
    //     patientRegistrationTarget && patientRegistrationTarget[0]?.limits[0]?.limit;

    //   let patientRegistrationTargetPerDay = patientLimits;
    //   let teleconsultationTargetPerDay = teleconsultationLimits;

    //   const taskList = [
    //     {
    //       title: 'TOTAL PATIENTS REGISTRATION',
    //       totalCount:
    //         isError === false
    //           ? totalNumberOfCHW * Number(patientRegistrationTargetPerDay)
    //           : 0,
    //       target: totalNumberOfCHW > 0 ? patientRegistrationTargetPerDay : null,
    //       style: 'col-xl-4',
    //     },
    //     {
    //       title: 'TOTAL TELECONSULTATION',
    //       totalCount:
    //         isError === false
    //           ? totalNumberOfCHW * Number(teleconsultationTargetPerDay)
    //           : 0,
    //       target: totalNumberOfCHW > 0 ? teleconsultationTargetPerDay : null,
    //       style: 'ms-3 col-xl-8',
    //     },
    //   ];
    //   const value = useFeatureValue('change-color', 'green');

    const dashboardErrorMsg = useMemo(
        () =>
            isErrorMsgNotReadable(dashboardError?.response?.data?.message)
                ? getErrorMessage(dashboardError?.response?.status)
                : dashboardError?.response?.data?.message,
        [dashboardError]
    );

    return (
        <div>
            {/* <div className="d-flex w-100">
        {isLoading || isFetching ? (
          <>
            <div className="col-xl-4">
              <SkeletonSmallCard />
            </div>
            <div className="col-xl-8">
              <SkeletonSmallCard />
            </div>
          </>
        ) : (
          taskList?.map((i: any) => {
            return (
              <div className={`${i.style} mb-4`}>
                <Card
                  elevation={2}
                  className="card-xl-stretch mb-xl-3"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "20px",
                    height: "60px",
                  }}
                >
                  <div>
                    <h2>{i?.title}</h2>
                  </div>
                  <div>
                    <CustomTooltip
                      TransitionComponent={Zoom}
                      title={"Total no of CHW * Target per day"}
                      placement="top"
                    >
                      <h2>
                        {i?.totalCount} / {i?.target}
                      </h2>
                    </CustomTooltip>
                  </div>
                </Card>
              </div>
            );
          })
        )}
      </div> */}

            <div className="row gy-5 gx-xl-8">
                {isLoading || isFetching ? (
                    <>
                        <div className="col-xl-4">
                            <SkeletonCard />
                        </div>
                        <div className="col-xl-4">
                            <SkeletonCard />
                        </div>
                        <div className="col-xl-4">
                            <SkeletonCard />
                        </div>
                    </>
                ) : (
                    cardList?.map((i: any) => {
                        return (
                            <div
                                // style={{ backgroundColor: value }}  // Growth book example
                                className="col-xl-4"
                            >
                                <InfoCard
                                    className="card-xl-stretch mb-xl-3 mb-5"
                                    cardSize={{ height: '200px' }}
                                    title={i.title}
                                    arrow={i.arrow}
                                    totalCount={i.totalCount}
                                    description={i.description}
                                />
                            </div>
                        );
                    })
                )}
            </div>

            <div className="row gy-5 gx-xl-8">
                {/* {isLoading || isFetching ? (
          <div className="col-xl-4">
            <FaclityOverviewSkeleton />
          </div>
        ) : ( */}
                <div className="col-xl-4">
                    <FacilityOverview
                        className="card-xl-stretch mb-xl-3"
                        data={taskData}
                    />
                </div>
                {/* )} */}

                <div className="col-xl-8">
                    {isLoading || isFetching === true ? (
                        <div className="card card-xl-stretch h-410px d-flex justify-content-center align-items-center">
                            <ClipLoader size={70} speedMultiplier={0.6} />
                        </div>
                    ) : (
                        <CHWStatus
                            className="card-xxl-stretch mb-5 mb-xl-8"
                            tableTitle="CHW STATUS"
                            tableSubTitle="CHW Status Overview"
                            isLoading={isLoading}
                            isFetching={isFetching}
                            dataList={chwData}
                            dataListMsg="No CHW List"
                            dataListResult={totalNumberOfCHW}
                            isError={errorBoolean}
                            errorMsg={dashboardErrorMsg}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
