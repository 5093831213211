import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../../common/utils/genericErrors';
type Props = {
  mutate: Function;
  setApiData: Function;
  setPage: Function;
};

const searchSchema = Yup.object().shape({
  searchName: Yup.string()
    .matches(
      /^\s*PO\d{8}$/,
      "Search must follow the format 'PO' followed by exactly 8 digits"
    )
    .label('Search'),
});

const SearchBox = ({ mutate, setApiData, setPage }: Props) => {
  const facilityCode = localStorage.getItem('facilityCode') as string;
  const successToastMsg = () => {
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          {/* {successMsg} */}
          Purchase order found!
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'success-toast',
      }
    );
  };

  const errorToastMsg = (errorStr: string) => {
    toast.error(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
          {errorStr}
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'error-toast',
      }
    );
  };
  return (
    <div className="card-toolbar">
      <Toaster />
      <div className="py-2 me-20">
        <Formik
          initialValues={{
            searchName: '',
          }}
          validationSchema={searchSchema}
          onSubmit={(values, onSubmitProps) => {
            const payload = {
              facilityCode,
              purchaseOrderId: values.searchName?.trim(),
            };
            mutate(payload, {
              onSuccess: (data: any) => {
                successToastMsg();
                // setPage(1);
                setTimeout(() => {
                  onSubmitProps.resetForm();
                }, 1500);
                setApiData(data);
              },
              onError: (error: any) => {
                errorToastMsg(
                  isErrorMsgNotReadable(error?.response?.data?.message)
                    ? getErrorMessage(error?.response?.status)
                    : error?.response?.data?.message
                );
                onSubmitProps.resetForm();
              },
            });
          }}
        >
          {(formProps: any) => (
            <Form className="w-100 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex ">
                <Field
                  name="searchName"
                  placeholder="Search PO ID"
                  className="form-control form-control-lg form-control-solid"
                />
                <button
                  disabled={
                    formProps.isSubmitting ||
                    !(formProps.isValid && formProps.dirty)
                  }
                  type="submit"
                  className="ms-3 btn btn-lg btn-primary"
                >
                  Search
                </button>
              </div>
              <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                <ErrorMessage name="searchName" />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SearchBox;
