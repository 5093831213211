function Vitals() {
    return (
        <div className="card shadow-sm p-4">
            <div className="card-header bg-white border-bottom-2 pb-3">
                <h2 className="fw-bold text-uppercase text-center">Vitals</h2>
            </div>
            <div className="card-body fs-6">
                <div className="row g-4">
                    <div className="col-md-6">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between">
                                <span className="fw-bold">Height:</span>
                                <span>173 cm</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <span className="fw-bold">Weight:</span>
                                <span>59 kg</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <span className="fw-bold">Temperature:</span>
                                <span className="badge bg-danger">N/A</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <span className="fw-bold">Blood Glucose:</span>
                                <span className="badge bg-danger">N/A</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between">
                                <span className="fw-bold">BP:</span>
                                <span>163/93 mmHg</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <span className="fw-bold">
                                    Oxygen Saturation:
                                </span>
                                <span>96 %</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <span className="fw-bold">Pulse:</span>
                                <span>96 Bpm</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                                <span className="fw-bold">Hemoglobin:</span>
                                <span className="badge bg-danger">N/A</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vitals;
