import { useEffect, useState } from 'react';
import { useNavbar } from '../../../../context/NavbarContext';
import useMutateRequest from '../../../../hooks/getMutation.query';
import useSearchParams from '../../../../hooks/useSearchParams';
import { getDoctorAppointments } from '../../../../services/doctor/features/DoctorAppointmentDetails.hook';

import DoctorAppointments from './table-view/DoctorAppointments';
import { convertToUtcTimestamp } from '../../../../common/utils';

const DoctorAppointmentPage = () => {
  const { selectedFacility } = useNavbar();
  const { dId: id }: any = useSearchParams();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterData, setFilterData]: any = useState({
    fromDate: '',
    toDate: '',
  });

  const facilityCode: any = localStorage.getItem('facilityCode');

  const {
    data,
    isLoading,
    error,
    mutate,
    isError: isPatientConsultationError,
  }: any = useMutateRequest((data: any) =>
    getDoctorAppointments({
      facilityCode,
      page: currentPageNumber,
      limit: pageLimit,
      // fromDate: filterData?.fromDate,
      // toDate: filterData?.toDate,
      fromDate:
        filterData?.fromDate !== ''
          ? convertToUtcTimestamp(filterData?.fromDate)
          : '',
      toDate:
        filterData?.toDate !== ''
          ? convertToUtcTimestamp(filterData?.toDate)
          : '',
      doctorId: id,
    })
  );

  const patientConsultationDetails: any = data?.data?.data?.consultation;
  //   const totalPatientAppointmentCount: any = data?.data?.data?.consultationCount;

  const totalPatientConsultationEntites = data?.data?.results;

  const totalPatientConsultationPages = Math.ceil(
    totalPatientConsultationEntites / pageLimit
  );

  const errorMsg = error?.response?.data?.message?.message;

  //   const successMsg = data?.data?.data?.message;

  const getData = () => {
    try {
      let payload = {
        facilityCode,
        // fromDate: filterData?.fromDate,
        // toDate: filterData?.toDate,
        fromDate:
          filterData?.fromDate !== ''
            ? convertToUtcTimestamp(filterData?.fromDate)
            : '',
        toDate:
          filterData?.toDate !== ''
            ? convertToUtcTimestamp(filterData?.toDate)
            : '',
        doctorId: id,
      };

      mutate(payload, {
        onSuccess: (data: any) => {},
        onError: (error: any) => {
          //   console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, filterData, selectedFacility, id, pageLimit]);

  return (
    <>
      <div className="mt-5">
        <DoctorAppointments
          setCurrentPageNumber={setCurrentPageNumber}
          currentPageNumber={currentPageNumber}
          filterData={filterData}
          setFilterData={setFilterData}
          patientConsultationDetails={patientConsultationDetails}
          errorMsg={errorMsg}
          errorStatus={error?.response?.status}
          isLoading={isLoading}
          totalPatientConsultationPages={totalPatientConsultationPages}
          isPatientConsultationError={isPatientConsultationError}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          totalPatientConsultationEntites={totalPatientConsultationEntites}
          facilityCode={facilityCode}
        />
      </div>
    </>
  );
};

export default DoctorAppointmentPage;
