import * as Yup from 'yup';

const SUPPORTED_FORMATS = ['application/pdf'];

export type contentBuilderTypes = {
  sections: any;
};

export const initalValues: contentBuilderTypes = {
  sections: [
    {
      secId: 0,
      sectionName: '',
      questions: [
        {
          questionName: '',
          questionType: 'Document',
          documentValue: '',
          videoLink: '',
        },
      ],
    },
  ],
};

export const contentBuilderValidationSchema = Yup.object({
  sections: Yup.array().of(
    Yup.object().shape({
      secId: Yup.string().label('Section Id required'),
      sectionName: Yup.string().label('Section Name required'),
      questions: Yup.array().of(
        Yup.object().shape({
          questionName: Yup.string().required(
            'Document/Video name is required'
          ),
          questionType: Yup.string().label('Question Type required'),
          questionRequired: Yup.string().label('Question required'),
          documentValue: Yup.mixed()
            .required('File is required')
            // .nullable()
            // .when("questionType", {
            //   is: "Document",
            //   then: Yup.string().required("Document Value"),
            //   // then: Yup.mixed().label("Document Value"),
            // })
            .test(
              'FILE_FORMAT',
              'Uploaded file has unsupported format.',
              (value) =>
                !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            ),
          videoLink: Yup.string().when('questionType', {
            is: 'Video',
            then: Yup.string().required('Video Link required'),
          }),
        })
      ),
    })
  ),
});
