// A global Info about the app

import { useEffect, useState } from 'react';
import { AdminResource, fmResource } from '../../rbac';
import { doctorResource } from '../../rbac/common.schema';

export const useAppInfo = () => {
    const logged = localStorage.getItem('userRole');

    const resource =
        logged === 'FACILITY_MANAGER'
            ? fmResource
            : logged === 'DOCTOR'
            ? doctorResource
            : AdminResource;
    // User info
    const [loggedInUser, setLoggedInUser] = useState('');

    useEffect(() => {
        setLoggedInUser(resource?.roleId?.roleName);
    }, [resource]);

    // const loggedInUser = useMemo(() => resource?.roleId?.roleName, []);

    return {
        loggedInUser,
    };
};
