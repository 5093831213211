import { useState } from 'react';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { Menu } from '@mui/material';
import InfoCard from '../../../../components/InfoCard';

import InsightsSkeleton from '../../../../components/skeleton/InsightsSkeleton';
import { todayDate } from '../../../../common/utils';
import useSearchParams from '../../../../hooks/useSearchParams';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const DoctorInsights = ({
  filterData,
  setFilterData,
  doctorInsights,
  fetchingDoctorInsights,
  loadingDoctorInsights,
  allFacilites,
}: any) => {
  const cardList = [
    {
      title: 'TOTAL CONSULTATION',
      totalCount: doctorInsights?.totalConsultation
        ? doctorInsights?.totalConsultation
        : '0',
      iconType: '/media/ikure/doctor.svg',
    },
    {
      title: 'TOTAL ONLINE CONSULTATION',
      totalCount: doctorInsights?.totalOnlineConsultation
        ? doctorInsights?.totalOnlineConsultation
        : '0',
      iconType: '/media/ikure/doctor.svg',
    },
    {
      title: 'TOTAL PHYSICAL CONSULTATION',
      totalCount: doctorInsights?.totalPhysicalConsultation
        ? doctorInsights?.totalPhysicalConsultation
        : '0',
      iconType: '/media/ikure/chw.svg',
    },
    {
      title: 'PENDING CONSULTATION',
      totalCount: doctorInsights?.totalPendingConsultation
        ? doctorInsights?.totalPendingConsultation
        : '0',
      iconType: '/media/ikure/chw.svg',
    },
    {
      title: 'CANCELLED CONSULTATION',
      totalCount: doctorInsights?.totalCancelledConsultation
        ? doctorInsights?.totalCancelledConsultation
        : '0',
      iconType: '/media/ikure/chw.svg',
    },
  ];
  // const { id }: any = useParams();
  const { dId: id }: any = useSearchParams();

  // Begins Menu Here
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onFilterSubmit = (values: any, onSubmitProps: any) => {
    try {
      const payload = {
        facilityCode: values.facilityCode,
        fromDate: values.fromDate,
        toDate: values.toDate,
        doctorId: id,
      };
      setFilterData(payload);
      handleClose();
      onSubmitProps.resetForm();
    } catch (error) {
      console.log(error);
      handleClose();
      onSubmitProps.resetForm();
    }
  };
  const clearData = () => {
    setFilterData({ fromDate: '', toDate: '', facilityCode: '' });
  };
  return (
    <div className="p-10 card d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between h-auto">
        <div className="">
          <span className="fs-1 text-dark fw-bolder ">DOCTOR INSIGHTS</span>
          <i
            onClick={() => clearData()}
            className="fas fa-redo fs-3 cursor-pointer ms-5"
          ></i>
        </div>
        <div className="d-flex align-items-center">
          <button onClick={handleClick} className="btn btn-sm btn-primary">
            <i className="fas fa-filter"></i>
            FILTER
          </button>
          {/* Filter Options */}
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Formik
                initialValues={filterData}
                onSubmit={onFilterSubmit}
                enableReinitialize
              >
                {(formProps: any) => (
                  <Form>
                    <div className="py-5 px-8">
                      <div className="w-250px mt-5">
                        <label className="form-label fs-4 fw-bolder">
                          FROM DATE
                        </label>
                        <Field
                          id="fromDate"
                          as="input"
                          type="date"
                          name="fromDate"
                          max={todayDate}
                          className="form-control cursor-pointer"
                        />
                        <div className="text-danger mt-5">
                          <ErrorMessage name="fromDate" />
                        </div>
                      </div>
                      <div className="w-250px mt-5 mb-5">
                        <label className="form-label fs-4 fw-bolder">
                          TO DATE
                        </label>
                        <Field
                          id="toDate"
                          type="date"
                          name="toDate"
                          max={todayDate}
                          className="form-control"
                        />
                        <div className="text-danger mt-5">
                          <ErrorMessage name="toDate" />
                        </div>
                      </div>
                      <div className="w-250px mt-5 ">
                        <label className="form-label fs-4 fw-bolder">
                          FACILITY NAME
                        </label>
                        <Field
                          id="facilityCode"
                          as="select"
                          name="facilityCode"
                          className="form-select form-select-light form-select-lg required"
                        >
                          <option value="" selected>
                            All
                          </option>
                          {allFacilites
                            ?.filter((facilities: any) => {
                              return facilities?.status !== false;
                            })
                            .map((facility: any) => {
                              const facilityName =
                                facility?.facilityFirstName +
                                ' ' +
                                facility?.facilityLastName;
                              return (
                                <>
                                  <option value={facility?.facilityCode}>
                                    {facilityName}
                                  </option>
                                </>
                              );
                            })}
                        </Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="visitNumber" />
                        </div>
                      </div>
                    </div>

                    <div className="my-5 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        disabled={!formProps.isValid || formProps.isSubmitting}
                        className="w-250px btn btn-sm btn-primary"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Menu>
          </div>
          {/* End Here */}
        </div>
      </div>
      <div className="row gy-5 gx-xl-8 mt-5">
        {cardList.map((i) => {
          return fetchingDoctorInsights || loadingDoctorInsights === true ? (
            <div className="col-xl-4">
              <InsightsSkeleton />
            </div>
          ) : (
            <div className="col-xl-4">
              <InfoCard
                className="card-xl-stretch mb-xl-3 mb-5"
                cardSize={{ height: '200px' }}
                iconType={toAbsoluteUrl(i.iconType)}
                title={i.title}
                totalCount={i.totalCount}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DoctorInsights;
