import { useEffect, useRef, useState } from 'react';
import { Field, ErrorMessage, FormikValues } from 'formik';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const Step2 = ({ values, setFieldValue, setFieldTouched }: FormikValues) => {
    const fileRef = useRef<HTMLInputElement>(null);
    const [showEye, setShowEye] = useState<boolean>(false);
    const [image, setImage] = useState<File | null>();
    const [preview, setPreview] = useState<string | null | undefined>();

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(image);
        } else {
            setPreview(null);
        }
    }, [image]);

    useEffect(() => {
        if (values.photo) {
            setImage(values.photo);
        }
    }, [values.photo]);
    const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === 'image') {
            await setFieldValue('photo', file);
            await setFieldTouched('photo'); // Trigger validation immediately
            setImage(file);
        } else {
            setFieldValue('photo', null);
            setImage(null);
        }
    };
    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Profile Settings</h2>
            </div>

            {/* Picture */}
            <div className="row mb-10  ">
                <div className="">
                    <label className="form-label required">Picture</label>
                </div>
                <div className="cursor-pointer d-flex col-lg-12">
                    <div className="col-lg-6">
                        {preview ? (
                            <img
                                src={preview}
                                alt="img-preview"
                                width="150px"
                                height="150px"
                                style={{ objectFit: 'cover' }}
                                data-testid="img-preview"
                            />
                        ) : (
                            <img
                                src={toAbsoluteUrl('/media/ikure/blank.png')}
                                alt="preview"
                                width="150px"
                                height="150px"
                                style={{ objectFit: 'cover' }}
                            />
                        )}
                    </div>
                    <div className="d-flex col-lg-6 justify-content-between align-items-center">
                        <input
                            data-testid="photo"
                            ref={fileRef}
                            hidden
                            type="file"
                            className="form-control form-control-lg  form-control-solid"
                            accept="image/*"
                            onChange={handleFileChange}
                            // {(event: any) => {
                            //     const file = event?.target?.files[0];
                            //     if (
                            //         file &&
                            //         file.type.substr(0, 5) === "image"
                            //     ) {
                            //         setFieldValue(
                            //             "photo",
                            //             event?.target?.files[0]
                            //         );
                            //         validateField("photo");
                            //         setImage(file);
                            //     } else {
                            //         setImage(null);
                            //     }
                            // }}
                        />
                        <button
                            className="btn btn-sm"
                            onClick={(event) => {
                                event.preventDefault();
                                fileRef?.current?.click();
                            }}
                            data-testid="upload-btn"
                        >
                            <i className="fs-3x fas fa-cloud-upload-alt"></i>
                        </button>
                    </div>
                </div>

                <div className="text-danger mt-2">
                    <ErrorMessage name="photo" />
                </div>
            </div>

            {/* Password */}
            <div className="fv-row col-lg-12 mb-10">
                <label className="form-label required disabled">Password</label>
                <div className="d-flex align-items-center justify-content-between position-relative">
                    <Field
                        data-testid="password"
                        type={showEye === true ? 'text' : 'password'}
                        name="password"
                        className="form-control disabled form-control-lg form-control-solid"
                    />
                    <span onClick={() => setShowEye(!showEye)}>
                        {showEye ? (
                            <div className="position-absolute translate-middle-y top-50 end-0 me-5 cursor-pointer">
                                <i className="fs-2 fas fa-eye mt-0"></i>
                            </div>
                        ) : (
                            <div className="position-absolute translate-middle-y top-50 end-0 me-5 cursor-pointer">
                                <i className="fs-2 fas fa-eye-slash mt-0 "></i>
                            </div>
                        )}
                    </span>
                </div>
                <div className="text-danger mt-2">
                    <ErrorMessage name="password" />
                </div>
            </div>

            {/* Role */}
            <div className="fv-row col-lg-12 mb-0">
                <label className="form-label required">Grade</label>
                <Field
                    data-testid="grade"
                    as="select"
                    name="grade"
                    className="form-select form-select-lg form-select-solid"
                    value={values.grade}
                >
                    <option value="">Select the CHW Grade</option>
                    <option value="FCHW - Frontline CHW">Frontline CHW</option>
                    <option value="CHW Senior">CHW Senior</option>
                    <option value="CHW Junior">CHW Junior</option>
                    <option value="Community Health Mobilizer">
                        Community Health Mobilizer
                    </option>
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="grade" />
                </div>
            </div>
        </div>
    );
};

export { Step2 };
