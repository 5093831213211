import { useEffect, useMemo, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import toast, { Toaster } from 'react-hot-toast';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
  courseLandingPageValidationSchema,
  initalValues,
} from './CourseLandingPageModel';
import {
  createWebContent,
  getAllCoursesCategories,
  getAllCoursesSubcategory,
} from '../../../services/web-content/WebContent.hook';
import CustomSelect from '../../../components/CustomSelect';
import useMutateRequest from '../../../hooks/getMutation.query';
import useGetRequest from '../../../hooks/getRequest.query';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../common/utils/genericErrors';

const chwGradeOptions = [
  { label: 'Frontline CHW', value: 'FCHW - Frontline CHW' },
  { label: 'CHW Senior', value: 'CHW Senior' },
  { label: 'CHW Junior', value: 'CHW Junior' },
  { label: 'Community Health Mobilizer', value: 'Community Health Mobilizer' },
];
const CreateContentPage = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');

  // Get List of all Course Category
  const { data: coursesCategoryList }: any = useGetRequest(
    'getAllCoursesCategories',
    async () => await getAllCoursesCategories(),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );

  // Get List of all Course Subcategory
  const { data: coursesSubcategoryList }: any = useGetRequest(
    ['getAllCoursesSubcategory', selectedCategoryId],
    async () =>
      await getAllCoursesSubcategory({
        categoryId: selectedCategoryId,
      }),
    {
      enabled: selectedCategoryId?.length > 0,
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );

  const coursesCategories = coursesCategoryList?.data?.data?.category;
  const coursesSubcategories = coursesSubcategoryList?.data?.data?.subCategory;

  const fileRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>();
  const [preview, setPreview] = useState<string | null | undefined>();

  const [spinner, setSpinner] = useState<boolean>(false);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  const { isLoading, isError, error, mutate, isSuccess }: any =
    useMutateRequest((data: any) => createWebContent(data));
  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error?.response?.data?.message]
  );
  const navigate = useNavigate();

  const successToastMsg = () => {
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          {/* {successMsg} */}
          Successfully Created Course !!
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'success-toast',
      }
    );
  };

  const errorToastMsg = () => {
    toast.error(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
          {errorMsg}

          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'error-toast',
      }
    );
  };

  const onSubmit = (values: any) => {
    try {
      let formData = new FormData();
      formData.append('courseTitle', values.courseTitle);
      formData.append('courseDescription', values.courseDescription);
      formData.append('courseDuration', values.courseDuration);
      formData.append('coursePrimaryFocus', values.coursePrimaryFocus);
      formData.append('courseLanguage', values.courseLanguage);
      formData.append('chwGrade', values.chwGrade);
      formData.append('courseCategory', values.courseCategory);
      formData.append('courseSubcategory', values.courseSubcategory);
      formData.append('courseImage', values.courseImage);

      mutate(formData, {
        onSuccess(data: any) {
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
            navigate('/training/course-management');
          }, 3000);
        },
        onError(error: any) {
          setSpinner(true);
          setTimeout(() => {
            setSpinner(false);
            navigate('/training/course-management');
          }, 3000);
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    isSuccess && successToastMsg();
  }, [isSuccess]);

  useEffect(() => {
    isError && errorToastMsg();
  }, [isError]);

  return (
    <>
      <Toaster />
      <div className="card h-100 p-5">
        <div className="d-flex justify-content-center">
          <h1 className="fs-1 fw-bolder m-0">Create Course</h1>
        </div>
        <hr className="h-2px" />
        <div className="mt-5 ms-5 text-right w-100">
          <p className="fs-5 fw-bold">
            The following descriptions will be visible on your course landing
            page
          </p>
        </div>
        {isLoading || spinner === true ? (
          <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
            <ClipLoader size={70} speedMultiplier={0.6} />
          </div>
        ) : (
          <div className="">
            <Formik
              initialValues={initalValues}
              validationSchema={courseLandingPageValidationSchema}
              onSubmit={onSubmit}
            >
              {(formProps) => {
                return (
                  <Form onKeyDown={handleInputKeyDown}>
                    <div className="p-5">
                      {/* Course Title */}
                      <div className="fv-row mb-10">
                        <label className="fw-bolder form-label required">
                          Course Title
                        </label>

                        <Field
                          name="courseTitle"
                          placeholder="Basics of Nutrition"
                          className="form-control form-control-lg form-control-solid"
                        />
                        <div className="text-danger mt-2">
                          <ErrorMessage name="courseTitle" />
                        </div>
                      </div>

                      {/* Course Description */}
                      <div className="fv-row mb-10">
                        <label className="fw-bolder form-label required">
                          Course Description
                        </label>

                        <Field
                          as="textarea"
                          rows={3}
                          name="courseDescription"
                          className="form-control form-control-lg form-control-solid"
                        />
                        <div className="text-danger mt-2">
                          <ErrorMessage name="courseDescription" />
                        </div>
                      </div>

                      {/* Course Duration */}
                      <div className="fv-row mb-10">
                        <label className="fw-bolder form-label required">
                          Course Duration
                        </label>

                        <Field
                          name="courseDuration"
                          placeholder="e.g. 2 hours"
                          className="form-control form-control-lg form-control-solid"
                        />
                        <div className="text-danger mt-2">
                          <ErrorMessage name="courseDuration" />
                        </div>
                      </div>

                      {/* Primary Focus */}
                      <div className="fv-row mb-10">
                        <label className="fw-bolder form-label required">
                          What is primarily thought in this course ?
                        </label>

                        <Field
                          name="coursePrimaryFocus"
                          placeholder="e.g. General Health"
                          className="form-control form-control-lg form-control-solid"
                        />
                        <div className="text-danger mt-2">
                          <ErrorMessage name="coursePrimaryFocus" />
                        </div>
                      </div>

                      <div className="row fv-row">
                        {/* Course Language */}
                        <div className="fv-row col-lg-6 mb-10">
                          <label className="fw-bolder form-label required">
                            Course Language
                          </label>
                          <Field
                            as="select"
                            name="courseLanguage"
                            className="form-select form-select-lg form-select-solid"
                          >
                            <option value="">Select Language</option>
                            <option value="English">English</option>
                            <option value="Bengali">Bengali</option>
                            <option value="Hindi">Hindi</option>
                          </Field>
                          <div className="text-danger mt-2">
                            <ErrorMessage name="courseLanguage" />
                          </div>
                        </div>

                        {/* CHW Grade */}
                        <div className="fv-row col-lg-6 mb-0">
                          <label className="fw-bolder form-label required">
                            CHW Grade
                          </label>
                          <Field
                            className="form-select-solid"
                            name="chwGrade"
                            options={chwGradeOptions}
                            component={CustomSelect}
                            placeholder="Select the CHW Grade ..."
                            isMulti={true}
                          />

                          <div className="text-danger mt-2">
                            <ErrorMessage name="chwGrade" />
                          </div>
                        </div>
                      </div>

                      <div className="row fv-row">
                        {/* CHW Category */}
                        <div className="fv-row col-lg-6 mb-8">
                          <label className="fw-bolder form-label required">
                            Course Category
                          </label>
                          <Field
                            as="select"
                            name="courseCategory"
                            className="form-select form-select-lg form-select-solid"
                          >
                            <option value="">Select Category</option>
                            {coursesCategories?.map((coursesCategory: any) => {
                              setSelectedCategoryId(
                                formProps?.values?.courseCategory
                              );
                              return (
                                <>
                                  <option value={coursesCategory?._id}>
                                    {coursesCategory?.categoryName}
                                  </option>
                                </>
                              );
                            })}
                          </Field>
                          <div className="text-danger mt-2">
                            <ErrorMessage name="courseCategory" />
                          </div>
                        </div>

                        {/* Sub Category */}
                        <div className="flex col-lg-6 mb-8">
                          <label className="fw-bolder form-label required">
                            Sub Category
                          </label>
                          <Field
                            as="select"
                            name="courseSubcategory"
                            className="form-select form-select-lg form-select-solid"
                          >
                            <option value="">Select Sub Category</option>
                            {coursesSubcategories?.map(
                              (coursesSubcategory: any) => {
                                return (
                                  <>
                                    <option value={coursesSubcategory?._id}>
                                      {coursesSubcategory?.subCategoryName}
                                    </option>
                                  </>
                                );
                              }
                            )}
                          </Field>
                          <div className="text-danger mt-2">
                            <ErrorMessage name="courseSubcategory" />
                          </div>
                        </div>
                      </div>
                      {/* Course Image */}
                      <div className="row mb-10  ">
                        <>
                          <label className="fw-bolder form-label required">
                            Course Image
                          </label>
                        </>
                        <div className="mt-5 cursor-pointer d-flex col-lg-12">
                          <div className="col-lg-6">
                            {preview ? (
                              <img
                                src={preview}
                                alt="preview"
                                width="250px"
                                height="250px"
                                style={{ objectFit: 'cover' }}
                              />
                            ) : (
                              <img
                                src={toAbsoluteUrl(
                                  '/media/ikure/content-builder/course.svg'
                                )}
                                alt="preview"
                                width="250px"
                                height="200px"
                                style={{ objectFit: 'cover' }}
                              />
                            )}
                          </div>
                          <div className="d-flex col-lg-6 justify-content-between align-items-center">
                            <input
                              ref={fileRef}
                              hidden
                              type="file"
                              className="form-control form-control-lg  form-control-solid"
                              accept="image/*"
                              onChange={(event: any) => {
                                const file = event?.target?.files[0];
                                if (
                                  file &&
                                  file.type.substr(0, 5) === 'image'
                                ) {
                                  formProps?.setFieldValue(
                                    'courseImage',
                                    event?.target?.files[0]
                                  );
                                  setImage(file);
                                } else {
                                  setImage(null);
                                }
                              }}
                            />
                            <button
                              className="btn btn-md w-150px btn-primary"
                              onClick={(event) => {
                                event.preventDefault();
                                fileRef?.current?.click();
                              }}
                            >
                              <i className="fs-1x fas fa-cloud-upload-alt"></i>{' '}
                              Upload File
                            </button>
                          </div>
                        </div>

                        <div className="text-danger mt-5">
                          {formProps?.errors && formProps?.errors?.courseImage}
                        </div>
                      </div>

                      <div className="mt-5 d-flex align-items-center justify-content-center">
                        <button
                          disabled={!formProps?.isValid || !formProps?.dirty}
                          type="submit"
                          className="btn btn-md btn-primary w-250px"
                        >
                          Create Course
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateContentPage;
