import React, { useEffect, useMemo, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import PrescriptionAndLabTest from './PrescriptionAndLabTest';
import CustomTooltip from '../../../../../components/CustomTooltip';
import { useNavbar } from '../../../../../context/NavbarContext';
import useMutateRequest from '../../../../../hooks/getMutation.query';
import useSearchParams from '../../../../../hooks/useSearchParams';
import { getPatientHealthConditions } from '../../../../../services/patient/PatientHealthConditions.hook';
import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../../../../components/Table';
import CollapsibleTableIcon from '../../../../../components/IconAnimations/CollapsibleTableIcon';
import TableErrorComponent from '../../../../../components/Error/TableError';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../../../common/utils/genericErrors';

const ConditonsTable = () => {
  const { selectedFacility } = useNavbar();
  const { pId: id }: any = useSearchParams();
  const [clicked, setClicked]: any = useState('0');

  const handleToggle = (index: any) => {
    if (clicked === index) {
      return setClicked('0');
    }
    setClicked(index);
  };

  // API to fetch patient health condtions
  const { isLoading, isError, data, error, mutate }: any = useMutateRequest(
    (data: any) => getPatientHealthConditions(data)
  );

  let errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error]
  );

  const getData = () => {
    try {
      let payload = {
        uhid: id,
      };
      mutate(payload, {
        onSuccess: (data: any) => {},
        onError: (error: any) => {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) getData();
  }, [id, selectedFacility]);

  let patientHealthConditions = data?.data?.data?.conditionDetails;

  return (
    <div className="card d-flex flex-column">
      <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
        <div className="my-2 d-flex align-items-center justify-content-center h-50px">
          <h1>DISEASE PROFILE / HEALTH CONDITION</h1>
        </div>
      </div>
      {isError ? (
        <TableErrorComponent
          errorMessage={errorMsg || 'No data found for Health Condition'}
        />
      ) : (
        <Table>
          <TableHeadRow>
            <TableHead width={50} align="center">
              -
            </TableHead>
            <TableHead width={50} align="center">
              SL No
            </TableHead>
            <TableHead width={50}>CONDITION NAME</TableHead>
            <TableHead width={50}>SYMPTOMS</TableHead>
            <TableHead width={50}>DATE</TableHead>
            <TableHead width={50}>STATUS</TableHead>
          </TableHeadRow>
          {isLoading ? (
            <tbody
              style={{
                height: '400px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                left: '500px',
                top: '50px',
              }}
            >
              <ClipLoader size={70} speedMultiplier={0.6} />
            </tbody>
          ) : isError === true ? (
            <tbody
              style={{
                height: '400px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                left: '400px',
                top: '5px',
              }}
            >
              <h1 className="text-danger">
                {isError === true ? errorMsg : 'Something went wrong!!'}
              </h1>
            </tbody>
          ) : Array?.isArray(patientHealthConditions) ? (
            <TableBodyContainer isLoading={isLoading}>
              {patientHealthConditions?.map((data: any, index: any) => {
                let firstSymptoms = data?.symptoms[0]?.symptomName;
                let allSymptoms = data?.symptoms?.map((symptom: any) => {
                  return symptom?.symptomName;
                });
                const dateFormat = new Date(data?.createdAt);

                return (
                  <React.Fragment key={index}>
                    <TableRow
                      collapsible
                      show={clicked === index}
                      onClick={() => handleToggle(index)}
                      collapsibleBody={
                        <>
                          {clicked === index && (
                            <div className="card p-6 d-flex flex-column justify-content-center ms-5 w-100">
                              <PrescriptionAndLabTest data={data} />
                            </div>
                          )}
                        </>
                      }
                    >
                      {/* <tr onClick={() => handleToggle(index)}> */}
                      <TableCell hover align="center">
                        <CollapsibleTableIcon isClicked={clicked === index} />
                      </TableCell>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>
                        {data?.conditionName ? data?.conditionName : 'N/A'}
                      </TableCell>
                      <TableCell>
                        <CustomTooltip
                          sx={{ textTransform: 'capitalize' }}
                          title={allSymptoms?.join(', ')}
                          placement="top"
                        >
                          <span>{`${firstSymptoms} ...`}</span>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell>{dateFormat?.toDateString()}</TableCell>
                      <TableCell>
                        <span
                          className={`badge badge-light-${
                            data?.status === 'Cured' ? 'success' : 'danger'
                          } text-center `}
                        >
                          {data?.status ? data?.status : 'N/A'}
                        </span>
                      </TableCell>
                      {/* </tr> */}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBodyContainer>
          ) : (
            ''
          )}
        </Table>
      )}
    </div>
  );
};

export default ConditonsTable;
