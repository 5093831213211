import { useEffect, useMemo, useState } from 'react';
import { useNavbar } from '../../../context/NavbarContext';
import useGetRequest from '../../../hooks/getRequest.query';
import { getFacilityList } from '../../../services/facility/Facility.hook';

import FacilityCounterPage from './FacilityCounterPage';
import FacilityTable from './FacilityTable';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../common/utils/genericErrors';

const FacilityPage = () => {
  const { selectedFacility } = useNavbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterData, setFilterData]: any = useState({
    grade: '',
    access: '',
    fieldstatus: '',
  });

  const facilityCode = localStorage.getItem('facilityCode');

  const { data, isLoading, isFetching, refetch, isError, error }: any =
    useGetRequest(
      ['getAllFacilites-facility-main', pageNumber, pageLimit],
      async () =>
        await getFacilityList({
          page: pageNumber,
          limit: pageLimit,
        }),
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    refetch();
  }, [filterData, facilityCode, selectedFacility]);

  const totalEntites = useMemo(() => data?.data?.results, [data]);
  const facilityData = useMemo(() => data?.data?.data?.facility, [data]);
  const totalAvailablePages = useMemo(
    () => Math.ceil(totalEntites / pageLimit),
    [totalEntites, pageLimit]
  );
  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error]
  );

  return (
    <>
      <div>
        <FacilityCounterPage
          isLoading={isLoading}
          isFetching={isFetching}
          counterData={totalEntites}
        />
      </div>
      <div className="mt-5">
        <FacilityTable
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          currentPageNumber={pageNumber}
          setCurrentPageNumber={setPageNumber}
          totalEntites={totalEntites}
          totalAvailablePages={totalAvailablePages}
          getFacilityLoader={isLoading}
          getFacilityFetcher={isFetching}
          isFacilityError={isError}
          facilityErrorMsg={errorMsg}
          bodyProps={facilityData}
          setFilterData={setFilterData}
          refetch={refetch}
        />
      </div>
    </>
  );
};

export default FacilityPage;
