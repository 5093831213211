import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../../components/Table";
import { convertUtcTimestampToLocalDate } from "../../../../../common/utils";

const GRNDetailsTable = ({ data }: any) => {
    // console.log("data", data);
    return (
        <div className="">
            <h1>PRODUCTS LIST</h1>
            <Table nested>
                <TableHeadRow>
                    <TableHead width={100} align="center">
                        SL No
                    </TableHead>
                    <TableHead width={250}>PRODUCT NAME</TableHead>
                    <TableHead width={200}>BATCH</TableHead>
                    <TableHead width={250}>EXPIRY</TableHead>
                    <TableHead width={200}>UNIT/STRIP</TableHead>
                    <TableHead width={200}>NO OF STRIP</TableHead>
                    <TableHead width={200}>FREE STRIP</TableHead>
                    <TableHead width={200}>MRP/STRIP</TableHead>
                    <TableHead width={200}>TOTAL UNITS</TableHead>
                    <TableHead width={200}>PRICE UNIT</TableHead>
                    <TableHead width={200}>MRP/UNIT</TableHead>
                </TableHeadRow>

                {data?.length === 0 ? (
                    <tbody>
                        <tr>
                            <td colSpan={3} className="text-center">
                                <span className="text-center fw-bolder text-danger">
                                    No data
                                </span>
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    <TableBodyContainer isLoading={false}>
                        {data &&
                            data.map((grn: any, index: any) => {
                                return (
                                    <TableRow collapsible={false}>
                                        <TableCell align="center">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell>
                                            {console.log(
                                                grn?.productId?.productName
                                            )}
                                            {grn?.productDetails?.productName
                                                ? grn.productDetails.productName
                                                : grn?.productId?.productName ??
                                                  "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {grn?.batch ? grn?.batch : "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {grn?.expiry
                                                ? convertUtcTimestampToLocalDate(
                                                      grn?.expiry
                                                  )
                                                : "N/A"}
                                        </TableCell>

                                        <TableCell>
                                            {grn?.unitsPerStrip
                                                ? grn?.unitsPerStrip
                                                : 0}
                                        </TableCell>
                                        <TableCell>
                                            {grn?.totalQuantity
                                                ? grn?.totalQuantity
                                                : 0}
                                        </TableCell>
                                        <TableCell>
                                            {grn?.freeQuantity
                                                ? grn?.freeQuantity
                                                : 0}
                                        </TableCell>
                                        <TableCell>
                                            {grn?.ratePerStrip
                                                ? (grn?.ratePerStrip).toFixed(2)
                                                : 0}
                                        </TableCell>
                                        <TableCell>
                                            {grn?.unitsPerStrip *
                                                grn?.totalQuantity}
                                        </TableCell>
                                        <TableCell>
                                            {grn?.pricePerUnit
                                                ? grn?.pricePerUnit
                                                : 0}
                                        </TableCell>
                                        <TableCell>
                                            {grn?.ratePerUnit
                                                ? grn?.ratePerUnit
                                                : 0}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBodyContainer>
                )}
            </Table>
        </div>
    );
};

export default GRNDetailsTable;
