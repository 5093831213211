import * as Yup from 'yup';
import { Menu } from '@mui/material';
import { useState } from 'react';
import { ErrorMessage, Field, Formik, Form } from 'formik';

import useMutateRequest from '../../../hooks/getMutation.query';
import { searchCHWByName } from '../../../services/CHWManagement.hook';
import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../../components/Table';
import Pagination from '../../../components/Pagination';

const filterSchema = Yup.object().shape({
  grade: Yup.string().label('Grade'),
  fieldstatus: Yup.string().label('Field Status'),
  access: Yup.string().label('Access'),
});

const FacilityTable = ({
  bodyProps,
  getFacilityLoader,
  getFacilityFetcher,
  isFacilityError,
  facilityErrorMsg,
  currentPageNumber,
  setCurrentPageNumber,
  totalEntites,
  totalAvailablePages,
  setFilterData,
  pageLimit,
  setPageLimit,
}: any) => {
  // Setting the Search term
  const [searchData, setSearchData]: any = useState([]);
  const [searchFormProps, setSearchFormProps] = useState('');

  // API For Search CHW
  const {
    data,
    // mutate,
    isError: isSearchError,
    error: ErrorMsg,
    reset,
  }: any = useMutateRequest((data: string) => searchCHWByName(data));

  const totalSearchEntites = data?.data?.results;
  const totalSearchCHWPages = Math.ceil(totalSearchEntites / pageLimit);
  const totalPages =
    searchData?.length > 0 ? totalSearchCHWPages : totalAvailablePages;

  // Begins Menu Here
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFilterSubmit = (values: any, onSubmitProps: any) => {
    try {
      const payload = {
        grade: values.grade,
        fieldstatus: values.fieldstatus,
        access: values.access,
      };
      setFilterData(payload);
      onSubmitProps.resetForm();
    } catch (error) {
      onSubmitProps.resetForm();
    }
  };

  const clearData = (searchFormProps: any) => {
    if (searchFormProps) {
      searchFormProps.resetForm();
      setSearchData([]);
      reset();
    }
    const payload = {
      grade: '',
      fieldstatus: '',
      access: '',
    };
    setFilterData(payload);
  };

  let allFacilities: any;
  if (searchData?.length > 0) {
    allFacilities = searchData;
  } else {
    allFacilities = bodyProps;
  }
  const tableNames = ['NAME', 'FACILITY CODE', 'PHONE NO', 'EMAIL ID'];
  return (
    <div className="card text-dark">
      <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
        <div className="d-flex align-items-center justify-content-center h-50px">
          <h1>FACILITY LIST</h1>
          <i
            onClick={() => clearData(searchFormProps)}
            className="fas fa-redo fs-3 cursor-pointer ms-5"
          ></i>
        </div>
        <div className="card-toolbar">
          {/* Filter Options */}
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Formik
                initialValues={{
                  grade: '',
                  fieldstatus: '',
                  access: '',
                }}
                validationSchema={filterSchema}
                onSubmit={onFilterSubmit}
              >
                {(formProps: any) => (
                  <Form>
                    <div className="py-5 px-8">
                      <div className="w-250px mt-5 mb-8">
                        <label className="form-label fs-4 fw-bolder">
                          Grade
                        </label>
                        <Field
                          as="select"
                          name="grade"
                          className="form-select form-select-lg form-select-solid"
                        >
                          <option value="">ALL</option>
                          <option value="FCHW - Frontline CHW">
                            Frontline CHW
                          </option>
                          <option value="CHW Senior">CHW Senior</option>
                          <option value="CHW Junior">CHW Junior</option>
                          <option value="Community Health Mobilizer">
                            Community Health Mobilizer
                          </option>
                        </Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="grade" />
                        </div>
                      </div>

                      <div className="w-250px mt-5 mb-8">
                        <label className="form-label fs-4 fw-bolder">
                          Status
                        </label>
                        <Field
                          id="fieldstatus"
                          as="select"
                          name="fieldstatus"
                          className="form-select form-select-light form-select-lg"
                        >
                          <option value="">ALL</option>
                          <option value="OnField">ON FIELD</option>
                          <option value="OffField">OFF FIELD</option>
                        </Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="fieldstatus" />
                        </div>
                      </div>

                      <div className="w-250px mt-5">
                        <label className="form-label fs-4 fw-bolder">
                          Access
                        </label>
                        <Field
                          id="access"
                          as="select"
                          name="access"
                          className="form-select form-select-light form-select-lg"
                        >
                          <option value="">ALL</option>
                          <option value="true">ACTIVATED</option>
                          <option value="false">DEACTIVATED</option>
                        </Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="access" />
                        </div>
                      </div>
                    </div>

                    <div className="my-5 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        disabled={!formProps.isValid || formProps.isSubmitting}
                        className="w-250px btn btn-sm btn-primary"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Menu>
          </div>
          {/* End Here */}
        </div>
      </div>

      <div className="card-body py-3">
        <Table>
          <TableHeadRow>
            <TableHead width={140} align="center">
              SL NO
            </TableHead>
            {tableNames?.map((name) => {
              return (
                <TableHead width={180} key={name} align="left">
                  {name}
                </TableHead>
              );
            })}

            <TableHead width={140} align="center">
              TOTAL NO OF CHWS
            </TableHead>
          </TableHeadRow>

          <TableBodyContainer
            isLoading={getFacilityLoader}
            isError={isSearchError || isFacilityError}
            errorMessage={facilityErrorMsg}
          >
            {allFacilities?.map((item: any, index: any) => {
              const facilityCode = item?.facilityCode;
              const facilityName =
                item?.facilityFirstName + ' ' + item?.facilityLastName;
              return (
                <TableRow collapsible={false} key={index}>
                  <TableCell align="center">
                    {currentPageNumber &&
                      (currentPageNumber - 1) * pageLimit + index + 1}
                  </TableCell>

                  <TableCell>{facilityName}</TableCell>
                  <TableCell>{facilityCode}</TableCell>
                  <TableCell>{item?.telecom}</TableCell>
                  <TableCell>{item?.email}</TableCell>
                  <TableCell align="center">
                    {item?.chw?.length ? item?.chw?.length : 0}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBodyContainer>
        </Table>

        {/* Pagination */}
        <Pagination
          dataCount={totalEntites}
          handleCallback={(val) => setPageLimit(val)}
          handleNext={() =>
            setCurrentPageNumber((prevPageNumber: number) => prevPageNumber + 1)
          }
          handlePrev={() =>
            setCurrentPageNumber((prevPageNumber: number) => prevPageNumber - 1)
          }
          nextDisabled={currentPageNumber === totalPages}
          prevDisabled={currentPageNumber === 1}
          pageLimit={pageLimit}
          pageNumber={currentPageNumber}
        />
      </div>
    </div>
  );
};

export default FacilityTable;
