import * as Yup from 'yup';
import { Menu } from '@mui/material';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import TaskListExport from '../components/TaskListExport';
import CustomTooltip from '../../../components/CustomTooltip';
import useGetRequest from '../../../hooks/getRequest.query';
import useSearchParams from '../../../hooks/useSearchParams';
import { getCHWTasks } from '../../../services/CHWManagement.hook';
import Pagination from '../../../components/Pagination';
import TableErrorComponent from '../../../components/Error/TableError';
import { Permissions, hasAccessToSubModule } from '../../../rbac';
import {
  convertToUtcTimestamp,
  convertUtcTimestampToLocalDate,
  todayDate,
} from '../../../common/utils';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../common/utils/genericErrors';

const filterSchema = Yup.object().shape({
  taskStatus: Yup.string().label('Task Status'),
  fromDate: Yup.string().label('From Date'),
  toDate: Yup.string().label('To Date'),
});

const TaskListTable = ({ chwName }: any) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isExportOpen, setIsExportOpen] = useState<boolean>(false);

  const [pageLimit, setPageLimit] = useState(10);
  const { chwId: id }: any = useSearchParams();
  const [filterData, setFilterData]: any = useState({
    taskStatus: '',
    fromDate: '',
    toDate: '',
  });
  const [customFilterData, setCustomFilterData] = useState({
    taskStatus: '',
    fromDate: '',
    toDate: '',
  });
  const handleExportModal = () => {
    setIsExportOpen(!isExportOpen);
  };
  const fd = localStorage.getItem('facilityCode');

  const queryParams = {
    facilityCode: fd,
    chwId: id,
  };

  const {
    data,
    isLoading: isTaskLoading,
    // isFetching: isTaskFetching,
    refetch,
    isError: isTaskError,
    error: ErrorMsg,
  }: any = useGetRequest(
    ['getCHWTasks', currentPageNumber, id, pageLimit],
    () =>
      getCHWTasks({
        ...queryParams,
        page: currentPageNumber,
        limit: pageLimit,
        taskStatus: filterData?.taskStatus,
        fromDate: filterData?.fromDate,
        toDate: filterData?.toDate,
      }),
    {
      retry: false,
      // staleTime: 100000,
      refetchOnWindowFocus: false,
      enabled: !!id,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (id) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, filterData]);

  const chwTaskData = data?.data?.data?.tasks;
  const totalCHWTaskEntites = data?.data?.results;
  const totalCHWTaskPages = Math.ceil(totalCHWTaskEntites / pageLimit);

  const taskErrorMsg = ErrorMsg?.response?.data?.message;

  // Begins Menu Here
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFilterSubmit = (values: any, onSubmitProps: any) => {
    try {
      const payload = {
        taskStatus: values.taskStatus,
        fromDate: convertToUtcTimestamp(values.fromDate),
        toDate: convertToUtcTimestamp(values.toDate),
      };
      const customPayload = {
        taskStatus: values.taskStatus,
        fromDate: values.fromDate,
        toDate: values.toDate,
      };
      setCurrentPageNumber(1);
      setFilterData(payload);
      setCustomFilterData(customPayload);
      handleClose();
    } catch (error) {
      handleClose();
      onSubmitProps.resetForm();
    }
  };

  const clearData = () => {
    const payload = {
      taskStatus: '',
      fromDate: '',
      toDate: '',
    };
    setCurrentPageNumber(1);
    setFilterData(payload);
    setCustomFilterData(payload);
  };

  const canExport = hasAccessToSubModule(
    'LIST_CHW',
    'TASK_LIST',
    Permissions.export_button
  );

  return (
    <div className="card text-dark">
      <Modal
        open={isExportOpen}
        overlayStyles={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <TaskListExport
          onClose={handleExportModal}
          styles={{
            backgroundColor: 'white',
            width: '650px',
            height: '620px',
            padding: '40px',
          }}
          chwName={chwName}
        />
      </Modal>
      <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
        <div className="d-flex align-items-center justify-content-center h-50px">
          <h1>TASK LIST</h1>
          <i
            onClick={() => clearData()}
            className="fas fa-redo fs-3 cursor-pointer ms-5"
          ></i>
        </div>

        <div className="card-toolbar">
          <button
            hidden={!canExport}
            onClick={handleExportModal}
            className="btn btn-sm btn-light-primary me-5"
          >
            <i className="fas fa-download"></i>
            EXPORT
          </button>

          <button onClick={handleClick} className="btn btn-sm btn-primary">
            <i className="fas fa-filter"></i>
            FILTER
          </button>

          {/* Filter Options */}
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Formik
                initialValues={customFilterData}
                validationSchema={filterSchema}
                onSubmit={onFilterSubmit}
                enableReinitialize
              >
                {(formProps: any) => (
                  <Form>
                    <div className="py-5 px-8">
                      <div className="w-250px mt-5 mb-8">
                        <label className="form-label fs-4 fw-bolder">
                          TASK STATUS
                        </label>
                        <Field
                          as="select"
                          name="taskStatus"
                          className="form-select form-select-lg form-select-solid"
                        >
                          <option value="">All</option>
                          <option value="To Do">Todo</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Cancelled">Cancelled</option>
                          <option value="Done">Done</option>
                        </Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="taskStatus" />
                        </div>
                      </div>
                      <div className="w-250px mt-5 mb-5">
                        <label className="form-label fs-4 fw-bolder">
                          FROM DATE
                        </label>
                        <Field
                          id="fromDate"
                          as="input"
                          type="date"
                          name="fromDate"
                          max={todayDate}
                          className="form-control cursor-pointer"
                        />
                        <div className="text-danger mt-5">
                          <ErrorMessage name="fromDate" />
                        </div>
                      </div>
                      <div className="w-250px mt-5">
                        <label className="form-label fs-4 fw-bolder">
                          TO DATE
                        </label>
                        <Field
                          id="toDate"
                          type="date"
                          name="toDate"
                          max={todayDate}
                          className="form-control"
                        />
                        <div className="text-danger mt-5">
                          <ErrorMessage name="toDate" />
                        </div>
                      </div>
                    </div>

                    <div className="my-5 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        disabled={!formProps.isValid || formProps.isSubmitting}
                        className="w-250px btn btn-sm btn-primary"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Menu>
          </div>
          {/* End Here */}
        </div>
      </div>
      {isTaskError ? (
        <TableErrorComponent
          errorMessage={
            isErrorMsgNotReadable(taskErrorMsg)
              ? getErrorMessage(ErrorMsg?.response?.status)
              : taskErrorMsg
          }
        />
      ) : (
        <div className="card-body py-3">
          <Table>
            <TableHeadRow>
              <TableHead width={140} align="center">
                SL NO
              </TableHead>
              <TableHead width={140}>PATIENT NAME</TableHead>
              <TableHead width={120}>ACTIVITY NAME</TableHead>
              <TableHead width={120}>TASK TYPE</TableHead>
              <TableHead width={120}>TASK STATUS</TableHead>
              <TableHead width={120} align="center">
                NO ACTIVITIES ASSIGNED
              </TableHead>
              <TableHead width={120}>TASK DATE</TableHead>
              <TableHead width={100}>ACTION</TableHead>
            </TableHeadRow>

            <TableBodyContainer
              isLoading={isTaskLoading}
              isError={isTaskError}
              errorMessage={taskErrorMsg}
            >
              {chwTaskData?.map((item: any, index: any) => {
                let allVitalNames = item?.activity
                  ?.map((activityName: any) => {
                    return activityName?.activityDetails;
                  })
                  ?.flat();

                let vitalNames = allVitalNames?.map((allVitalName: any) => {
                  return `${allVitalName?.vitalName}`;
                });

                let firstVitalNames = allVitalNames?.map(
                  (allVitalName: any) => {
                    return `${allVitalName?.vitalName} ...`;
                  }
                )[0];

                let firstVitalName = allVitalNames?.map((allVitalName: any) => {
                  return `${allVitalName?.vitalName}`;
                })[0];

                return (
                  <TableRow collapsible={false} key={index}>
                    <TableCell align="center">
                      {currentPageNumber &&
                        (currentPageNumber - 1) * pageLimit + index + 1}
                    </TableCell>
                    <TableCell hover>{item?.patient?.name}</TableCell>
                    <TableCell hover>
                      <CustomTooltip
                        title={vitalNames?.join(', ')}
                        placement="top"
                      >
                        <span className="border-none text-capitalize">
                          {allVitalNames?.length < 2
                            ? firstVitalName
                            : firstVitalNames}
                        </span>
                      </CustomTooltip>
                    </TableCell>
                    <TableCell hover>
                      <span
                        className={`badge badge-light-${
                          item?.taskType === 'Doorstep'
                            ? 'success'
                            : item?.taskType === 'Camp'
                            ? 'danger'
                            : item?.taskType === 'Community'
                            ? 'info'
                            : item?.taskType === 'Telehealth'
                            ? 'primary'
                            : item?.taskType === 'Clinic'
                            ? 'info'
                            : null
                        } text-center `}
                      >
                        {item?.taskType}
                      </span>
                    </TableCell>
                    <TableCell hover>
                      <span
                        className={`badge badge-light-${
                          item?.taskStatus === 'Done'
                            ? 'success'
                            : item?.taskStatus === 'Cancelled'
                            ? 'danger'
                            : item?.taskStatus === 'In Progress'
                            ? 'info'
                            : item?.taskStatus === 'To Do'
                            ? 'primary'
                            : null
                        } text-center `}
                      >
                        {item?.taskStatus}
                      </span>
                    </TableCell>
                    <TableCell hover align="center">
                      {item?.activity.length}
                    </TableCell>
                    <TableCell hover>
                      {/* {item?.taskDate
                                                ? dateFormatter(item?.taskDate)
                                                : "N/A"} */}
                      {item?.taskDate
                        ? convertUtcTimestampToLocalDate(item?.taskDate)
                        : 'N/A'}
                    </TableCell>

                    <TableCell>
                      <Link
                        state={{
                          activity: item?.activity,
                          patient: item?.patient,
                          chwId: item?.chwId,
                          taskStatus: item?.taskStatus,
                        }}
                        to={{
                          pathname: `/task/task-details`,
                          search: `?taskId=${item?._id}`,
                        }}
                        className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                      >
                        DETAILS
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBodyContainer>
          </Table>

          <Pagination
            dataCount={totalCHWTaskEntites}
            handleCallback={(val) => setPageLimit(+val)}
            handleNext={() =>
              setCurrentPageNumber(
                (prevPageNumber: number) => prevPageNumber + 1
              )
            }
            handlePrev={() =>
              setCurrentPageNumber(
                (prevPageNumber: number) => prevPageNumber - 1
              )
            }
            nextDisabled={currentPageNumber === totalCHWTaskPages}
            prevDisabled={currentPageNumber === 1}
            pageLimit={pageLimit}
            pageNumber={currentPageNumber}
          />
          {/* end::Table */}
        </div>
      )}
    </div>
  );
};

export { TaskListTable };
