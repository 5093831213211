import { useEffect, useMemo, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import toast, { Toaster } from 'react-hot-toast';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import {
  subcategoryValidationSchema,
  initalValues,
} from '../models/SubcategoryModel';
import useMutateRequest from '../../../../hooks/getMutation.query';
import useGetRequest from '../../../../hooks/getRequest.query';
import {
  getAllCoursesCategories,
  createCourseSubcategory,
} from '../../../../services/web-content/WebContent.hook';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../../common/utils/genericErrors';

const SubcategoryPage = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');

  // Get List of all Course Category
  const {
    data: coursesCategoryList,
    refetch: refetchCoursesCategory,
    isSuccess: responseSuccess,
  }: any = useGetRequest(
    'getAllCoursesCategories',
    async () => await getAllCoursesCategories(),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );
  const run = selectedCategoryId?.length > 0;
  // console.log(run);

  useEffect(() => {
    refetchCoursesCategory();
  }, [selectedCategoryId]);

  const coursesCategories = Array.from(
    new Set(
      coursesCategoryList?.data?.data?.category.map(
        (item: any) => item.categoryName
      )
    )
  ).map((name) =>
    coursesCategoryList?.data?.data?.category.find(
      (item: any) => item.categoryName === name
    )
  );

  // Toast
  const [open, setOpen] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [msg, setMsg] = useState<boolean>(false);

  const { isLoading, isError, data, error, mutate, isSuccess }: any =
    useMutateRequest((data: any) => createCourseSubcategory(data));

  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error?.response?.data?.message]
  );

  const successToastMsg = () => {
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          {/* {successMsg} */}
          Successfully Created Course Subcategory!!
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'success-toast',
      }
    );
  };

  const errorToastMsg = () => {
    toast.error(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
          {errorMsg}
          {/* Failed to Create Focused Group !! */}
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'error-toast',
      }
    );
  };

  const onSubmit = (values: any) => {
    // console.log(values);
    try {
      // Remove double quotes
      const payload = {
        categoryId: selectedCategoryId,
        subCategoryName: values.subcategoryName,
      };
      mutate(payload, {
        onSuccess(data: any) {
          console.log(data);
          setSpinner(true);
          setOpen(true);
          setMsg(true);

          setTimeout(() => {
            setSpinner(false);
            // redirectTo.push("/training/content-management");
          }, 3000);
        },
        onError(error: any) {
          setSpinner(true);
          setOpen(true);
          setMsg(false);

          setTimeout(() => {
            setSpinner(false);
            // redirectTo.push("/training/content-management");
          }, 3000);
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster />
      {isSuccess && open === true && successToastMsg()}
      {isError && open === true && errorToastMsg()}
      <div className="">
        <div className="d-flex justify-content-center">
          <h1 className="fs-1 fw-bolder m-0">Create Subcategory to a Course</h1>
        </div>
        <hr className="h-2px" />
        {/* <div className="mt-5 ms-5 text-right w-100">
          <p className="fs-5 fw-bold">
            The following descriptions will visible on your Course Landing Page
          </p>
        </div> */}
        {isLoading || spinner === true ? (
          <div className="card w-100 h-250px d-flex flex-column align-items-center justify-content-center p-15">
            <ClipLoader size={70} speedMultiplier={0.6} />
          </div>
        ) : (
          <div className="">
            <Formik
              initialValues={initalValues}
              validationSchema={subcategoryValidationSchema}
              onSubmit={onSubmit}
            >
              {(formProps) => {
                return (
                  <Form>
                    <div className="p-5">
                      {/* CHW Category */}
                      <div className="fv-row col-lg-12 mb-7">
                        <label className="fw-bolder form-label required">
                          Course Category
                        </label>
                        <Field
                          as="select"
                          name="categoryName"
                          className="form-select form-select-lg form-select-solid"
                        >
                          <option value="">Select Category</option>
                          {coursesCategories?.map((coursesCategory: any) => {
                            setSelectedCategoryId(
                              formProps?.values?.categoryName
                            );
                            return (
                              <>
                                <option value={coursesCategory?._id}>
                                  {coursesCategory?.categoryName}
                                </option>
                              </>
                            );
                          })}
                        </Field>
                        <div className="text-danger mt-2">
                          <ErrorMessage name="courseCategory" />
                        </div>
                      </div>

                      {/* Course Subcategory */}
                      <div className="fv-row col-lg-12 mb-10">
                        <label className="fw-bolder form-label required">
                          Course Subcategory
                        </label>

                        <Field
                          name="subcategoryName"
                          placeholder="ex: Patient Onboarding"
                          className="form-control form-control-lg form-control-solid"
                        />
                        <div className="text-danger mt-2">
                          <ErrorMessage name="subcategoryName" />
                        </div>
                      </div>

                      <div className="mt-5 d-flex align-items-center justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-md btn-primary w-250px"
                        >
                          Create Course Subcategory
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default SubcategoryPage;
