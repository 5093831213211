import { Form, Formik } from 'formik';
import { useEffect, useMemo } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import toast, { Toaster } from 'react-hot-toast';

import {
  initalValues,
  contentBuilderValidationSchema,
} from '../../model/ContentBuilderModel';
import MultiSection from './MultiSection';
import useMutateRequest from '../../../../../hooks/getMutation.query';
import useSearchParams from '../../../../../hooks/useSearchParams';
import { addContent } from '../../../../../services/web-content/WebContent.hook';

const PublishCourse = ({ courseContentRefetch }: any) => {
  const { courseId: id }: any = useSearchParams();

  // API Call
  const { mutate, isError, isSuccess, error, isLoading }: any =
    useMutateRequest((data: string) => addContent(data));

  const errorMsg = useMemo(() => error?.response?.data?.message, [error]);

  const onSubmit = (values: any) => {
    try {
      const payload: any = new FormData();
      payload.append('status', true);
      payload.append('courseId', id);
      var sec: any = values.sections.map((section: any, sectionIndex: any) => {
        payload.append(`sections[${sectionIndex}][secId]`, id);
        payload.append(
          `sections[${sectionIndex}][sectionName]`,
          section.sectionName
        );

        // Lessons
        var less: any = section.questions.map(
          (question: any, questionIndex: any) => {
            const value = question.questionType === 'Document' ? 1 : 0;
            payload.append(
              `sections[${sectionIndex}][lessons][${questionIndex}][lessonName]`,
              question.questionName
            );
            payload.append(
              `sections[${sectionIndex}][lessons][${questionIndex}][lessonNumber]`,
              questionIndex + 1
            );
            payload.append(
              `sections[${sectionIndex}][lessons][${questionIndex}][lessonType]`,
              value
            );
            payload.append(
              `sections[${sectionIndex}][lessons][${questionIndex}][documentValue]`,
              question.documentValue
            );
            payload.append(
              `sections[${sectionIndex}][lessons][${questionIndex}][videoLink]`,
              question.videoLink
            );
            payload.append(
              `sections[${sectionIndex}][lessons][${questionIndex}][status]`,
              true
            );
            console.log(less);

            return less;
          }
        );
        return sec;
      });
      if (id) {
        mutate(payload, {
          onSuccess: (data: any) => {
            courseContentRefetch();
            setTimeout(() => {
              // redirectTo.push("/training/content-management");
            }, 3000);
          },
          onError: (error: any) => {
            console.log(error);
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const successToastMsg = () => {
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          {/* {successMsg} */}
          Successfully added Course Content!!
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'success-toast',
      }
    );
  };

  const errorToastMsg = () => {
    toast.error(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
          {errorMsg}
          {/* Failed to added Course Content!! */}
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'error-toast',
      }
    );
  };

  useEffect(() => {
    isSuccess && successToastMsg();
  }, [isSuccess]);

  useEffect(() => {
    isError && errorToastMsg();
  }, [isError]);

  return (
    <div>
      <Toaster />
      {isLoading ? (
        <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
          <ClipLoader size={70} speedMultiplier={0.6} />
        </div>
      ) : (
        <Formik
          initialValues={initalValues}
          validationSchema={contentBuilderValidationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(formikProps) => (
            <Form>
              <>
                <MultiSection />
              </>
              <div className="mt-5 d-flex justify-content-center">
                <button
                  disabled={!formikProps?.isValid || !formikProps?.dirty}
                  type="submit"
                  className="w-250px btn btn-lg btn-primary me-3"
                >
                  Publish
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default PublishCourse;
