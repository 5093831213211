import { useEffect, useState } from 'react';
import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import AttendanceListExport from '../components/AttendanceListExport';
import CustomTooltip from '../../../components/CustomTooltip';
import { prevMonth } from '../../../common/utils/datesUtils';
import useGetRequest from '../../../hooks/getRequest.query';
import useSearchParams from '../../../hooks/useSearchParams';
import { getCHWAttendanceList } from '../../../services/CHWManagement.hook';
import Pagination from '../../../components/Pagination';
import TableErrorComponent from '../../../components/Error/TableError';
import { Permissions, hasAccessToSubModule } from '../../../rbac';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../common/utils/genericErrors';

const AttendanceListTable = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  // const { id }: any = useParams();
  const { chwId: id }: any = useSearchParams();
  const currentMonthName = prevMonth && prevMonth[0]?.month;
  const [insightPeriod, setInsightPeriod] = useState(currentMonthName);

  // Popup
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExportOpen, setIsExportOpen] = useState<boolean>(false);
  const [userPhoto, setUserPhoto] = useState<string>('');
  const facilityCode = localStorage.getItem('facilityCode');

  const handleModal = (photo: any) => {
    setUserPhoto(photo);
    setIsOpen(!isOpen);
  };
  const handleExportModal = () => {
    setIsExportOpen(!isExportOpen);
  };

  const {
    data,
    isLoading,
    // isFetching,
    refetch,
    isError,
    error: ErrorMsg,
  }: // reset,
  any = useGetRequest(
    ['getCHWAttendanceList', currentPageNumber, id],
    () =>
      getCHWAttendanceList({
        facilityCode,
        page: currentPageNumber,
        limit: pageLimit,
        month: insightPeriod,
        chwId: id,
      }),
    {
      retry: false,
      // staleTime: 100000,
      refetchOnWindowFocus: false,
      enabled: !!id,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, insightPeriod]);

  const chwAttendanceData = data?.data?.data?.chwfield;
  const totalCHWAttendanceEntites = data?.data?.results;
  const totalCHWAttendancePages = Math.ceil(
    totalCHWAttendanceEntites / pageLimit
  );

  const attendanceErrorMsg = ErrorMsg?.response?.data?.message;

  const clearData = () => {
    setInsightPeriod(currentMonthName);
  };
  const canExport = hasAccessToSubModule(
    'LIST_CHW',
    'LIST_ATTENDANCE',
    Permissions.export_button
  );
  return (
    <div className="card text-dark">
      <Modal
        open={isExportOpen}
        overlayStyles={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <AttendanceListExport
          onClose={handleExportModal}
          styles={{
            backgroundColor: 'white',
            width: '650px',
            height: '570px',
            msgHeight: '200px',
            padding: '40px',
          }}
        />
      </Modal>
      <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
        <div className="d-flex align-items-center justify-content-center h-50px">
          <h1>ATTENDANCE LIST</h1>
          <i
            onClick={() => clearData()}
            className="fas fa-redo fs-3 cursor-pointer ms-5"
          ></i>
        </div>
        <div className="card-toolbar">
          <button
            hidden={!canExport}
            onClick={handleExportModal}
            className="btn btn-sm btn-light-primary me-5"
          >
            <i className="fas fa-download"></i>
            EXPORT
          </button>

          <div className="ms-5">
            <select
              onChange={(e) => setInsightPeriod(e.target.value)}
              value={insightPeriod}
              className="cursor-pointer form-select form-select-light form-select-lg"
            >
              {prevMonth.map((item: any) => {
                return (
                  <>
                    <option value={item?.month}>{item?.monthAndYear}</option>
                  </>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      {isError ? (
        <TableErrorComponent
          errorMessage={
            isErrorMsgNotReadable(attendanceErrorMsg)
              ? getErrorMessage(ErrorMsg?.response?.status)
              : attendanceErrorMsg
          }
        />
      ) : (
        <div className="card-body py-3">
          <Table>
            <TableHeadRow>
              <TableHead width={140} align="center">
                SL NO
              </TableHead>
              <TableHead width={140} align="center">
                DATE
              </TableHead>
              <TableHead width={140} align="center">
                PHOTO
              </TableHead>
              <TableHead width={120} align="center">
                PUNCH IN TIME
              </TableHead>
              <TableHead width={120} align="center">
                PUNCH OUT TIME
              </TableHead>
              <TableHead width={120} align="center">
                LOCATION
              </TableHead>
              <TableHead width={120} align="center">
                MAP VIEW
              </TableHead>
            </TableHeadRow>

            <Modal
              styles={{
                width: '350px',
                height: '400px',
                backgroundColor: '#fff',
                borderRadius: '5px',
              }}
              open={isOpen}
              overlayStyles={{
                backgroundColor: 'rgba(0, 0, 0, 0.043)',
              }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div>
                  <img
                    src={userPhoto}
                    style={{
                      padding: 0,
                      width: '300px',
                      objectFit: 'fill',
                      height: '280px',
                      borderRadius: '5px',
                    }}
                    alt="proof"
                  />
                </div>
                <div className="mt-5" onClick={handleModal}>
                  <button className="btn btn-sm btn-danger">Close</button>
                </div>
              </div>
            </Modal>

            <TableBodyContainer
              isLoading={isLoading}
              isError={isError}
              errorMessage={attendanceErrorMsg}
            >
              {Array?.isArray(chwAttendanceData) ? (
                chwAttendanceData?.map((item: any, index: any) => {
                  const chwLocation =
                    item?.street +
                    ', ' +
                    item?.locality +
                    ', ' +
                    item?.district +
                    ', ' +
                    item?.state;
                  return (
                    <TableRow collapsible={false}>
                      <TableCell hover align="center">
                        {currentPageNumber &&
                          (currentPageNumber - 1) * pageLimit + index + 1}
                      </TableCell>
                      <TableCell hover align="center">
                        {item?.createdDate ? item?.createdDate : 'N/A'}
                      </TableCell>
                      <TableCell hover align="center">
                        <i
                          onClick={() => handleModal(item?.photo)}
                          className="fs-2 fas fa-solid fa-image text-hover-primary cursor-pointer"
                        ></i>
                      </TableCell>
                      <TableCell hover align="center">
                        {item?.startTime ? item?.startTime : 'N/A'}
                      </TableCell>
                      <TableCell hover align="center">
                        {item?.endTime ? item?.endTime : 'N/A'}
                      </TableCell>
                      <TableCell hover align="center">
                        <CustomTooltip title={chwLocation} placement="top">
                          <i className="fs-2 fas fa-solid fa-location-arrow"></i>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell hover align="center">
                        <a
                          href={`https://www.google.com/maps/place/${item?.latitude},${item?.longitude}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fs-2 fas fa-map-marked-alt text-hover-primary cursor-pointer"></i>
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <tbody
                  style={{
                    height: '400px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: '500px',
                    top: '200px',
                  }}
                >
                  <h1 className="text-danger text-nowrap">
                    {chwAttendanceData}
                  </h1>
                </tbody>
              )}
            </TableBodyContainer>
          </Table>

          {/* Pagination */}

          <Pagination
            dataCount={totalCHWAttendanceEntites}
            handleCallback={(val) => setPageLimit(+val)}
            handleNext={() =>
              setCurrentPageNumber(
                (prevPageNumber: number) => prevPageNumber + 1
              )
            }
            handlePrev={() =>
              setCurrentPageNumber(
                (prevPageNumber: number) => prevPageNumber - 1
              )
            }
            nextDisabled={currentPageNumber === totalCHWAttendancePages}
            prevDisabled={currentPageNumber === 1}
            pageLimit={pageLimit}
            pageNumber={currentPageNumber}
          />
          {/* end::Table */}
        </div>
      )}
      {/* end::Table container */}
    </div>
  );
};

export { AttendanceListTable };
