import { useEffect, useState } from "react";
import { useNavbar } from "../../context/NavbarContext";
import useGetRequest from "../../hooks/getRequest.query";
import { getAllCHW } from "../../services/CHWManagement.hook";

import CHWCounterPage from "./CHWCounterPage";
import { CHWTable } from "./table/CHWTable";

const CHWPage = () => {
    const { selectedFacility } = useNavbar();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [filterData, setFilterData]: any = useState({
        grade: "",
        access: "",
        fieldstatus: "",
    });

    const updatedPageNumber = Object?.values(filterData)?.some(
        (value) => value !== ""
    );

    useEffect(() => {
        if (updatedPageNumber) {
            setPageNumber(1);
        }
    }, [updatedPageNumber]);

    const fd = localStorage.getItem("facilityCode");

    const chwQueryParams = {
        facilityCode: fd,
    };

    const {
        data,
        isLoading,
        isFetching,
        refetch,
        isError,
        error: ErrorMsg,
    }: any = useGetRequest(
        ["allCHWData", pageNumber, pageLimit],
        async () =>
            await getAllCHW({
                ...chwQueryParams,
                page: pageNumber,
                limit: pageLimit,
                grade: filterData?.grade,
                access: filterData?.access,
                fieldstatus: filterData?.fieldstatus,
            }),
        {
            // retry: false,
            // staleTime: 100000,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    useEffect(() => {
        refetch();
    }, [filterData, fd, selectedFacility]);

    const chwData = data?.data?.data?.chw;
    const chwCounterData = data?.data?.data?.chwCounters;
    const totalAvailablePages = data?.data?.results; // Math.ceil(totalCHWEntites / pageLimit);
    const chwErrorMsg = ErrorMsg?.response?.data?.message;

    return (
        <>
            <div>
                <CHWCounterPage
                    totalCount={isError ? 0 : totalAvailablePages}
                    filterData={filterData}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    chwCounterData={chwCounterData}
                />
            </div>
            <div className="mt-5">
                <CHWTable
                    filterData={filterData}
                    pageLimit={pageLimit}
                    currentPageNumber={pageNumber}
                    handlePageNumber={setPageNumber}
                    totalGetCHWPages={totalAvailablePages}
                    getCHWLoader={isLoading}
                    getCHWFetcher={isFetching}
                    isCHWError={isError}
                    chwErrorMsg={chwErrorMsg}
                    bodyProps={chwData}
                    setFilterData={setFilterData}
                    refetch={refetch}
                    chwCounterData={chwCounterData}
                    errorStatus={ErrorMsg?.response?.status}
                    setPageLimit={setPageLimit}
                />
            </div>
        </>
    );
};

export default CHWPage;
