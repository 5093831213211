import { Toaster } from 'react-hot-toast';
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from '../../../components/Table';
import Pagination from '../../../components/Pagination';
import { convertUtcTimestampToLocalDate } from '../../../common/utils';
import TableErrorComponent from '../../../components/Error/TableError';

const IkureConnectTable = ({
    bodyProps,
    isLoading,
    isFetching,
    isIkureConnectError,
    errorMsg,
    currentPageNumber,
    setCurrentPageNumber,
    totalIkureConnectPages,
    totalIkureConnectEntites,
    pageLimit,
    setPageLimit,
    refetch,
}: any) => {
    const clearData = () => {
        setCurrentPageNumber(1);
        refetch();
    };

    const tableNames = [
        'PATIENT NAME',
        'FACILITY',
        'CHW NAME',
        'CHANNEL NAME',
        'REMARK',
        'DATE',
        'STATUS',
    ];
    return (
        <div className="card text-dark">
            <Toaster />
            <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
                <div className="d-flex align-items-center justify-content-center h-50px">
                    <h1>IKURE CONNECT</h1>
                    <i
                        onClick={clearData}
                        className="fas fa-redo fs-3 cursor-pointer ms-5"
                    ></i>
                </div>
            </div>
            {isIkureConnectError || bodyProps?.length === 0 ? (
                <TableErrorComponent
                    errorMessage={
                        bodyProps?.length === 0 ? errorMsg : 'No data available'
                    }
                />
            ) : (
                <div className="card-body py-3">
                    <Table>
                        <TableHeadRow>
                            <TableHead width={140} align="left">
                                SL NO
                            </TableHead>

                            {tableNames?.map((name) => {
                                return (
                                    <TableHead
                                        width={180}
                                        key={name}
                                        align="left"
                                    >
                                        {name}
                                    </TableHead>
                                );
                            })}
                        </TableHeadRow>
                        <TableBodyContainer
                            isLoading={isLoading || isFetching}
                            isError={
                                isIkureConnectError || bodyProps?.length === 0
                            }
                        >
                            {bodyProps?.map((item: any, index: any) => {
                                return (
                                    <TableRow collapsible={false} key={index}>
                                        <TableCell>
                                            <div className="ps-2">
                                                {(currentPageNumber - 1) *
                                                    pageLimit +
                                                    index +
                                                    1}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {item?.patient?.name}
                                        </TableCell>
                                        <TableCell>
                                            {item?.facilityDetails &&
                                            item?.facilityDetails?.status
                                                ? `${item?.facilityDetails?.facilityFirstName} ${item?.facilityDetails?.facilityLastName}`
                                                : `--`}
                                        </TableCell>
                                        <TableCell>{item?.chwName}</TableCell>

                                        <TableCell>
                                            {item?.requestChannel}
                                        </TableCell>
                                        <TableCell>{item?.remark}</TableCell>
                                        <TableCell>
                                            {item?.taskDate
                                                ? convertUtcTimestampToLocalDate(
                                                      item?.createdAt
                                                  )
                                                : 'N/A'}
                                        </TableCell>

                                        <TableCell>
                                            <span
                                                className={`badge badge-light-${
                                                    item?.taskStatus === 'Done'
                                                        ? 'success'
                                                        : item?.taskStatus ===
                                                          'Cancelled'
                                                        ? 'danger'
                                                        : item?.taskStatus ===
                                                          'In Progress'
                                                        ? 'info'
                                                        : item?.taskStatus ===
                                                          'To Do'
                                                        ? 'primary'
                                                        : null
                                                } text-center `}
                                            >
                                                {item?.taskStatus}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBodyContainer>
                    </Table>
                    {/* Pagination */}
                    <Pagination
                        dataCount={totalIkureConnectEntites}
                        handleCallback={(val) => setPageLimit(+val)}
                        handleNext={() =>
                            setCurrentPageNumber(
                                (prevPageNumber: number) => prevPageNumber + 1
                            )
                        }
                        handlePrev={() =>
                            setCurrentPageNumber(
                                (prevPageNumber: number) => prevPageNumber - 1
                            )
                        }
                        nextDisabled={
                            currentPageNumber === totalIkureConnectPages
                        }
                        prevDisabled={currentPageNumber === 1}
                        pageLimit={pageLimit}
                        pageNumber={currentPageNumber}
                    />
                </div>
            )}
        </div>
    );
};

export { IkureConnectTable };
