import React from 'react';
import { IFallbackErrorTypes } from '../types/CommonErrors.types';
import { toAbsoluteUrl } from '../_metronic/helpers';
import { useNavbar } from '../context/NavbarContext';

const ErrorBoundaryFallBack = ({ error }: IFallbackErrorTypes) => {
    const { userRole } = useNavbar();
    return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <img
                src={toAbsoluteUrl('/media/illustrations/sigma-1/9.png')}
                className="w-auto h-500px"
                alt="fallback"
            />
            <div className="text-center p-5">
                <h1 className="fs-1 text-dark fw-bolder">
                    Something went wrong !!
                </h1>
                <h4 style={{ color: 'red' }} className="mt-5 flex-wrap w-90">
                    {process.env.REACT_APP_ENV === 'prod'
                        ? 'Sorry, for now we are facing some issue in this page'
                        : error.message}
                </h4>
            </div>

            <button
                className="btn btn-lg btn-primary"
                onClick={() =>
                    (window.location.href =
                        userRole === 'DOCTOR'
                            ? '/patient/patient-appoinment'
                            : '/dashboard')
                }
            >
                Back to Home
            </button>
        </div>
    );
};

export default ErrorBoundaryFallBack;
