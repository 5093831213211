import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

type Props = {
    setSearch: Function;
    setPage: Function;
};

const searchSchema = Yup.object().shape({
    searchName: Yup.string()
        .matches(
            /^\s*S0\d{7}$/,
            "Search must start with 'S0' followed by exactly 7 digits"
        )
        .label("Search"),
});

const SearchBoxSo = ({ setSearch, setPage }: Props) => {
    return (
        <div className="card-toolbar">
            {/* <Toaster /> */}
            <div className="py-2 me-20">
                <Formik
                    initialValues={{
                        searchName: "",
                    }}
                    validationSchema={searchSchema}
                    onSubmit={(values, onSubmitProps) => {
                        setPage(1);
                        setSearch(values.searchName?.trim());
                        onSubmitProps.resetForm();
                    }}
                >
                    {(formProps: any) => (
                        <Form className="w-100 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex ">
                                <Field
                                    name="searchName"
                                    placeholder="Search Order ID"
                                    className="form-control form-control-lg form-control-solid"
                                    data-testid="searchBar"
                                />
                                <button
                                    disabled={
                                        formProps.isSubmitting ||
                                        !(formProps.isValid && formProps.dirty)
                                    }
                                    type="submit"
                                    className="ms-3 btn btn-lg btn-primary"
                                    data-testid="search-btn"
                                >
                                    Search
                                </button>
                            </div>
                            <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                                <ErrorMessage name="searchName" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SearchBoxSo;
