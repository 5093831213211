function BookingDetails() {
    return (
        <div className="card shadow-sm p-4" data-testid="booking-id">
            <div className="card-header bg-white border-bottom-2 pb-3">
                <h2 className="fw-bold text-uppercase text-center">
                    Booking Details
                </h2>
            </div>
            <div className="card-body fs-6">
                <div className="row g-4">
                    <div className="col-md-6">
                        <p className="fw-bold">
                            Booking ID:
                            <span className="fw-normal text-dark ms-3">
                                6abgfv12
                            </span>
                        </p>
                    </div>
                    <div className="col-md-6">
                        <p className="fw-bold">
                            Type:
                            <span className="fw-normal text-dark ms-3">
                                Online consultation
                            </span>
                        </p>
                    </div>
                    <div className="col-md-6">
                        <p className="fw-bold">
                            Date:
                            <span className="fw-normal text-dark ms-3">
                                20 November 2024
                            </span>
                        </p>
                    </div>
                    <div className="col-md-6">
                        <p className="fw-bold">
                            Fees:
                            <span className="fw-normal text-dark ms-3">
                                Rs 500
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookingDetails;
