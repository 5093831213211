import * as Yup from "yup";
import {
    phoneValidator,
    stringValidator,
    numberValidator,
    alphaNumericValidator,
} from "./UserInputs";

/**
 * User Schema
 * @author 'Rakshith'
 */

const baseSchema = Yup.object({
    firstName: Yup.string()
        .matches(stringValidator, "Please enter valid first name")
        .required()
        .label("First Name"),
    middleName: Yup.string()
        .matches(stringValidator, "Please enter valid middle name")
        .label("Middle Name"),
    lastName: Yup.string()
        .matches(stringValidator, "Please enter valid last name")
        .required()
        .label("Last Name"),
    phoneNumber: Yup.string()
        .matches(
            phoneValidator,
            "Phone number is not valid, must be only digits"
        )
        .min(10, "Must be exactly 10 digits")
        .max(10, "Must be exactly 10 digits")
        // .oneOf([Yup.ref("userName"), null], "User Name don't match!")
        .required("Phone Number is Required!"),
    email: Yup.string()
        .email("Please enter a vaild email")
        .trim()
        .required()
        .label("Email Id"),
});

export const addressSchema = Yup.object({
    floorNumber: Yup.string()
        .matches(alphaNumericValidator, "Floor Number is not valid")
        .label("Floor Number"),
    streetName: Yup.string()
        .matches(alphaNumericValidator, "Street Name is not valid")
        .required()
        .label("Street Name"),
    streetNumber: Yup.string()
        .matches(alphaNumericValidator, "Street Number is not valid")
        .label("Street Number"),
    district: Yup.string()
        .matches(alphaNumericValidator, "Please enter valid district name")
        .required()
        .label("District"),
    postalCode: Yup.string()
        .matches(
            numberValidator,
            "Postal code is not valid, must be only digits"
        )
        .min(6, "Must be exactly 6 digits")
        .max(6, "Must be exactly 6 digits")
        .required()
        .label("Postal Code"),
    town: Yup.string()
        .matches(stringValidator, "Please enter valid town name")
        .required()
        .label("Town"),
    state: Yup.string()
        .matches(stringValidator, "Please enter valid state name")
        .required()
        .label("State"),
    country: Yup.string()
        .matches(stringValidator, "Please enter valid country name")
        .required()
        .label("Country"),
});

export const userSchema = baseSchema;

// const extendedSchema = baseSchema.shape({
//     shipping: Yup.string().required("Required"),
// });
