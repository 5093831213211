import { useCallback, useEffect, useState } from "react";
import { Formik, Form, FormikValues } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";
import { Step4 } from "./steps/Step4";
import { Step5 } from "./steps/Step5";
import { Step6 } from "./steps/Step6";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
    createAccountSchemas,
    initsForCHWOnboarding,
} from "../model/CHWOnboarding";
import useMutateRequest from "../../../hooks/getMutation.query";
import { onboardCHW } from "../../../services/CHWOnboarding.hook";

import Stepper from "../../../components/Stepper";
import { hasAccessToModule } from "../../../rbac";
import { Permissions } from "../../../rbac/types";
import { convertToUtcTimestamp } from "../../../common/utils";
import { getErrorMessage } from "../../../common/utils/genericErrors";

const CHWOnboarding = () => {
    const { isError, error, mutate, isSuccess, isLoading }: any =
        useMutateRequest((data: any) => onboardCHW(data));
    const errorMsg = error?.response?.data?.message;
    const navigate = useNavigate();
    const organizationId: any = localStorage.getItem("organizationId") || "{}";

    const facilityCode: any = localStorage.getItem("facilityCode") || "{}";
    const submitStep = (values: any, actions: FormikValues) => {
        try {
            let formData = new FormData();
            formData.append("userName", values.phoneNumber);
            formData.append("firstName", values.firstName);
            formData.append("lastName", values.lastName);
            formData.append("middleName", values.middleName);
            formData.append("password", values.password);
            formData.append("email", values.email);
            formData.append("telecom", values.phoneNumber);
            formData.append("organizationId", organizationId);
            formData.append("facilityCode", facilityCode);
            formData.append("photo", values.photo);
            formData.append("id", values.id);
            formData.append("proof", values.proofLocation);
            formData.append("age", values.age);
            formData.append("languages", values.languages);
            formData.append("floorNumber", values.floorNumber);
            formData.append("streetNumber", values.streetNumber);
            formData.append("postalCode", values.postalCode);
            // Normal String Type
            formData.append("gender", values.gender);
            formData.append("grade", values.grade);
            formData.append("idProofName", values.idProofName);
            // formData.append("birthDate", values.birthDate);
            formData.append(
                "birthDate",
                convertToUtcTimestamp(values.birthDate).toString()
            );
            formData.append("preferredLanguage", values.preferredLanguage);
            formData.append("addressSiteName", values.addressSiteName);
            formData.append("streetName", values.streetName);
            formData.append("district", values.district);
            formData.append("town", values.town);
            formData.append("state", values.state);
            formData.append("country", values.country);

            mutate(formData, {
                onSuccess: (data: any) => {
                    setTimeout(() => {
                        navigate("/chw/chw-management");
                    }, 3000);
                },
                onError: (error: any) => {},
            });
        } catch (error) {
            console.log(error);
        }
    };

    const successToastMsg = () => {
        toast.success(
            (t) => (
                <span
                    data-testid="success-msg"
                    className="p-2 fs-4 d-flex align-items-center justify-content-center "
                >
                    {/* {successMsg} */}
                    Successfully Onboarded CHW!!
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "success-toast",
            }
        );
    };

    const errorToastMsg = useCallback(() => {
        toast.error(
            (t) => (
                <span
                    data-testid="error-msg"
                    className="p-2 fs-4 d-flex align-items-center justify-content-center w-100"
                >
                    {errorMsg?.includes("{") || errorMsg?.includes("[")
                        ? getErrorMessage(error?.response?.status)
                        : errorMsg}
                    <button
                        className="ms-15"
                        style={{ backgroundColor: "transparent", border: "0" }}
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <i className="fs-4 fas fa-times text-hover-primary"></i>
                    </button>
                </span>
            ),
            {
                duration: 5000,
                position: "top-right",
                id: "error-toast",
            }
        );
    }, [error?.response?.status, errorMsg]);

    const handleInputKeyDown = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    const formSteps = [
        {
            title: "Basic Info",
            subTitle: "Your Basic Details",
        },
        {
            title: "Profile Settings",
            subTitle: "Setup Your Profile Settings",
        },
        {
            title: "Personal Details",
            subTitle: "Provide Valid Details",
        },
        {
            title: "Additional Details",
            subTitle: "Profile Details",
        },
        {
            title: "Address Details",
            subTitle: "Provide Valid Address Information",
        },
        {
            title: "Completed",
            subTitle: "Woah, we are here",
        },
    ];
    const [activeIndex, setActiveIndex] = useState(0);

    const StepsContent = (activeStep: number, formikProps: any) => {
        switch (activeStep) {
            case 0:
                return (
                    <div>
                        <Step1 />
                    </div>
                );

            case 1:
                return (
                    <div>
                        <Step2 {...formikProps} />
                    </div>
                );
            case 2:
                return (
                    <div>
                        <Step3 />
                    </div>
                );
            case 3:
                return (
                    <div>
                        <Step4 {...formikProps} />
                    </div>
                );
            case 4:
                return (
                    <div>
                        <Step5 {...formikProps} />
                    </div>
                );
            case 5:
                return (
                    <div>
                        <Step6 />
                    </div>
                );
            default:
                return (
                    <div>
                        <h1>No Step</h1>
                    </div>
                );
        }
    };

    useEffect(() => {
        isSuccess && successToastMsg();
    }, [isSuccess]);

    useEffect(() => {
        isError && errorToastMsg();
    }, [isError, errorToastMsg]);

    const canReadPage = !hasAccessToModule("ONBOARD_CHW", Permissions.read);
    return (
        <div hidden={canReadPage}>
            <div>
                {/* Spinner */}
                {isLoading ? (
                    <div className="card w-100 h-500px d-flex flex-column align-items-center justify-content-center p-15">
                        <ClipLoader
                            data-testid="spinner"
                            size={70}
                            speedMultiplier={0.6}
                        />
                    </div>
                ) : (
                    <Formik
                        validationSchema={createAccountSchemas[activeIndex]}
                        initialValues={initsForCHWOnboarding}
                        onSubmit={submitStep}
                        enableReinitialize={true}
                    >
                        {(formikProps) => (
                            <Form onKeyDown={handleInputKeyDown}>
                                <Stepper
                                    steps={formSteps}
                                    activeIndex={activeIndex}
                                    setActiveIndex={setActiveIndex}
                                    stepComponent={
                                        <div className="py-20 w-100 w-xl-750px min-vh-50 px-9">
                                            {StepsContent(
                                                activeIndex,
                                                formikProps
                                            )}
                                        </div>
                                    }
                                    formikProps={formikProps}
                                />
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

export { CHWOnboarding };
