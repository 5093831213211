import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

type Props = {
    controlId?: string;
    label?: string;
    className?: string;
    type: 'text' | 'email' | 'password' | 'number' | 'date';
    placeholder?: string;
    name: string;
    defaultValue?: string;
    onChange: (e: string | React.ChangeEvent<any>) => void;
    required?: boolean;
    value: string;
    disabled?: boolean;
    min?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = ({
    controlId,
    label,
    required,
    className,
    placeholder,
    defaultValue,
    ...rest
}: Props) => {
    return (
        // @ts-ignore
        <FloatingLabel
            controlId={controlId}
            label={
                <>
                    {label}&nbsp;
                    {required && (
                        <span className="text-danger font-weight-bold">*</span>
                    )}
                </>
            }
            className={className}
        >
            {/* @ts-ignore */}
            <Form.Control
                // type={props.type}
                placeholder={placeholder}
                // name={props.name}

                defaultValue={defaultValue}
                // onChange={props.onChange}
                // value={props.value}
                {...rest}
                required={false}
            />
        </FloatingLabel>
    );
};

export default Input;
