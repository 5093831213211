/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { isArray } from 'lodash';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import _ from 'lodash';

import {
    AutoCompleteInput,
    SelectMenu,
    TextInput,
} from '../../../../../components/Inputs';
import { validationSchemaForAddSalesOrder } from './addBillingFormikValidations';
import ErrorMessageText from '../../../../../components/ErrorMessageText/ErrorMessageText';
import { IProductsArr } from './IForms';
import { IProduct, IProps } from './IForms';
import useGetRequest from '../../../../../hooks/getRequest.query';
import { getFacilityStock } from '../../../../../services/supply-chain/view-stock/facility-stock/FacilityStock.services';
import { getAllPatient } from '../../../../../services/patient/PatientManagement.hook';
import {
    getErrorMessage,
    isErrorMsgNotReadable,
} from '../../../../../common/utils/genericErrors';

const errorToastMsg = (err: string) => {
    toast.error(
        (t) => (
            <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
                {err}
                <button
                    className="ms-15"
                    style={{ backgroundColor: 'transparent', border: '0' }}
                    onClick={() => toast.dismiss(t.id)}
                >
                    <i className="fs-4 fas fa-times text-hover-primary"></i>
                </button>
            </span>
        ),
        {
            duration: 5000,
            position: 'top-right',
            id: 'error-toast-1',
        }
    );
};

const AddBillingProductForm = ({ setProductsArr, customerType }: IProps) => {
    const facilityCode = localStorage.getItem('facilityCode') as string;
    const [productCode, setProductCode] = useState('');

    const formik = useFormik({
        initialValues: {
            patientName: '',
            productCode: '',
            totalQuantity: '0',
            product: '',
            productId: '',
            manufacturer: '',
            batch: '',
            expiryDate: '',
            units: '',
            gstTotal: '',
            mrpPerUnit: '',
            discountPercent: '0',
            batchArr: [],
            vendorObj: { label: '', year: '' },
            productObj: { label: '', year: '' },
            patientObj: { label: '', year: '' },
            inventoryId: '',
        },
        validationSchema: validationSchemaForAddSalesOrder,
        onSubmit: (values: IProduct, { resetForm }) => {
            // Handle form submission

            setProductsArr((prevValue: IProductsArr) => {
                const uniqueArr =
                    prevValue &&
                    prevValue.every(
                        (ele) =>
                            ele.product !== values.product ||
                            ele.batch !== values.batch
                    );
                if (uniqueArr) {
                    values.batch = values.batch.split(';')[0];
                    return [...prevValue, values];
                }
                return prevValue;
            });
            // resetForm();
            // patientName: "",
            formik.setFieldValue('productCode', '');
            // productCode: "",
            formik.setFieldValue('product', '');
            // product: "",
            formik.setFieldValue('productId', '');
            // productId: "",
            formik.setFieldValue('manufacturer', '');
            // manufacturer: "",
            formik.setFieldValue('batch', '');
            // batch: "",
            formik.setFieldValue('expiryDate', '');
            // expiryDate: "",
            formik.setFieldValue('units', '');
            // units: "",
            formik.setFieldValue('gstTotal', '');
            // gstTotal: "5",
            formik.setFieldValue('mrpPerUnit', '');
            // mrpPerUnit: "",
            formik.setFieldValue('discountPercent', '0');
            // discountPercent: "0",
            formik.setFieldValue('batchArr', []);
            // batchArr: [],
            formik.setFieldValue('vendorObj', { label: '', year: '' });
            // vendorObj: { label: "", year: "" },
            formik.setFieldValue('productObj', { label: '', year: '' });
            // productObj: { label: "", year: "" },
            // patientObj: { label: "", year: "" },
            formik.setFieldValue('inventoryId', '');
            // inventoryId: "",
        },
    });

    const { data: patients }: any = useGetRequest(
        ['getAllPatient', formik.values.patientName],
        async () =>
            await getAllPatient({
                facilityCode,
                page: 1,
                limit: 100,
                status: '',
                fromDate: '',
                toDate: '',
                chwId: '',
                name: formik.values.patientName
                    ? formik.values.patientName
                    : '',
            }),
        {
            refetchOnWindowFocus: false,
        }
    );

    const { data, isError, error }: any = useGetRequest(
        ['getFacilityStock', facilityCode, productCode],
        () =>
            getFacilityStock({
                facilityCode,
                page: 1,
                limit: 1000,
                filterOption: 0,
                productName: '',
                genericName: '',
                productCode,
            }),
        {
            refetchOnWindowFocus: false,
            enabled: productCode.length > 0,
        }
    );
    useEffect(() => {
        if (
            data?.data?.data?.stock?.length !== 0 &&
            data?.data?.data?.stock[0]?.productData.length !== 0
        ) {
            const productId = data?.data?.data?.stock[0]?.productId;
            const inventoryId = data?.data?.data?.stock[0]?._id;
            formik.setFieldValue('productId', productId);
            formik.setFieldValue('inventoryId', inventoryId);
            formik.setFieldValue('gstTotal', data?.data?.data?.stock[0]?.GST);
            formik.setFieldValue(
                'product',
                data?.data?.data?.stock[0]?.productData[0]?.productName
            );
            formik.setFieldValue(
                'batch',
                `${data?.data?.data?.stock[0]?.batch};${JSON.stringify(
                    data?.data?.data?.stock
                )}`
            );
            formik.setFieldValue('batchArr', data?.data?.data?.stock);
            formik.setFieldValue(
                'manufacturer',
                data?.data?.data?.stock[0]?.productData[0]?.manufacturerName
            );
            const expiryDate = data?.data?.data?.stock[0]?.expiry
                ? dayjs(data?.data?.data?.stock[0]?.expiry).format('YYYY-MM-DD')
                : '';
            formik.setFieldValue('expiryDate', expiryDate);
            formik.setFieldValue(
                'mrpPerUnit',
                data?.data?.data?.stock[0]?.ratePerUnit
            );
            formik.setFieldValue(
                'discountPercent',
                data?.data?.data?.stock[0]?.discount
            );
            formik.setFieldValue(
                'totalQuantity',
                data?.data?.data?.stock[0]?.totalQuantity
            );
        }
        // @ts-ignore
    }, [data]);

    // const productList = useMemo(() => {
    //     return data?.data?.data?.stock
    //         ? data?.data?.data?.stock.map((ele: any) => {
    //               console.log("ccc", ele);
    //               return {
    //                   label: capitalize(ele?.productData[0]?.productName),
    //                   productId: ele?.productId,
    //                   manufacturer: ele?.productData[0]?.manufacturerName,
    //                   productCode: ele?.productData[0]?.productCode,
    //                   batch: [ele.batch],
    //                   expiryDate: dayjs(ele.expiry).format("YYYY-MM-DD"),
    //                   mrp: ele?.ratePerUnit,
    //                   discount: ele?.discount,
    //                   gstTotal: ele?.GST,
    //                   inventoryId: ele?._id,
    //               };
    //           })
    //         : {
    //               label: "",
    //               productId: "",
    //               manufacturer: "",
    //               batch: "",
    //               expiryDate: "",
    //               mrp: "",
    //               discount: "",
    //               gstTotal: "",
    //               inventoryId: "",
    //           };
    // }, [data]);

    const patientData = useMemo(() => {
        return patients && isArray(patients?.data?.data?.patients)
            ? patients?.data?.data?.patients.map((ele: any) => {
                  return {
                      label: `${ele.name} (uhid: ${ele?.uhid})`,
                      uhid: ele.uhid,
                  };
              })
            : [];
    }, [patients]);
    const handleBatchSelect = (e: any) => {
        const val = e.target.value.split(';');
        formik.setFieldValue('batch', e.target.value);
        const obj = JSON.parse(val[1]);
        formik.setFieldValue('gstTotal', obj?.GST);
        formik.setFieldValue('product', obj?.productData[0]?.productName);
        formik.setFieldValue(
            'manufacturer',
            obj?.productData[0]?.manufacturerName
        );
        formik.setFieldValue(
            'expiryDate',
            dayjs(obj?.expiry).format('YYYY-MM-DD')
        );
        formik.setFieldValue('mrpPerUnit', obj?.ratePerUnit);
        formik.setFieldValue('discountPercent', obj?.discount);
        formik.setFieldValue('totalQuantity', obj?.totalQuantity);
        formik.setFieldValue('inventoryId', obj?._id);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            {isError &&
                errorToastMsg(
                    isErrorMsgNotReadable(error?.response?.data?.message)
                        ? getErrorMessage(error?.response?.status)
                        : error?.response?.data?.message
                )}

            <div className="row mb-3">
                <div className="col-sm-2">
                    <AutoCompleteInput
                        label={'Patient Name'}
                        name="patientName"
                        data-testid="patient-menu"
                        required
                        // @ts-ignore
                        onInputChange={(e: any) => {
                            _.debounce(
                                // @ts-ignore
                                () =>
                                    formik.setFieldValue(
                                        'patientName',
                                        e.target.value
                                    ),
                                2000
                            )();
                        }}
                        onChange={(e: any, value: any) => {
                            formik.setFieldValue('patientName', value.label);
                            formik.setFieldValue('patientObj', value);
                        }}
                        value={formik.values.patientObj}
                        optionsArr={patientData}
                        className="mb-3"
                    />
                    {formik.touched.patientName &&
                        formik.errors.patientName && (
                            <ErrorMessageText
                                message={formik.errors.patientName}
                            />
                        )}
                </div>
                <div className="col">
                    <TextInput
                        className="mb-3"
                        controlId="productCode"
                        label="Bar Code"
                        placeholder="0"
                        type="text"
                        data-testid="productCode"
                        name="productCode"
                        onChange={(e: any) => {
                            formik.handleChange(e);
                            _.debounce(
                                // @ts-ignore
                                () => setProductCode(e.target.value),
                                2000
                            )();
                        }}
                        value={formik.values.productCode}
                        required
                    />
                    {formik.touched.productCode &&
                        formik.errors.productCode && (
                            <ErrorMessageText
                                message={formik.errors.productCode}
                            />
                        )}
                </div>
                <div className="col-sm-4">
                    <TextInput
                        className="mb-3"
                        controlId="product"
                        label="Product"
                        placeholder="0"
                        type="text"
                        disabled
                        data-testid="product"
                        name="product"
                        onChange={formik.handleChange('product')}
                        value={formik.values.product}
                        required
                    />

                    {formik.touched.product && formik.errors.product && (
                        <ErrorMessageText message={formik.errors.product} />
                    )}
                </div>
                <div className="col">
                    <SelectMenu
                        label="Batch"
                        name="batch"
                        data-testid="batch-input"
                        onChange={handleBatchSelect}
                        className="mb-3"
                        value={formik.values.batch}
                        required
                    >
                        {formik?.values?.batchArr?.map((ele: IProduct) => {
                            return (
                                <option
                                    value={`${ele?.batch};${JSON.stringify(
                                        ele
                                    )}`}
                                >
                                    {ele?.batch}
                                </option>
                            );
                        })}
                    </SelectMenu>
                    {formik.touched.batch && formik.errors.batch && (
                        <ErrorMessageText message={formik.errors.batch} />
                    )}
                </div>

                <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="units"
                        label="Units"
                        min="0"
                        placeholder="0"
                        type="number"
                        data-testid="unit-input"
                        name="units"
                        onChange={formik.handleChange('units')}
                        value={formik.values.units}
                        required
                    />
                    <p>
                        Available Qty:{' '}
                        {formik.values.totalQuantity
                            ? formik.values.totalQuantity
                            : '--'}
                    </p>
                    {formik.touched.units && formik.errors.units && (
                        <ErrorMessageText message={formik.errors.units} />
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="expiryDate"
                        label="Expiry Date"
                        placeholder="DD-MM-YYYY"
                        disabled
                        type={'date'}
                        data-testid="expiry-date"
                        name="expiryDate"
                        onChange={formik.handleChange('expiryDate')}
                        value={
                            formik.values.expiryDate ===
                            dayjs().format('YYYY-MM-DD')
                                ? ''
                                : formik.values.expiryDate
                        }
                        required
                    />

                    {formik.touched.expiryDate && formik.errors.expiryDate && (
                        <ErrorMessageText message={formik.errors.expiryDate} />
                    )}
                </div>
                <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="mrpPerUnit"
                        label="MRP/unit"
                        data-testid="mrp-strip"
                        placeholder="0"
                        type="number"
                        min="0"
                        disabled
                        name="mrpPerUnit"
                        onChange={formik.handleChange('mrpPerUnit')}
                        value={formik.values.mrpPerUnit}
                        required
                    />
                    {formik.touched.mrpPerUnit && formik.errors.mrpPerUnit && (
                        <ErrorMessageText message={formik.errors.mrpPerUnit} />
                    )}
                </div>
                <div className="col-sm-2">
                    <TextInput
                        className="mb-3"
                        controlId="discount"
                        data-testid="disc-input"
                        label="Discount %"
                        placeholder="0"
                        type="text"
                        min="0"
                        name="discountPercent"
                        onChange={formik.handleChange('discountPercent')}
                        value={formik.values.discountPercent}
                    />
                    {formik.touched.discountPercent &&
                        formik.errors.discountPercent && (
                            <ErrorMessageText
                                message={formik.errors.discountPercent}
                            />
                        )}
                </div>

                <div className="col-sm-2 d-flex">
                    <button
                        className="btn btn-primary"
                        type="submit"
                        style={{ height: '3.7rem' }}
                        data-testid="add-btn"
                    >
                        +
                    </button>
                </div>
            </div>
        </form>
    );
};

export default AddBillingProductForm;
