import React from "react";
import dayjs from "dayjs";
import {
    Table,
    TableBodyContainer,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../../../../components/Table";

import Pagination from "../../../../components/Pagination";

import { Link } from "react-router-dom";
import { capitalize } from "lodash";
import CustomTooltip from "../../../../components/CustomTooltip";
import {
    convertUtcTimestampToLocalDate,
    dateFormatter,
} from "../../../../common/utils";

type Props = {
    filterValues: any;
    data: any;
    setPage: Function;
    setLimit: Function;
    page: number;
    limit: number;
    isLoading: boolean;
    errorMsg: string;
    isError: boolean;
};
const tableNames = [
    "PO ID",
    "PO Date",
    "Indent ID",
    "Vendor Name",
    "Amount",
    "GRN NO",
    "Status",
];
const PurchaseOrderListTable = (props: Props) => {
    const {
        data,
        setLimit,
        limit,
        page,
        setPage,
        isLoading,
        errorMsg,
        isError,
    } = props;

    const findUniqueGrnIds = (arr: { grnId: string }[]) => {
        const uniqueGrnIds = new Set<string>();
        let result = "";

        // Iterate over the array and add each grnId to the set (uniqueGrnIds).
        arr?.forEach(({ grnId }) => {
            uniqueGrnIds.add(grnId);
        });

        // Iterate over the unique GRN IDs and build the result string.
        let isFirst = true;
        uniqueGrnIds.forEach((grnId) => {
            // Add a comma and space before each ID except the first one.
            if (!isFirst) {
                result += ", ";
            }
            result += grnId;
            isFirst = false;
        });

        return result;
    };

    // const firstUniqueGrnIds = (arr: { grnId: string }[]) => {
    //     const uniqueGrnIds = new Set<string>();

    //     // Iterate over the array and add each grnId to the set (uniqueGrnIds).
    //     arr?.forEach(({ grnId }) => {
    //         uniqueGrnIds.add(grnId);
    //     });
    //     const firstValue = uniqueGrnIds.values();

    //     return firstValue.next();
    // };

    return (
        <>
            <Table>
                <TableHeadRow>
                    <TableHead width={140} align="center">
                        SL NO
                    </TableHead>
                    {tableNames?.map((name) => {
                        return (
                            <TableHead width={180} key={name} align="left">
                                {name.toUpperCase()}
                            </TableHead>
                        );
                    })}
                </TableHeadRow>
                <TableBodyContainer
                    isLoading={isLoading}
                    isError={isError}
                    errorMessage={errorMsg}
                >
                    {data?.data?.purchaseOrders ? (
                        data?.data?.purchaseOrders?.map(
                            (ele: any, idx: number) => {
                                const isGRNAvailable = Object.hasOwn(
                                    ele,
                                    "grn"
                                );

                                return (
                                    <TableRow collapsible={false}>
                                        <TableCell align="center">
                                            {page &&
                                                (page - 1) * limit + idx + 1}
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                to={`/purchase-order/details?purchaseOrderId=${
                                                    ele._id
                                                }&orderId=${
                                                    ele.purchaseOrderId
                                                }&orderDate=${dayjs(
                                                    new Date(ele.createdAt)
                                                ).format(
                                                    "DD MMM,YYYY"
                                                )}&indentId=${ele.indentId}`}
                                                className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                            >
                                                {ele.purchaseOrderId}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {ele.createdAt
                                                ? convertUtcTimestampToLocalDate(
                                                      ele.createdAt
                                                  )
                                                : "N/A"}
                                        </TableCell>
                                        <TableCell>{ele.indentId}</TableCell>
                                        <TableCell>
                                            {capitalize(
                                                ele?.vendorId?.vendorName
                                            )}
                                        </TableCell>
                                        <TableCell>{ele.totalAmount}</TableCell>
                                        <TableCell>
                                            <CustomTooltip
                                                sx={{
                                                    textTransform: "capitalize",
                                                }}
                                                title={findUniqueGrnIds(
                                                    ele.grn ?? ""
                                                )}
                                            >
                                                <span>
                                                    {isGRNAvailable
                                                        ? ele?.grn?.length === 0
                                                            ? "N/A"
                                                            : ele?.grn?.length >
                                                              1
                                                            ? ele?.grn[0]
                                                                  ?.grnId +
                                                              " " +
                                                              "..."
                                                            : ele?.grn[0]?.grnId
                                                        : "N/A"}
                                                </span>
                                            </CustomTooltip>
                                        </TableCell>
                                        <TableCell>
                                            <span
                                                style={{
                                                    color:
                                                        ele?.poOrderStatus ===
                                                        "Order Completed"
                                                            ? "#50cd89"
                                                            : ele?.poOrderStatus ===
                                                              "Order Placed"
                                                            ? "#FFA533"
                                                            : "",
                                                }}
                                            >
                                                {ele.poOrderStatus}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )
                    ) : (
                        <TableRow collapsible={false}>
                            <TableCell align="center">
                                {page && (page - 1) * limit + 1}
                            </TableCell>
                            <TableCell>
                                <Link
                                    to={`/purchase-order/details?purchaseOrderId=${
                                        data?.data?.purchaseOrder._id
                                    }&orderId=${
                                        data?.data?.purchaseOrder
                                            .purchaseOrderId
                                    }&orderDate=${dayjs(
                                        new Date(
                                            data?.data?.purchaseOrder.createdAt
                                        )
                                    ).format("DD MMM,YYYY")}&indentId=${
                                        data?.data?.purchaseOrder.indentId
                                    }`}
                                    className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                                >
                                    {data?.data?.purchaseOrder.purchaseOrderId}
                                </Link>
                            </TableCell>
                            <TableCell>
                                {/* {dayjs(
                                    new Date(
                                        data?.data?.purchaseOrder.createdAt
                                    )
                                ).format("DD MMM,YYYY")} */}

                                {data?.data?.purchaseOrder.createdAt
                                    ? convertUtcTimestampToLocalDate(
                                          data?.data?.purchaseOrder.createdAt
                                      )
                                    : "N/A"}
                            </TableCell>
                            <TableCell>
                                {data?.data?.purchaseOrder.indentId}
                            </TableCell>
                            <TableCell>
                                {capitalize(
                                    data?.data?.purchaseOrder?.vendorId
                                        ?.vendorName
                                )}
                            </TableCell>
                            <TableCell>
                                {data?.data?.purchaseOrder.totalAmount}
                            </TableCell>

                            <TableCell>
                                <CustomTooltip
                                    sx={{
                                        textTransform: "capitalize",
                                    }}
                                    title={findUniqueGrnIds(
                                        data?.data?.purchaseOrder.grn
                                    )}
                                >
                                    <span className="border-none text-capitalize">
                                        {data?.data?.purchaseOrder?.grn
                                            ?.length > 1
                                            ? data?.data?.purchaseOrder?.grn[0]
                                                  ?.grnId +
                                              " " +
                                              ".."
                                            : data?.data?.purchaseOrder?.grn[0]
                                                  ?.grnId ?? "N/A"}
                                    </span>
                                </CustomTooltip>
                            </TableCell>
                            <TableCell>
                                <span
                                    style={{
                                        color:
                                            data?.data?.purchaseOrder
                                                ?.poOrderStatus ===
                                            "Order Completed"
                                                ? "#50cd89"
                                                : data?.data?.purchaseOrder
                                                      ?.poOrderStatus ===
                                                  "Order Placed"
                                                ? "#FFA533"
                                                : "",
                                    }}
                                >
                                    {data?.data?.purchaseOrder.poOrderStatus}
                                </span>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBodyContainer>
            </Table>
            <Pagination
                dataCount={data?.results}
                handleCallback={(val: number | string) => {
                    setLimit(val);
                }}
                handleNext={() =>
                    setPage((prevPageNumber: number) => prevPageNumber + 1)
                }
                handlePrev={() =>
                    setPage((prevPageNumber: number) => prevPageNumber - 1)
                }
                nextDisabled={page === Math.ceil(data?.results / limit)}
                pageNumber={page}
                pageLimit={limit}
                prevDisabled={page === 1}
            />
        </>
    );
};

export default PurchaseOrderListTable;
