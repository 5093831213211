import { useEffect, useMemo } from 'react';
import { useNavbar } from '../../context/NavbarContext';
import useGetRequest from '../../hooks/getRequest.query';
import { getAllReportTypes } from '../../services/report/ReportGeneration.hook';
import ReportTable from './table/ReportTable';

const ReportPage = () => {
  const { selectedFacility } = useNavbar();
  const { data, refetch }: any = useGetRequest(
    'getAllReportTypes',
    async () => await getAllReportTypes(),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [selectedFacility]);

  const allReportList = useMemo(() => data?.data?.data?.reportTypeList, [data]);

  return (
    <div className="mt-5">
      <ReportTable allReportList={allReportList} />
    </div>
  );
};

export default ReportPage;
