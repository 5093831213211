interface PrescriptionModalProps {
    handleModal: () => void;
    openPrescription: () => void;
}
function PrescriptionModal({
    handleModal,
    openPrescription,
}: PrescriptionModalProps) {
    return (
        <>
            <div
                className="position-fixed top-0 start-0 w-100 h-100 bg-black opacity-50"
                style={{ zIndex: 1040 }}
            ></div>

            {/* Modal overlay */}
            <div
                className="position-fixed top-50 start-50 translate-middle"
                style={{ zIndex: 1050 }}
            >
                <div className="w-600px h-300px bg-white p-10 rounded d-flex flex-column border border-2 position-relative">
                    <div className="row text-center">
                        <h2 className="text-center">Prescription</h2>
                    </div>
                    <div className="row mt-20">
                        <div className="col d-flex justify-content-center align-items-center">
                            <div>
                                <button className="border border-2 rounded px-5 py-2 cursor-pointer btn btn-outline-light text-black text-hover-primary">
                                    Upload Prescription
                                </button>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-center align-items-center">
                            <div>
                                <button
                                    className="border border-2 rounded px-5 py-2 cursor-pointer btn btn-outline-light text-black text-hover-primary"
                                    onClick={openPrescription}
                                >
                                    Add Prescription
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Close btn*/}
                <div className="position-absolute bottom-0 end-0 text-hover-danger mb-2 me-2">
                    <button className="btn btn-light" onClick={handleModal}>
                        Close
                    </button>
                </div>
            </div>
        </>
    );
}

export default PrescriptionModal;
