import React, { useMemo, useState } from 'react';
// import dayjs from 'dayjs';
import { Autocomplete, TextField } from '@mui/material';

import {
  Table,
  TableHeadRow,
  TableBodyContainer,
  TableHead,
  TableRow,
  TableCell,
} from '../../../../../components/Table';

import Pagination from '../../../../../components/Pagination';
import SearchBox from '../SearchBox';

// API
import useGetRequest from '../../../../../hooks/getRequest.query';

import { getChwStockList } from '../../../../../services/supply-chain/view-stock/chw-stock/index.services';
import { getAllCHW } from '../../../../../services/CHWManagement.hook';
import { customRecords, dateFormatter } from '../../../../../common/utils';
import { useNavbar } from '../../../../../context/NavbarContext';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../../../common/utils/genericErrors';

const tableNames = [
  'PRODUCT NAME',
  'GENERIC NAME',
  'EXPIRY DATE',
  'CHW NAME',
  'BATCH ID',
  'AVAILABLE QUANTITY',
  'PURCHASE PRICE',
  'COST',
  'MRP',
];

const ViewStockTable = () => {
  const { selectedFacility } = useNavbar();
  const facilityCode = localStorage.getItem('facilityCode') as string;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const payload = {
    page: 1,
    limit: 100,
    facilityCode,
    grade: '',
    fieldstatus: '',
    access: '',
  };

  const { data: chwData } = useGetRequest(
    'getAllCHW',
    () => getAllCHW(payload),
    { staleTime: 100000, refetchOnWindowFocus: false }
  );
  const chwList = useMemo(() => {
    const processedData = chwData?.data?.data?.chw?.map((ele: any) => {
      return { label: ele?.name, chwId: ele?.chwId };
    });
    return processedData;
  }, [chwData]);

  const [selectedCHW, setSelectedCHW] = useState({
    label: '',
    chwId: '',
  });
  const [search, setSearch] = useState({
    productName: '',
    genericName: '',
  });
  // Table View API
  const { data, error, refetch, isError, isFetching, isLoading } =
    useGetRequest(
      [
        'getChwStock',
        page,
        limit,
        facilityCode,
        selectedCHW,
        search,
        selectedFacility,
      ],
      () =>
        getChwStockList({
          facilityCode,
          page,
          limit,
          chwId: selectedCHW?.chwId ? selectedCHW?.chwId : '', // "CHW49488",
          productName: search?.productName,
          genericName: search?.genericName,
        }),
      { staleTime: 100000, refetchOnWindowFocus: false }
    );
  const chwStockData = data?.data?.data?.products;

  const totalEntites = data?.data?.results;
  const totalPages = Math.ceil(totalEntites / limit);

  // @ts-ignore
  const errorMsg = error?.response?.data?.message;

  return (
    <div>
      <div className="bg-white border-0 d-flex justify-content-between align-items-center p-5">
        <div className="d-flex align-items-center justify-content-center h-50px">
          <h1>CHW STOCK </h1>
          <i
            onClick={() => {
              refetch();
              setSelectedCHW({
                label: '',
                chwId: '',
              });
              setSearch({ genericName: '', productName: '' });
            }}
            className="fas fa-redo fs-3 cursor-pointer ms-5"
          ></i>
        </div>

        <SearchBox setSearch={setSearch} setPage={setPage} />

        <Autocomplete
          data-testid="chwName"
          disablePortal
          autoHighlight
          id="combo-box-demo"
          options={chwList}
          style={{ width: '250px' }}
          sx={{ width: 300 }}
          onChange={(e, value) => {
            // @ts-ignore
            return setSelectedCHW(value);
          }}
          renderInput={(params) => <TextField {...params} label={'CHW Name'} />}
        />
      </div>

      <div className="card text-dark">
        {!isError ? (
          <div className="card-body py-3">
            <Table>
              <TableHeadRow>
                <TableHead width={140} align="center">
                  SL NO
                </TableHead>
                {tableNames?.map((name) => {
                  return (
                    <TableHead width={180} key={name}>
                      {name}
                    </TableHead>
                  );
                })}
              </TableHeadRow>

              <TableBodyContainer
                isLoading={isLoading || isFetching}
                isError={isError}
                errorMessage={errorMsg}
              >
                {chwStockData?.map((item: any, index: any) => {
                  return item?.productData?.map((product: any, idx: number) => {
                    return (
                      <TableRow collapsible={false} key={index}>
                        <TableCell align="center">
                          {page && (page - 1) * limit + index + 1}
                        </TableCell>

                        <TableCell hover>
                          {product?.productName ? product?.productName : '--'}
                        </TableCell>

                        <TableCell hover>
                          {product?.genericName ? product?.genericName : '--'}
                        </TableCell>

                        <TableCell>
                          {item?.expiry ? dateFormatter(item?.expiry) : '--'}
                        </TableCell>
                        <TableCell>
                          {customRecords(item?.chwData[0]?.name)}
                        </TableCell>

                        {/* Batch */}
                        <TableCell>
                          {customRecords(item?.inventoryData[0]?.batch)}
                        </TableCell>

                        {/* Qty */}
                        <TableCell>
                          {customRecords(item?.quantityInHand)}
                        </TableCell>

                        {/* Purchase price */}
                        <TableCell>
                          {item?.inventoryData[0]?.pricePerStrip
                            ? item?.inventoryData[0]?.pricePerStrip.toFixed(2)
                            : '--'}
                        </TableCell>
                        {/* COST */}
                        <TableCell>
                          {item?.inventoryData[0]?.totalPriceValue
                            ? item?.inventoryData[0]?.totalPriceValue.toFixed(2)
                            : '--'}
                        </TableCell>
                        {/* MRP */}
                        <TableCell>
                          {item?.inventoryData[0]?.ratePerStrip
                            ? item?.inventoryData[0]?.ratePerStrip.toFixed(2)
                            : '--'}
                        </TableCell>
                      </TableRow>
                    );
                  });
                })}
              </TableBodyContainer>
            </Table>

            {/* Pagination */}
            <Pagination
              dataCount={totalEntites}
              handleCallback={(val) => setLimit(+val)}
              handleNext={() =>
                setPage((prevPageNumber: number) => prevPageNumber + 1)
              }
              handlePrev={() =>
                setPage((prevPageNumber: number) => prevPageNumber - 1)
              }
              nextDisabled={page === totalPages}
              prevDisabled={page === 1}
              pageLimit={limit}
              pageNumber={page}
            />
          </div>
        ) : (
          <div
            className=" d-flex align-items-center justify-content-center"
            style={{ height: '50vh' }}
          >
            <p className="text-danger fs-2 fw fw-bolder ">
              {isErrorMsgNotReadable(errorMsg)
                ? /* @ts-ignore */
                  getErrorMessage(error?.response?.status)
                : errorMsg}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewStockTable;
