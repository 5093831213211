import { useEffect, useMemo, useState } from 'react';
import TaskCounterPage from './TaskCounterPage';
import { TaskTable } from './table/TaskTable';
import { useNavbar } from '../../context/NavbarContext';
import useGetRequest from '../../hooks/getRequest.query';
import { getAllCHWByFacility } from '../../services/focused-group/FocusedGroup.hook';
import { getAllTaskByFacility } from '../../services/TaskManagement.hook';
import { convertToUtcTimestamp } from '../../common/utils';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../common/utils/genericErrors';

const TaskPage = () => {
  const { selectedFacility } = useNavbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterData, setFilterData]: any = useState({
    chwId: '',
    status: '',
    fromDate: '',
    toDate: '',
    taskType: '',
  });

  const facilityCode = localStorage.getItem('facilityCode');

  const {
    data,
    isLoading,
    isFetching,
    isError: isTaskError,
    error: TaskError,
    refetch,
  }: any = useGetRequest(
    ['getTask', pageNumber, pageLimit],
    () =>
      getAllTaskByFacility({
        facilityCode,
        page: pageNumber,
        limit: pageLimit,
        chwId: filterData?.chwId,
        status: filterData?.status,
        taskType: filterData?.taskType,
        fromDate:
          filterData?.fromDate !== ''
            ? convertToUtcTimestamp(filterData?.fromDate)
            : '',
        toDate:
          filterData?.toDate !== ''
            ? convertToUtcTimestamp(filterData?.toDate)
            : '',
      }),
    { staleTime: 100000, refetchOnWindowFocus: false }
  );

  const TaskData = data?.data?.data?.tasks;
  const TaskCounter = data?.data?.data?.taskCounters;
  const totalTaskEntites = data?.data?.results;
  const totalAvailablePages = Math.ceil(totalTaskEntites / pageLimit);

  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(TaskError?.response?.data?.message)
        ? getErrorMessage(TaskError?.response?.status)
        : TaskError?.response?.data?.message,
    [TaskError]
  );
  const successMsg = data?.data?.data?.message;

  const { data: chwList, refetch: refetchCHW }: any = useGetRequest(
    'allCHWData',
    async () =>
      await getAllCHWByFacility({
        facilityCode,
        page: 1,
        limit: 20,
      }),
    {
      retry: false,
      staleTime: 100000,
      refetchOnWindowFocus: false,
    }
  );

  const chwData = chwList?.data?.data?.chw;

  useEffect(() => {
    refetch();
    refetchCHW();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, selectedFacility]);

  return (
    <>
      <div>
        <TaskCounterPage
          totalCount={totalTaskEntites}
          data={TaskCounter}
          isLoading={isLoading}
          isFetching={isFetching}
          filterData={filterData}
        />
      </div>
      <div className="mt-5">
        <TaskTable
          filterData={filterData}
          bodyProps={TaskData}
          isLoading={isLoading}
          isFetching={isFetching}
          isTaskError={isTaskError}
          errorMsg={errorMsg}
          successMsg={successMsg}
          currentPageNumber={pageNumber}
          totalTaskListPages={totalAvailablePages}
          totalTaskListEntites={totalTaskEntites}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          setCurrentPageNumber={setPageNumber}
          setFilterData={setFilterData}
          chwData={chwData}
        />
      </div>
    </>
  );
};

export default TaskPage;
