import React, { useEffect, useMemo, useState } from 'react';
import IndentFilter from './IndentFilter';
import IndentTable from './IndentTable';
import useGetRequest from '../../../../hooks/getRequest.query';
import { getAllIndents } from '../../../../services/supply-chain/indent/Indent.services';
import { useNavbar } from '../../../../context/NavbarContext';
// import { useAppInfo } from '../../../../common/config/useAppInfo';
import FilterTile from '../../../../components/filter-tile';
import { convertToUtcTimestamp } from '../../../../common/utils';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../../common/utils/genericErrors';

const IndentListPage = () => {
  const { selectedFacility } = useNavbar();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [filterData, setFilterData]: any = useState({
    fromDate: '',
    toDate: '',
    indentStatus: '',
  });
  const facilityCode = localStorage.getItem('facilityCode');
  const userRole = localStorage.getItem('userRole');
  // Table View API
  const { data, isLoading, isFetching, isError, error, refetch }: any =
    useGetRequest(
      ['getAllIndents', limit, page],
      () =>
        getAllIndents({
          facilityCode,
          fromDate:
            filterData?.fromDate !== ''
              ? convertToUtcTimestamp(filterData?.fromDate)
              : '',
          toDate:
            filterData?.toDate !== ''
              ? convertToUtcTimestamp(filterData?.toDate)
              : '',
          indentStatus: filterData?.indentStatus,
          page,
          limit,
        }),
      { staleTime: 100000, refetchOnWindowFocus: false }
    );

  const indentList = data?.data?.data?.indents;
  const totalEntites = data?.data?.results;
  const isPageRefreshing = isLoading || isFetching;

  const errorMessage = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error?.response?.data?.message, error?.response?.status]
  );

  //   const successMsg = data?.data?.data?.message;

  useEffect(() => {
    refetch();
  }, [filterData, facilityCode, selectedFacility, userRole]);

  const payload = {
    fromDate: '',
    toDate: '',
    indentStatus: '',
  };
  return (
    <div className="card text-dark p-3">
      <div className="d-flex justify-content-between">
        <span className="d-flex">
          <h1>INDENT LIST</h1>
          <i
            onClick={() => setFilterData(payload)}
            className="mt-2 fas fa-redo fs-3 cursor-pointer ms-5"
          ></i>
        </span>
        <div>
          <IndentFilter
            setPage={setPage}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </div>
      </div>

      <FilterTile
        filterData={filterData}
        keyNames={{
          fromDate: 'Start Date',
          toDate: 'End Date',
          indentStatus: 'Indent Status',
        }}
        clearAll={() =>
          setFilterData({
            fromDate: '',
            toDate: '',
            indentStatus: '',
          })
        }
      />
      <div className="mt-4">
        {!isError ? (
          <IndentTable
            indentList={indentList}
            isPageRefreshing={isPageRefreshing}
            isError={isError}
            errorMessage={errorMessage}
            setPage={setPage}
            setLimit={setLimit}
            page={page}
            limit={limit}
            totalEntites={totalEntites}
            loggedInUser={userRole}
          />
        ) : (
          <div
            className=" d-flex align-items-center justify-content-center"
            style={{ height: '50vh' }}
          >
            <p className="text-danger fs-2 fw fw-bolder ">
              {errorMessage ?? 'There is no indent'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default IndentListPage;
