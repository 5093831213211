import { Menu } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
// import { Toaster } from "react-hot-toast";
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
// import { stringTruncate, warnUser } from "../../../common/utils";
import CustomTooltip from '../../../components/CustomTooltip';
import TableErrorComponent from '../../../components/Error/TableError';
import Modal from '../../../components/Modal';
import Pagination from '../../../components/Pagination';
import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../../components/Table';
import FilterTile from '../../../components/filter-tile';
import useMutateRequest from '../../../hooks/getMutation.query';
import { Permissions, hasAccessToModule } from '../../../rbac';
import { searchCHWByName } from '../../../services/CHWManagement.hook';
import CHWExport from '../components/CHWExport';
import ReassignCHW from '../components/ReassignCHW';
import RemoveCHW from '../components/RemoveCHW';
import TargetDistributionPage from '../target-distribution/TargetDistributionPage';
import { dataProps } from '../types/CHW.types';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../common/utils/genericErrors';

const tableHeadings = [
  'ROWS',
  'SL NO',
  'NAME',
  'FIELD STATUS',
  'PHONE NO',
  'FACILITY',
  'GRADE',
  'EMAIL ID',
];

const searchSchema = Yup.object().shape({
  searchName: Yup.string().min(3, 'Minimum 3 Letters Required').label('Search'),
});

const filterSchema = Yup.object().shape({
  grade: Yup.string().label('Grade'),
  fieldstatus: Yup.string().label('Field Status'),
  access: Yup.string().label('Access'),
});

const CHWTable = ({
  filterData,
  bodyProps,
  getCHWLoader,
  getCHWFetcher,
  isCHWError,
  chwErrorMsg,
  currentPageNumber,
  handlePageNumber,
  totalGetCHWPages,
  setFilterData,
  pageLimit,
  refetch,
  setPageLimit,
  chwCounterData,
  errorStatus,
}: dataProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
  const [isTargetModalOpen, setIsTargetModalOpen] = useState<boolean>(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);
  // Setting the Search term
  const [searchData, setSearchData]: any = useState([]);
  const [searchPageNumber] = useState(1);
  const [searchFormProps, setSearchFormProps] = useState('');
  const [chwName, setCHWName] = useState('');
  const [chwId, setCHWId] = useState('');

  const facilityCode: any = localStorage.getItem('facilityCode');

  // API For Search CHW
  const {
    data,
    mutate,
    isError: isSearchError,
    error: ErrorMsg,
    isLoading: searchCHWLoader,
    reset,
  }: any = useMutateRequest((data: any) => searchCHWByName(data));

  const totalSearchEntites = data?.data?.results;
  const totalSearchCHWPages = Math.ceil(totalSearchEntites / pageLimit);
  const totalPages =
    searchData?.length > 0
      ? totalSearchCHWPages
      : Math.ceil(totalGetCHWPages / pageLimit);

  const searchErrorMsg = ErrorMsg?.response?.data?.message;

  // Begins Menu Here
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Ends Here
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleAssignModal = () => {
    setIsAssignModalOpen(!isAssignModalOpen);
  };
  const handleRemoveModal = () => {
    setIsRemoveModalOpen(!isRemoveModalOpen);
  };
  const handleTargetDistributionModal = () => {
    setIsTargetModalOpen(!isTargetModalOpen);
  };

  const onSearchSubmit = (values: any, onSubmitProps: any) => {
    try {
      setSearchFormProps(onSubmitProps);
      const payload = {
        name: values.searchName?.trim(),
        facilityCode,
        limit: pageLimit,
        page: searchPageNumber,
      };

      mutate(payload, {
        onSuccess: (data: any) => {
          const searchTerm = data?.data?.data?.chws;
          setSearchData(searchTerm);
          handlePageNumber(1);
          onSubmitProps.resetForm();
        },
        onError: (error: any) => {
          setSearchData([]);
          onSubmitProps.resetForm();
        },
      });
    } catch (error) {
      // onSubmitProps.resetForm();
      setSearchData([]);
    }
  };

  const onFilterSubmit = (values: any, onSubmitProps: any) => {
    try {
      const payload = {
        grade: values.grade,
        fieldstatus: values.fieldstatus,
        access: values.access,
      };

      setFilterData(payload);
      // onSubmitProps.resetForm();
      handleClose();
    } catch (error) {
      // onSubmitProps.resetForm();
      handleClose();
    }
  };

  const clearData = (searchFormProps: any) => {
    if (searchFormProps) {
      searchFormProps.resetForm();
      setSearchData([]);
      reset();
    }
    const payload = {
      grade: '',
      fieldstatus: '',
      access: '',
    };
    setFilterData(payload);
  };

  const [selectedUser, setSelectedUser]: any = useState([]);

  useEffect(() => {
    setSelectedUser(searchData?.length > 0 ? searchData : bodyProps);
  }, [bodyProps, searchData]);

  const handleCheckbox = (e: any) => {
    const { name, checked } = e.target;

    let tempUser = selectedUser?.map((user: any, index: any) => {
      return user?._id === name ? { ...user, isChecked: checked } : user;
    });

    setSelectedUser(tempUser);
  };

  const checkCHWTasks = selectedUser?.filter((task: any) => {
    return task?.isChecked === true;
  });
  const canDistribute = hasAccessToModule(
    'LIST_CHW',
    Permissions.distribute_button
  );
  const canReassign = hasAccessToModule(
    'LIST_CHW',
    Permissions.reassign_button
  );
  const canDelete = hasAccessToModule('LIST_CHW', Permissions.delete_button);

  return (
    <div className="card text-dark">
      <Modal
        open={isOpen}
        styles={{
          width: '650px',
        }}
      >
        <CHWExport onClose={handleModal} />
      </Modal>
      <Modal
        open={isAssignModalOpen}
        overlayStyles={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <ReassignCHW
          styles={{
            backgroundColor: 'white',
            width: '650px',
            height: '450px',
            msgHeight: '200px',
            padding: '50px',
          }}
          onClose={handleAssignModal}
          chwName={chwName}
          chwId={chwId}
        />
      </Modal>
      <Modal
        open={isRemoveModalOpen}
        overlayStyles={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <RemoveCHW
          styles={{
            backgroundColor: 'white',
            width: '650px',
            height: '425px',
            msgHeight: '200px',
            padding: '50px',
          }}
          onClose={handleRemoveModal}
          chwName={chwName}
          chwId={chwId}
          refetch={refetch}
        />
      </Modal>
      {/* Target Distribution */}
      <Modal
        open={isTargetModalOpen}
        overlayStyles={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <TargetDistributionPage
          styles={{
            backgroundColor: 'white',
            width: '650px',
            height: '550px',
            msgHeight: '200px',
            padding: '30px',
          }}
          onClose={handleTargetDistributionModal}
          selectedCHW={checkCHWTasks}
          allCHW={selectedUser}
          refetch={refetch}
          chwCounterData={chwCounterData}
        />
      </Modal>
      <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
        <div className="d-flex align-items-center justify-content-center h-50px">
          <h1>CHW LIST</h1>
          <i
            onClick={() => clearData(searchFormProps)}
            className="fas fa-redo fs-3 cursor-pointer ms-5"
          ></i>
        </div>
        {!isCHWError && (
          <div className="card-toolbar">
            {/* Search Bar */}
            <div className="py-2 me-20">
              <Formik
                initialValues={{
                  facilityCode: '',
                  searchName: '',
                }}
                validationSchema={searchSchema}
                onSubmit={onSearchSubmit}
              >
                {(formProps: any) => (
                  <Form className="w-100 d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex ">
                      <Field
                        name="searchName"
                        placeholder="Search CHW"
                        className="form-control form-control-md form-control-solid"
                      />
                      <button
                        disabled={
                          formProps.isSubmitting ||
                          !(formProps.isValid && formProps.dirty)
                        }
                        type="submit"
                        className="ms-3 btn btn-lg btn-primary"
                      >
                        Search
                      </button>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                      <ErrorMessage name="searchName" />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* Assign task */}
            {canDistribute && (
              <button
                // hidden={rbacInfo?.role === checkUserRole()}
                // hidden={isFM()}
                onClick={handleTargetDistributionModal}
                className="btn btn-md btn-primary me-4"
              >
                DISTRIBUTE
              </button>
            )}

            <button onClick={handleClick} className="btn btn-md btn-primary">
              <i className="fas fa-filter"></i>
              FILTER
            </button>

            {/* Filter Options */}
            <div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Formik
                  initialValues={filterData}
                  validationSchema={filterSchema}
                  onSubmit={onFilterSubmit}
                  enableReinitialize
                >
                  {(formProps: any) => (
                    <Form>
                      <div className="py-5 px-8">
                        <div className="w-250px mt-5 mb-8">
                          <label className="form-label fs-4 fw-bolder">
                            Grade
                          </label>
                          <Field
                            as="select"
                            name="grade"
                            className="form-select form-select-lg form-select-solid"
                          >
                            <option value="">All</option>
                            <option value="FCHW - Frontline CHW">
                              Frontline CHW
                            </option>
                            <option value="CHW Senior">CHW Senior</option>
                            <option value="CHW Junior">CHW Junior</option>
                            <option value="Community Health Mobilizer">
                              Community Health Mobilizer
                            </option>
                          </Field>
                          <div className="text-danger mt-5">
                            <ErrorMessage name="grade" />
                          </div>
                        </div>

                        <div className="w-250px mt-5 mb-8">
                          <label className="form-label fs-4 fw-bolder">
                            Field Status
                          </label>
                          <Field
                            id="fieldstatus"
                            as="select"
                            name="fieldstatus"
                            className="form-select form-select-light form-select-lg"
                          >
                            <option value="">All</option>
                            <option value="OnField">On Field</option>
                            <option value="OffField">Off Field</option>
                          </Field>
                          <div className="text-danger mt-5">
                            <ErrorMessage name="fieldstatus" />
                          </div>
                        </div>

                        <div className="w-250px mt-5">
                          <label className="form-label fs-4 fw-bolder">
                            Status
                          </label>
                          <Field
                            id="access"
                            as="select"
                            name="access"
                            className="form-select form-select-light form-select-lg"
                          >
                            <option value="">All</option>
                            <option value="true">Active</option>
                            <option value="false">Deactive</option>
                          </Field>
                          <div className="text-danger mt-5">
                            <ErrorMessage name="access" />
                          </div>
                        </div>
                      </div>

                      <div className="my-5 d-flex justify-content-center align-items-center">
                        <button
                          type="submit"
                          disabled={
                            !formProps.isValid || formProps.isSubmitting
                          }
                          className="w-250px btn btn-sm btn-primary"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Menu>
            </div>
            {/* End Here */}
          </div>
        )}
      </div>
      <FilterTile
        filterData={filterData}
        keyNames={{
          grade: 'Grade',
          access: 'Access',
          fieldstatus: 'Field Status',
        }}
        clearAll={() =>
          setFilterData({
            grade: '',
            access: '',
            fieldstatus: '',
          })
        }
      />
      {isSearchError || isCHWError ? (
        <TableErrorComponent
          errorMessage={
            // Error message handling to show custom message
            // incase the backend gives error whichs contains "{" or "["
            (isErrorMsgNotReadable(chwErrorMsg)
              ? getErrorMessage(errorStatus as number)
              : chwErrorMsg) ||
            (isErrorMsgNotReadable(searchErrorMsg)
              ? getErrorMessage(ErrorMsg?.response?.status as number)
              : searchErrorMsg)
          }
        />
      ) : (
        <div className="card-body py-3">
          <Table>
            <TableHeadRow>
              {tableHeadings.map((ele) => (
                <TableHead
                  width={
                    ele.toLowerCase() === 'rows' ||
                    ele.toLowerCase() === 'sl no'
                      ? 50
                      : ele.toLowerCase() === 'email'
                      ? 90
                      : 100
                  }
                  align={ele.toLowerCase() === 'rows' ? 'center' : 'left'}
                  key={ele}
                >
                  {ele}
                </TableHead>
              ))}
              <TableHead width={225}>ACTION</TableHead>
            </TableHeadRow>
            <TableBodyContainer
              isLoading={getCHWLoader || searchCHWLoader}
              isError={isSearchError || isCHWError}
              errorMessage={chwErrorMsg || searchErrorMsg}
            >
              {Array?.isArray(selectedUser) &&
                selectedUser?.map((item: any, index: any) => {
                  const fieldInfo = item?.fieldData?.fieldStatus;

                  const chwLocation =
                    item?.fieldData?.length === 0
                      ? 'No Data'
                      : item?.fieldData?.street +
                        ', ' +
                        item?.fieldData?.locality +
                        ', ' +
                        item?.fieldData?.district +
                        ', ' +
                        item?.fieldData?.state;

                  const facilityList = item?.facilityData?.map((d: any) => {
                    return {
                      name: d?.facilityFirstName + ' ' + d?.facilityLastName,
                      code: d?.facilityCode,
                    };
                  });

                  const facilityNames = facilityList
                    ?.map((d: any) => {
                      const name = d?.name;
                      return name
                        ? name.charAt(0).toUpperCase() + name.slice(1)
                        : '';
                    })
                    .join(', ');

                  const singleFacility = facilityList?.find(
                    (facility: any) => facility?.code === facilityCode
                  );
                  const chwFacilitiesTooltip =
                    facilityCode === ''
                      ? facilityNames
                      : singleFacility?.name.charAt(0).toUpperCase() +
                        singleFacility?.name?.slice(1);

                  const chwFacilities =
                    facilityCode === ''
                      ? facilityList?.length > 1
                        ? facilityNames?.split(',')[0] + ' ..'
                        : facilityNames?.split(',')[0]
                      : singleFacility?.name.charAt(0).toUpperCase() +
                        singleFacility?.name?.slice(1);

                  return (
                    <TableRow collapsible={false} key={index}>
                      <TableCell align="center">
                        <input
                          type="checkbox"
                          name={item?._id}
                          checked={item?.isChecked}
                          className="form-check-input"
                          onChange={handleCheckbox}
                        />
                      </TableCell>
                      <TableCell>
                        {currentPageNumber &&
                          (currentPageNumber - 1) * pageLimit + index + 1}
                      </TableCell>
                      <TableCell>
                        <CustomTooltip title={item?.chwId}>
                          <p>{_.startCase(item?.name)}</p>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell>
                        <span
                          className={`badge badge-light-${
                            fieldInfo === 'OnField' ? 'success' : 'danger'
                          } text-center `}
                        >
                          {fieldInfo === 'OnField' ? (
                            <CustomTooltip
                              sx={{
                                textTransform: 'capitalize',
                              }}
                              title={chwLocation}
                            >
                              <span className="border-none">On Field</span>
                            </CustomTooltip>
                          ) : (
                            <span className="border-none">Off Field</span>
                          )}
                        </span>
                      </TableCell>
                      <TableCell>{item?.demographicDetails?.telecom}</TableCell>
                      {/* <TableCell>                                   
                                                {item?.facilityData &&
                                                chwFacilities
                                                    ? chwFacilities
                                                    : "--"}
                                            </TableCell> */}

                      <TableCell>
                        <CustomTooltip
                          sx={{
                            textTransform: 'capitalize',
                          }}
                          title={chwFacilitiesTooltip}
                        >
                          <span className="border-none text-capitalize">
                            {item?.facilityData && chwFacilities
                              ? chwFacilities
                              : '--'}
                          </span>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell>
                        {item?.grade !== '' ? item?.grade : 'N/A'}
                      </TableCell>
                      <TableCell>{item?.demographicDetails?.email}</TableCell>
                      <TableCell>
                        {
                          // facilityCode ?
                          <Link
                            state={{ item }}
                            to={{
                              pathname: `/chw/overview`,
                              search: `?chwId=${item?.chwId}`,
                            }}
                            className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                          >
                            DETAILS
                          </Link>
                          //  : (
                          //     <button
                          //         onClick={() =>
                          //             warnUser()
                          //         }
                          //         className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                          //     >
                          //         DETAILS
                          //     </button>
                          // )
                        }

                        {canReassign && (
                          <button
                            onClick={() => {
                              setCHWName(item?.name);
                              setCHWId(item?.chwId);
                              handleAssignModal();
                            }}
                            className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                          >
                            REASSIGN
                          </button>
                        )}
                        {canDelete && (
                          <button
                            onClick={() => {
                              setCHWName(item?.name);
                              setCHWId(item?.chwId);
                              handleRemoveModal();
                            }}
                            className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBodyContainer>
          </Table>
          {!isSearchError && !isCHWError && (
            <Pagination
              dataCount={totalGetCHWPages}
              handleCallback={(val: number | string) => {
                setPageLimit(val);
              }}
              handleNext={() =>
                handlePageNumber((prevPageNumber: number) => prevPageNumber + 1)
              }
              handlePrev={() =>
                handlePageNumber((prevPageNumber: number) => prevPageNumber - 1)
              }
              nextDisabled={currentPageNumber === totalPages}
              pageNumber={currentPageNumber}
              pageLimit={pageLimit}
              prevDisabled={currentPageNumber === 1}
            />
          )}
        </div>
      )}
    </div>
  );
};

export { CHWTable };
