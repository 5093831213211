import * as Yup from 'yup';
import { CHWOnboardingTypes } from '../../../types/CHWOnboarding.types';

import {
    SUPPORTED_FORMATS,
    passwordValidator,
    userSchema,
    addressSchema,
    phoneValidator,
    stringValidator,
    alphaNumericValidator,
    numberValidator,
    SUPPORTED_FORMATS_DOC,
} from '../../../common/validators';
import type { Prettify } from '../../../common';
import dayjs from 'dayjs';

export const initsForCHWOnboarding: Prettify<CHWOnboardingTypes> = {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    photo: '',

    // Second API
    gender: '',
    grade: '',
    birthDate: '',
    age: '',
    languages: '',
    preferredLanguage: '',
    idProofName: '',
    proofLocation: '',
    id: '',
    addressSiteName: '',
    floorNumber: '',
    streetName: '',
    streetNumber: '',
    district: '',
    postalCode: '',
    town: '',
    state: '',
    country: '',
};

export const createAccountSchemas = [
    // Step 1
    Yup.object({
        firstName: Yup.string()
            .matches(stringValidator, 'Please enter valid first name')
            .required()
            .label('First Name'),
        middleName: Yup.string()
            .matches(stringValidator, 'Please enter valid middle name')
            .label('Middle Name'),
        lastName: Yup.string()
            .matches(stringValidator, 'Please enter valid last name')
            .required()
            .label('Last Name'),
        phoneNumber: Yup.string()
            .matches(
                phoneValidator,
                'Phone Number must start with 6, 7, 8, or 9, contain only numbers, and be exactly 10 digits'
            )
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits')
            // .oneOf([Yup.ref("userName"), null], "User Name don't match!")
            .required('Phone Number is Required!'),
        email: Yup.string()
            .email('Please enter a vaild email')
            .trim()
            .required()
            .label('Email Id'),
    }),
    // Step 2
    Yup.object({
        photo: Yup.mixed()
            .nullable()
            .required()
            .label('Photo')
            .test(
                'FILE_SIZE',
                'Uploaded file is too big.',
                (value) => !value || (value && value.size <= 5 * 1024 * 1024) // 5 MB
            )
            .test(
                'FILE_FORMAT',
                'Uploaded file has unsupported format.',
                (value) =>
                    !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            ),
        password: Yup.string()
            .matches(
                passwordValidator,
                'Must Contain 8 Characters, atleast( One Uppercase, One Lowercase, One Number and one special case Character)'
            )
            .required('Password is Required!'),
        grade: Yup.string().required().label('Grade'),
    }),
    Yup.object({
        // Step 3
        gender: Yup.string().required().label('Gender'),
        birthDate: Yup.date()
            .required()
            .label('Birth Date')
            .test(
                'age-match',
                'Age does not match birth date',
                function (value) {
                    const { age } = this.parent;
                    if (!value || age === undefined) {
                        return true;
                    }
                    return dayjs().diff(dayjs(value), 'year') === age;
                }
            ),
        age: Yup.number()
            .moreThan(18, 'You must be over the age of 18 to register')
            .lessThan(100, 'Are you really that old?')
            .typeError('The accepted value is only number')
            .label('Age'),
        languages: Yup.array()
            .min(1, "You can't leave this blank.")
            .required("You can't leave this blank.")
            .nullable()
            .label('Language'),
        preferredLanguage: Yup.string().required().label('Preferred Language'),
    }),
    Yup.object({
        // Step 4
        idProofName: Yup.string().required().label('Proof Name'),
        id: Yup.mixed().required().label('Id Proof'),
        addressSiteName: Yup.string()
            .matches(
                alphaNumericValidator,
                'Please enter valid address site name'
            )
            .required()
            .label('Address Site'),
        proofLocation: Yup.mixed()
            .nullable()
            .required('Please upload a pdf file')
            .label('Proof')
            .test(
                'FILE_SIZE',
                'Uploaded file is too big.',
                (value) => !value || (value && value.size <= 5 * 1024 * 1024)
            )
            .test(
                'FILE_FORMAT',
                'Uploaded file has unsupported format.',
                (value) => {
                    if (!value) return true;
                    return value && SUPPORTED_FORMATS_DOC.includes(value?.type);
                }
            ),
    }),
    Yup.object({
        floorNumber: Yup.string()
            .matches(
                numberValidator,
                'Floor Number is not valid, must be only digits'
            )
            .label('Floor Number'),
        streetName: Yup.string()
            .matches(alphaNumericValidator, 'Please enter valid street name')
            .required()
            .label('Street Name'),
        streetNumber: Yup.string()
            .matches(
                numberValidator,
                'Street Number is not valid, must be only digits'
            )
            .label('Street Number'),
        district: Yup.string()
            .matches(alphaNumericValidator, 'Please enter valid district name')
            .required()
            .label('District'),
        postalCode: Yup.string()
            .matches(
                numberValidator,
                'Postal code is not valid, must be only digits'
            )
            .min(6, 'Must be exactly 6 digits')
            .max(6, 'Must be exactly 6 digits')
            .required()
            .label('Postal Code'),
        town: Yup.string()
            .matches(alphaNumericValidator, 'Please enter valid town')
            .required('Please enter valid town name')
            .label('Town'),
        state: Yup.string().required().label('State'),
        country: Yup.string().required().label('Country'),
    }),
];
