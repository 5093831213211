import * as Yup from 'yup';
import { Menu } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../../components/Table';
import Modal from '../../../components/Modal';
import useMutateRequest from '../../../hooks/getMutation.query';
import useSearchParams from '../../../hooks/useSearchParams';
import { getPatientConsultations } from '../../../services/patient/PatientManagement.hook';
import Pagination from '../../../components/Pagination';
import TableErrorComponent from '../../../components/Error/TableError';
import { Permissions, hasAccessToSubModule } from '../../../rbac';
import FilterTile from '../../../components/filter-tile';
import {
  convertUtcTimestampToLocalDate,
  // dateFormatter,
} from '../../../common/utils';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../common/utils/genericErrors';

const filterSchema = Yup.object().shape({
  status: Yup.string().label('Status'),
});

const AppointmentTable = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterData, setFilterData]: any = useState({
    status: '',
    doctorName: '',
    consultationType: '',
    specialization: '',
  });

  const { pId: id }: any = useSearchParams();

  const {
    data,
    isLoading,
    error,
    mutate,
    isError: isPatientConsultationError,
  }: any = useMutateRequest((data: any) =>
    getPatientConsultations({
      uhid: id,
      page: currentPageNumber,
      limit: pageLimit,
      status: filterData?.status,
      doctorName: filterData?.doctorName,
      consultationType: filterData?.consultationType,
      specialization: filterData?.specialization,
    })
  );
  const patientConsultationDetails: any = data?.data?.data?.consultation;
  const doctorNames: any = data?.data?.data?.doctor;
  const totalPatientConsultationEntites = data?.data?.results;

  const totalPatientConsultationPages = Math.ceil(
    totalPatientConsultationEntites / pageLimit
  );
  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error]
  );
  //   const successMsg = data?.data?.data?.message;

  const getData = () => {
    try {
      const formData = new FormData();
      formData.append('uhid', id);
      formData.append('status', filterData?.status);
      formData.append('doctorName', filterData?.doctorName);
      mutate(formData, {
        onSuccess: (data: any) => {},
        onError: (error: any) => {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id) getData();
  }, [id, currentPageNumber, filterData]);

  // Begins Menu Here
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Ends Here
  //   const handleModal = () => {
  //     setIsOpen(!isOpen);
  //   };

  const onFilterSubmit = (values: any, onSubmitProps: any) => {
    try {
      const payload = {
        status: values.status,
        doctorName: values.doctorName,
        consultationType: values.consultationType,
        specialization: values.specialization,
      };
      setFilterData(payload);
    } catch (error) {
      console.log(error);
      onSubmitProps.resetForm();
    }
  };

  const clearData = () => {
    const payload = {
      status: '',
      doctorName: '',
      consultationType: '',
      specialization: '',
    };
    setFilterData(payload);
  };

  const downloadPresciption = () =>
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          Presciption Downloaded
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
      }
    );

  // Role settings
  const canDownload = hasAccessToSubModule(
    'LIST_PATIENT',
    'LIST_PATIENT_APPOINTMENT',
    Permissions.export_button
  );
  return (
    <div className="card text-dark">
      <Toaster />
      <Modal
        open={isOpen}
        styles={{
          width: '650px',
        }}
      >
        <div></div>
        {/* <PatientExport onClose={handleModal} /> */}
      </Modal>
      <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
        <div className="d-flex align-items-center justify-content-center h-50px">
          <h1>APPOINTMENTS LIST</h1>
          <i
            onClick={() => clearData()}
            className="fas fa-redo fs-3 cursor-pointer ms-5"
          ></i>
        </div>
        <div className="card-toolbar">
          <button onClick={handleClick} className="btn btn-sm btn-primary">
            <i className="fas fa-filter"></i>
            FILTER
          </button>

          {/* Filter Options */}
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Formik
                initialValues={filterData}
                validationSchema={filterSchema}
                onSubmit={onFilterSubmit}
                enableReinitialize
              >
                {(formProps: any) => (
                  <Form>
                    <div className="py-5 px-8">
                      <div className="w-250px mt-5 mb-5">
                        <label className="form-label fs-4 fw-bolder">
                          DOCTOR NAME
                        </label>

                        <Field
                          as="select"
                          name="doctorName"
                          className="form-select form-select-lg form-select-solid"
                        >
                          <option value="">All</option>
                          {doctorNames?.map((item: any, index: any) => {
                            return (
                              <>
                                <option value={item}>{item}</option>
                              </>
                            );
                          })}
                        </Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="doctorName" />
                        </div>
                      </div>
                      <div className="w-250px mt-5 mb-5">
                        <label className="form-label fs-4 fw-bolder">
                          CONSULTATION TYPE
                        </label>
                        <Field
                          as="select"
                          name="consultationType"
                          className="form-select form-select-lg form-select-solid"
                        >
                          <option value="">All</option>
                          <option value="physical">Physical</option>
                          <option value="online">Online</option>
                        </Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="consultationType" />
                        </div>
                      </div>
                      <div className="w-250px mt-5 mb-5">
                        <label className="form-label fs-4 fw-bolder">
                          SPECIALIZATION
                        </label>
                        <Field
                          as="select"
                          name="specialization"
                          className="form-select form-select-lg form-select-solid"
                        >
                          <option value="">All</option>
                          <option value="General">General</option>
                          <option value="Cardiology">Cardiology</option>
                          <option value="Gynecology">Gynecology</option>
                          <option value="Dermatology">Dermatology</option>
                          <option value="Orthopedic">Orthopedic</option>
                          <option value="Dental">Dental</option>
                          <option value="Eye">Eye</option>
                        </Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="specialization" />
                        </div>
                      </div>
                      <div className="w-250px mt-5 mb-5">
                        <label className="form-label fs-4 fw-bolder">
                          STATUS
                        </label>
                        <Field
                          as="select"
                          name="status"
                          className="form-select form-select-lg form-select-solid"
                        >
                          <option value="All">All</option>
                          <option value="Scheduled">Scheduled</option>
                          <option value="Done">Done</option>
                          <option value="InProgress">In Progress</option>
                          <option value="Cancelled">Cancelled</option>
                          <option value="Rescheduled">Rescheduled</option>
                        </Field>
                        <div className="text-danger mt-5">
                          <ErrorMessage name="status" />
                        </div>
                      </div>
                    </div>

                    <div className="my-5 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        disabled={!formProps.isValid || formProps.isSubmitting}
                        className="w-250px btn btn-sm btn-primary"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Menu>
          </div>
          {/* End Here */}
        </div>
      </div>

      <FilterTile
        filterData={filterData}
        keyNames={{
          status: 'Status',
          doctorName: 'Doctor name',
          consultationType: 'Consultation type',
          specialization: 'Specialization',
        }}
        clearAll={() =>
          setFilterData({
            status: '',
            doctorName: '',
            consultationType: '',
            specialization: '',
          })
        }
      />
      {isPatientConsultationError ? (
        <TableErrorComponent errorMessage={errorMsg} />
      ) : (
        <div className="card-body py-3">
          <Table>
            <TableHeadRow>
              <TableHead width={50} align="center">
                SL NO
              </TableHead>
              <TableHead width={140}>DOCTOR NAME</TableHead>
              <TableHead width={140}>CONSULTATION TYPE</TableHead>
              <TableHead width={140}>STATUS</TableHead>
              <TableHead width={120}>SPECIALIZATION</TableHead>
              <TableHead width={120}>DATE</TableHead>
              <TableHead width={120}>TIME SLOTS / TOKEN NO</TableHead>
              <TableHead width={120}>SCHEDULED BY</TableHead>
              <TableHead width={100}>PRESCRIPTION</TableHead>
            </TableHeadRow>

            {isPatientConsultationError === true ? (
              <tbody
                style={{
                  height: '400px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  left: '400px',
                  top: '200px',
                }}
              >
                <h1 className="text-danger">
                  {isPatientConsultationError
                    ? errorMsg
                    : 'Something went wrong!!'}
                </h1>
              </tbody>
            ) : Array?.isArray(patientConsultationDetails) ? (
              <TableBodyContainer
                isLoading={isLoading}
                isError={isPatientConsultationError}
                errorMessage={errorMsg}
              >
                {patientConsultationDetails?.map((item: any, index: any) => {
                  return (
                    <TableRow collapsible={false}>
                      <TableCell align="center">
                        {currentPageNumber &&
                          (currentPageNumber - 1) * pageLimit + index + 1}
                      </TableCell>
                      <TableCell>{item?.doctorName?.name}</TableCell>
                      <TableCell>
                        <span
                          className={`badge badge-light-${
                            item?.consultationType === 'physical consultation'
                              ? 'success'
                              : 'info'
                          } text-center `}
                        >
                          {item?.consultationType}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className={`badge badge-light-${
                            item?.consultationStatus === 'Scheduled'
                              ? 'primary'
                              : item?.consultationStatus === 'Cancelled'
                              ? 'danger'
                              : item?.consultationStatus === 'In Progress'
                              ? 'info'
                              : item?.consultationStatus === 'Rescheduled'
                              ? 'secondary'
                              : item?.consultationStatus === 'Done'
                              ? 'success'
                              : item?.consultationStatus === 'Payment Pending'
                              ? 'dark'
                              : null
                          } text-center `}
                        >
                          {item?.consultationStatus}
                        </span>
                      </TableCell>
                      <TableCell>{item?.specialization}</TableCell>
                      <TableCell>
                        {item?.taskDate
                          ? convertUtcTimestampToLocalDate(
                              item?.taskDate,
                              'MMMM D, YYYY'
                            )
                          : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {item?.consultationType === 'physical consultation'
                          ? item?.token
                          : item?.taskTime}
                      </TableCell>
                      <TableCell hover>
                        <Link
                          style={{
                            color: 'black',
                            cursor: 'pointer',
                          }}
                          to={`/chw/overview?chwId=${item?.consultationScheduledBy?.schedulerId}`}
                        >
                          <span className="text-hover-primary">
                            {item?.consultationScheduledBy?.schedulerName}
                          </span>
                        </Link>
                      </TableCell>
                      <TableCell>
                        {item?.consultationStatus === 'Done' ? (
                          <>
                            <a
                              hidden={!canDownload}
                              href={
                                item?.prescription?.prescriptionStatus
                                  ? item?.prescription?.prescriptionFile
                                  : item?.prescription?.glassPrescriptionFile
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i
                                onClick={downloadPresciption}
                                style={{
                                  cursor: 'pointer',
                                }}
                                className="fs-2 fas fa-arrow-circle-down text-hover-primary"
                              ></i>
                            </a>
                          </>
                        ) : (
                          <i className="fs-2 fas fa-times-circle text-hover-danger"></i>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBodyContainer>
            ) : (
              <tbody
                style={{
                  height: '400px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  left: '400px',
                  top: '200px',
                }}
              >
                <h1 className="text-danger">{patientConsultationDetails}</h1>
              </tbody>
            )}
          </Table>

          {/* Pagination */}

          {!isPatientConsultationError && (
            <Pagination
              dataCount={totalPatientConsultationEntites}
              handleCallback={(val: number | string) => {
                setPageLimit(+val);
              }}
              handleNext={() =>
                setCurrentPageNumber(
                  (prevPageNumber: number) => prevPageNumber + 1
                )
              }
              handlePrev={() =>
                setCurrentPageNumber(
                  (prevPageNumber: number) => prevPageNumber - 1
                )
              }
              nextDisabled={currentPageNumber === totalPatientConsultationPages}
              pageNumber={currentPageNumber}
              pageLimit={pageLimit}
              prevDisabled={currentPageNumber === 1}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AppointmentTable;
