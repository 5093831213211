import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import useMutateRequest from '../../../../../hooks/getMutation.query';

import {
  Table,
  TableBodyContainer,
  TableCell,
  TableHead,
  TableHeadRow,
  TableRow,
} from '../../../../../components/Table';
import {
  createIndent,
  updateIndent,
} from '../../../../../services/supply-chain/indent/Indent.services';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../../../common/utils/genericErrors';

const ModifyIndentTable = ({ productDetails, setProductDetails }: any) => {
  const facilityCode = localStorage.getItem('facilityCode');
  const navigate = useNavigate();
  const tableNames = [
    'PRODUCT NAME',
    'GENERIC NAME',
    'MANUFACTURER NAME',
    'NO OF STRIPS',
    'UNIT PER STRIP',
    'TOTAL UNITS',
    'ACTION',
  ];
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const indentId = searchParams.get('id');
  const tableTitle = status === 'Draft' ? 'Edit' : 'Create';
  const [spinner, setSpinner] = useState<boolean>(false);

  const {
    isError: isCreateError,
    error: createError,
    mutate: createMutate,
    isLoading: isCreateLoading,
    isSuccess,
  }: any = useMutateRequest((data: any) => createIndent(data));

  const {
    isError,
    error,
    mutate: updateMutate,
    isLoading: isUpdateLoading,
  }: any = useMutateRequest((data: any) => updateIndent(data));

  const errorMsg = useMemo(
    () =>
      isErrorMsgNotReadable(error?.response?.data?.message)
        ? getErrorMessage(error?.response?.status)
        : error?.response?.data?.message,
    [error]
  );
  const successToastMsg = () => {
    toast.success(
      (t) => (
        <span
          data-testid="success-msg"
          className="p-2 fs-4 d-flex align-items-center justify-content-center "
        >
          {/* {successMsg} */}
          Successfully created indent
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'success-toast',
      }
    );
  };

  const errorToastMsg = useCallback(() => {
    toast.error(
      (t) => (
        <span
          data-testid="error-msg"
          className="p-2 fs-4 d-flex align-items-center justify-content-center w-100"
        >
          {errorMsg}
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'error-toast',
      }
    );
  }, [errorMsg]);

  const createErrorToastMsg = useCallback(() => {
    toast.error(
      (t) => (
        <span
          data-testid="error-msg"
          className="p-2 fs-4 d-flex align-items-center justify-content-center w-100"
        >
          {createError?.response?.data?.message}
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'error-toast',
      }
    );
  }, [createError?.response?.data?.message]);
  const deleteProduct = (product: any) => {
    setProductDetails((prevValue: any) =>
      prevValue?.filter(
        (productItem: any) => productItem?.productName !== product?.productName
      )
    );
  };

  const getBtnText = (str: string) => {
    if (str === 'Submitted') {
      if (tableTitle === 'Create') {
        const payload = {
          indentStatus: str,
          facilityCode,
          products: productDetails,
        };
        createMutate(payload, {
          onSuccess(data: any) {
            setSpinner(true);
            setTimeout(() => {
              setSpinner(false);
              navigate('/indent/indent-list');
            }, 1500);
          },
          onError(error: any) {
            console.log(error);
          },
        });
      }
      if (tableTitle === 'Edit') {
        const payload = {
          indentStatus: str,
          indentId: indentId,
          facilityCode,
          products: productDetails,
        };
        updateMutate(payload, {
          onSuccess(data: any) {
            setSpinner(true);
            setTimeout(() => {
              setSpinner(false);
              navigate('/indent/indent-list');
            }, 1500);
          },
          onError(error: any) {
            console.log(error);
          },
        });
      }
    }
    if (str === 'Draft') {
      if (tableTitle === 'Create') {
        const payload = {
          indentStatus: str,
          facilityCode,
          products: productDetails,
        };
        createMutate(payload, {
          onSuccess(data: any) {
            setSpinner(true);
            setTimeout(() => {
              setSpinner(false);
              navigate('/indent/indent-list');
            }, 1500);
          },
          onError(error: any) {
            console.log(error);
          },
        });
      }
      if (tableTitle === 'Edit') {
        const payload = {
          indentStatus: str,
          indentId: indentId,
          facilityCode,
          products: productDetails,
        };
        updateMutate(payload, {
          onSuccess(data: any) {
            setSpinner(true);
            setTimeout(() => {
              setSpinner(false);
              navigate('/indent/indent-list');
            }, 1500);
          },
          onError(error: any) {
            console.log(error);
          },
        });
      }
    }
  };

  useEffect(() => {
    isSuccess && successToastMsg();
  }, [isSuccess]);

  useEffect(() => {
    isError && errorToastMsg();
  }, [isError, errorToastMsg]);

  useEffect(() => {
    isCreateError && createErrorToastMsg();
  }, [isCreateError, createErrorToastMsg]);

  return (
    <>
      <Toaster />
      <section>
        <div className="card text-dark">
          <div className="card-header border-0 d-flex justify-content-between align-items-center pt-5">
            <div className="d-flex align-items-center justify-content-center h-50px">
              <h1>{tableTitle} LIST</h1>
            </div>
            <div className="card-toolbar"></div>
          </div>

          <div className="card-body py-3">
            <Table data-testid="indent-table">
              <TableHeadRow>
                <TableHead width={140} align="center">
                  SL NO
                </TableHead>
                {tableNames?.map((name) => {
                  return (
                    <TableHead width={140} key={name} align="left">
                      {name}
                    </TableHead>
                  );
                })}
              </TableHeadRow>

              <TableBodyContainer
                isLoading={spinner || isCreateLoading || isUpdateLoading}
                isError={isError}
              >
                {Array?.isArray(productDetails) &&
                  productDetails?.map((item: any, idx: number) => {
                    const totalUnits = item?.noOfstrips * item?.unitPerStrip;
                    return (
                      <TableRow collapsible={false} key={idx}>
                        <TableCell align="center">{idx + 1}</TableCell>

                        <TableCell hover>
                          {item?.productName ? item?.productName : 'N/A'}
                        </TableCell>

                        <TableCell>
                          {item?.genericName ? item?.genericName : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {item?.manufacturerName
                            ? item?.manufacturerName
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {item?.noOfstrips ? item?.noOfstrips : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {item?.unitPerStrip ? item?.unitPerStrip : 'N/A'}
                        </TableCell>
                        <TableCell>{totalUnits}</TableCell>
                        <TableCell>
                          <i
                            onClick={() => deleteProduct(item)}
                            className="fa-sharp fa-solid fa-trash link-primary"
                          ></i>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBodyContainer>
            </Table>
            <section className="d-flex justify-content-end w-100">
              <div className="fv-row mb-10">
                <button
                  onClick={() => setProductDetails([])}
                  className="btn btn-light"
                >
                  Cancel
                </button>
              </div>
              <div className="ms-5 fv-row mb-10">
                <button
                  onClick={() => getBtnText('Submitted')}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
              <div className="ms-5 fv-row mb-10">
                <button
                  onClick={() => getBtnText('Draft')}
                  className="btn btn-primary"
                >
                  Save as draft
                </button>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default ModifyIndentTable;
