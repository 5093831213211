/**
 *  Logged in User level RBAC
 */
import { Permissions, chwTablePermissions, tablePermissions } from './types';

const { create, filter_button, export_button, read, search_bar, update } =
    Permissions;

export const commonResource = {
    roleId: {
        roleName: 'FACILITY_MANAGER',
        description: 'This role specifies the Admin users',
    },
    platform: 'WEB_PORTAL',
    sidebars: [],
    modules: [
        {
            moduleName: 'Visulise the insights on various modules',
            identifier: 'DASHBOARD',
            submoduleAvailable: false,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'Visalise based on Tables and Charts at facility level',
            identifier: 'PERFORMANCE_MONITORING',
            submoduleAvailable: false,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'Report',
            identifier: 'LIST_REPORT',
            submoduleAvailable: false,
            permissions: [read, export_button],
            subModules: [],
        },
        {
            moduleName: 'Create course content',
            identifier: 'CREATE_COURSE',
            submoduleAvailable: false,
            permissions: [read, create],
            subModules: [],
        },
        {
            moduleName: 'create course category & subcategory',
            identifier: 'CREATE_CATEGORY_SUBCATEGORY',
            submoduleAvailable: false,
            permissions: [read, create],
            subModules: [],
        },
        {
            moduleName: 'List of course category & subcategory',
            identifier: 'LIST_CATEGORY_SUBCATEGORY',
            submoduleAvailable: false,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'list of courses',
            identifier: 'LIST_COURSE',
            submoduleAvailable: true,
            permissions: [read, search_bar, filter_button],
            subModules: [
                {
                    subModuleName: 'Container for the Course Overview',
                    identifier: 'CONTAINER_COURSE',
                    permissions: [read],
                },
                {
                    subModuleName:
                        'Attach/Modify content to the existing course',
                    identifier: 'ATTACH_MODIFY_CONTENT',
                    permissions: [read, update],
                },
                {
                    subModuleName: 'See course content',
                    identifier: 'SEE_COURSE_CONTENT',
                    permissions: [read],
                },
            ],
        },
        {
            moduleName: 'list of tasks',
            identifier: 'LIST_TASK',
            submoduleAvailable: true,
            permissions: tablePermissions,
            subModules: [
                {
                    subModuleName: 'Container Overview',
                    identifier: 'CONTAINER_TASK',
                    permissions: [read],
                },
                {
                    subModuleName: 'Task Details',
                    identifier: 'TASK_DETAILS',
                    permissions: [read],
                },
            ],
        },
        {
            moduleName: 'List of unassigned tasks',
            identifier: 'LIST_UNASSIGNED_TASK',
            submoduleAvailable: false,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'Task Insights',
            identifier: 'INSIGHTS_TASK',
            submoduleAvailable: false,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'Ikure Connect',
            identifier: 'LIST_IKURE_CONNECT',
            submoduleAvailable: false,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'list of chws',
            identifier: 'LIST_CHW',
            submoduleAvailable: true,
            permissions: chwTablePermissions,
            subModules: [
                {
                    subModuleName: 'CHW Overview',
                    identifier: 'CONTAINER_CHW',
                    permissions: [read],
                },
                {
                    subModuleName: 'CHW Insights',
                    identifier: 'INSIGHTS_CHW',
                    permissions: [read],
                },
                {
                    subModuleName: 'Task List',
                    identifier: 'TASK_LIST',
                    permissions: tablePermissions,
                },
                {
                    subModuleName: 'Track CHW attendance',
                    identifier: 'LIST_ATTENDANCE',
                    permissions: tablePermissions,
                },
                {
                    subModuleName:
                        'Perf monitor visalize based on Tables & Charts at individual level',
                    identifier: 'VISALIZE_CHART_TABLE',
                    permissions: tablePermissions,
                },
            ],
        },
        {
            moduleName: 'onboard a chw',
            identifier: 'ONBOARD_CHW',
            submoduleAvailable: false,
            permissions: [read, create],
            subModules: [],
        },
        {
            moduleName: 'see list of batch list',
            identifier: 'LIST_BATCH',
            submoduleAvailable: true,
            permissions: tablePermissions,
            subModules: [
                {
                    subModuleName: 'Batch Container',
                    identifier: 'CONTAINER_BATCH',
                    permissions: [read],
                },
                {
                    subModuleName:
                        'see list of healthcards that are present in batch',
                    identifier: 'LIST_HEALTHCARDS',
                    permissions: tablePermissions,
                },
                {
                    subModuleName: 'Batch Details',
                    identifier: 'DETAILS_BATCH',
                    permissions: [read],
                },
            ],
        },
        {
            moduleName: 'Unused healthcard list',
            identifier: 'LIST_UNUSED_HEALTHCARDS',
            submoduleAvailable: false,
            permissions: tablePermissions,
            subModules: [],
        },
        {
            moduleName: 'Generate the healthcard',
            identifier: 'GENERATE_HEALTHCARDS',
            submoduleAvailable: false,
            permissions: [create, read],
            subModules: [],
        },
        {
            moduleName: 'list of patients at facility',
            identifier: 'LIST_PATIENT',
            submoduleAvailable: true,
            permissions: tablePermissions,
            subModules: [
                {
                    subModuleName: 'Patient Overview',
                    identifier: 'CONTAINER_PATIENT',
                    permissions: [read],
                },
                {
                    subModuleName: 'Health Overview',
                    identifier: 'DETAILS_PATIENT',
                    permissions: [read],
                },
                {
                    subModuleName: 'Appointment list',
                    identifier: 'LIST_PATIENT_APPOINTMENT',
                    permissions: tablePermissions,
                },
                {
                    subModuleName: 'Vitals details',
                    identifier: 'DETAILS_VITALS',
                    permissions: [read],
                },
                {
                    subModuleName: 'Tasks',
                    identifier: 'LIST_PATIENT_TASKS',
                    permissions: tablePermissions,
                },
                {
                    subModuleName: 'Patient health conditions',
                    identifier: 'HEALTH_CONDITION',
                    permissions: tablePermissions,
                },
                {
                    subModuleName: 'Assigned focused groups',
                    identifier: 'LIST_ASSIGNED_FOCUSED_GROUPS',
                    permissions: tablePermissions,
                },
                {
                    subModuleName: 'Update his details',
                    identifier: 'UPDATE_PATIENT_PROFILE',
                    permissions: [update],
                },
            ],
        },
        {
            moduleName:
                'Appointment list of all the patients at facility level',
            identifier: 'LIST_FACILITY_LEVEL_PATIENT_APPOINTMENT',
            submoduleAvailable: false,
            permissions: tablePermissions,
            subModules: [],
        },
        {
            moduleName: 'Form builder',
            identifier: 'FORM_BUILDER',
            submoduleAvailable: false,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'list of focused groups',
            identifier: 'LIST_FOCUSED_GROUP',
            submoduleAvailable: true,
            permissions: tablePermissions,
            subModules: [
                {
                    subModuleName: 'Focused groups Overview',
                    identifier: 'CONTAINER_FOCUSED_GROUP',
                    permissions: [read],
                },
                {
                    subModuleName: 'List Form Entries',
                    identifier: 'LIST_FORM_ENTRIES',
                    permissions: [read],
                },
                {
                    subModuleName: 'Beneficary list',
                    identifier: 'LIST_BENEFICIARY',
                    permissions: tablePermissions,
                },
                {
                    subModuleName: 'Beneficary list of vitals',
                    identifier: 'LIST_BENEFICIARY_VITALS',
                    permissions: [read, update],
                },
                {
                    subModuleName: 'Beneficary list of entries',
                    identifier: 'LIST_BENEFICIARY_ENTRIES',
                    permissions: [read],
                },
                {
                    subModuleName: 'Add Vitals to a specific visit',
                    identifier: 'FOCUSED_GROUP_VITALS',
                    permissions: [create, update],
                },
                {
                    subModuleName:
                        'List of Forms specific to the focused group',
                    identifier: 'LIST_FORMS',
                    permissions: [read, create],
                },
            ],
        },
        {
            moduleName: 'create a focused groups',
            identifier: 'CREATE_FOCUSED_GROUP',
            submoduleAvailable: false,
            permissions: [create, read],
            subModules: [],
        },
        {
            moduleName: 'list of courses',
            identifier: 'LIST_INDENT',
            submoduleAvailable: true,
            permissions: [read],
            subModules: [
                {
                    subModuleName: 'Details of a specific indent',
                    identifier: 'INDENT_DETAILS',
                    permissions: [read],
                },
                {
                    subModuleName: 'Modify a spcific indent',
                    identifier: 'MODIFY_INDENT',
                    permissions: [read],
                },
                {
                    subModuleName: 'create a po',
                    identifier: 'CREATE_PURCHASE_ORDER',
                    permissions: [read],
                },
            ],
        },
        {
            moduleName: 'list of purchase orders',
            identifier: 'LIST_PURCHASE_ORDER',
            submoduleAvailable: true,
            permissions: [read],
            subModules: [
                {
                    subModuleName: 'Details of a specific po',
                    identifier: 'PURCHASE_ORDER_DETAILS',
                    permissions: [read],
                },
                {
                    subModuleName: 'create GRN',
                    identifier: 'CREATE_GRN',
                    permissions: [create],
                },
            ],
        },
        {
            moduleName: 'list of GRN',
            identifier: 'LIST_GRN',
            submoduleAvailable: false,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'Master products ',
            identifier: 'LIST_MASTER_PRODUCT',
            submoduleAvailable: true,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'list of CHW Stock',
            identifier: 'LIST_CHW_STOCK',
            submoduleAvailable: false,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'list of Facility Stock',
            identifier: 'LIST_FACILITY_STOCK',
            submoduleAvailable: false,
            permissions: [read],
            subModules: [],
        },
        {
            moduleName: 'list of sales order',
            identifier: 'LIST_SALES_ORDER',
            submoduleAvailable: true,
            permissions: [read],
            subModules: [
                {
                    subModuleName: 'sales order details',
                    identifier: 'SALE_ORDER_DETAILS',
                    permissions: [read],
                },
            ],
        },
    ],
};

export const doctorResource = {
    roleId: {
        roleName: 'Doctor',
        description: 'This role specifies the doctors',
    },
    platform: 'WEB_PORTAL',
    sidebars: [],
    modules: [
        // {
        //     moduleName: 'list of patients at facility',
        //     identifier: 'LIST_PATIENT',
        //     submoduleAvailable: true,
        //     permissions: tablePermissions,
        //     subModules: [
        //         {
        //             subModuleName: 'Patient Overview',
        //             identifier: 'CONTAINER_PATIENT',
        //             permissions: [read],
        //         },
        //         {
        //             subModuleName: 'Health Overview',
        //             identifier: 'DETAILS_PATIENT',
        //             permissions: [read],
        //         },
        //         {
        //             subModuleName: 'Appointment list',
        //             identifier: 'LIST_PATIENT_APPOINTMENT',
        //             permissions: tablePermissions,
        //         },
        //         {
        //             subModuleName: 'Vitals details',
        //             identifier: 'DETAILS_VITALS',
        //             permissions: [read],
        //         },
        //         {
        //             subModuleName: 'Tasks',
        //             identifier: 'LIST_PATIENT_TASKS',
        //             permissions: tablePermissions,
        //         },
        //         {
        //             subModuleName: 'Patient health conditions',
        //             identifier: 'HEALTH_CONDITION',
        //             permissions: tablePermissions,
        //         },
        //         {
        //             subModuleName: 'Assigned focused groups',
        //             identifier: 'LIST_ASSIGNED_FOCUSED_GROUPS',
        //             permissions: tablePermissions,
        //         },
        //         {
        //             subModuleName: 'Update his details',
        //             identifier: 'UPDATE_PATIENT_PROFILE',
        //             permissions: [update],
        //         },
        //     ],
        // },
        {
            moduleName:
                'Appointment list of all the patients at facility level',
            identifier: 'LIST_FACILITY_LEVEL_PATIENT_APPOINTMENT',
            submoduleAvailable: false,
            permissions: tablePermissions,
            subModules: [],
        },
        {
            moduleName: 'list of doctors',
            identifier: 'LIST_DOCTOR',
            submoduleAvailable: false,
            permissions: tablePermissions,
            subModules: [
                {
                    subModuleName: 'Doctor Overview',
                    identifier: 'CONTAINER_DOCTOR',
                    permissions: [read],
                },
            ],
        },
        {
            moduleName:
                'Appointment list of all the patients at facility level',
            identifier: 'LIST_FACILITY_LEVEL_PATIENT_APPOINTMENT_DETAILS',
            submoduleAvailable: false,
            permissions: tablePermissions,
            subModules: [],
        },
        {
            moduleName: 'list of patients at facility',
            identifier: 'LIST_PATIENT',
            submoduleAvailable: true,
            permissions: tablePermissions,
            subModules: [
                {
                    subModuleName: 'Patient Overview',
                    identifier: 'CONTAINER_PATIENT',
                    permissions: [read],
                },
            ],
        },
    ],
};
