import { useEffect, useRef, useState } from "react";
import { Field, ErrorMessage, FormikValues } from "formik";
import useGetRequest from "../../../../../hooks/getRequest.query";
import { getDoctorQualifications } from "../../../../../services/doctor/DoctorOnboarding.hook";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";

const Step2 = ({ values, setFieldValue, setFieldTouched }: FormikValues) => {
    const fileRef = useRef<HTMLInputElement>(null);
    const [image, setImage] = useState<File | null>();
    const [preview, setPreview] = useState<string | null | undefined>();

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(image);
        } else {
            setPreview(null);
        }
    }, [image]);

    const { data, refetch }: any = useGetRequest(
        "getDoctorQualifications",
        async () => await getDoctorQualifications(),
        {
            retry: false,
            staleTime: 100000,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        refetch();
    }, []);

    const qualifications = data?.data?.data?.qualifications;

    const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === "image") {
            await setFieldValue("photo", file);
            await setFieldTouched("photo"); // Trigger validation immediately
            setImage(file);
        } else {
            setFieldValue("photo", null);
            setImage(null);
        }
    };
    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Profile Settings</h2>
            </div>

            {/* Picture */}
            <div className="row mb-10  ">
                <div className="">
                    <label className="form-label required">Picture</label>
                </div>
                <div className="cursor-pointer d-flex col-lg-12">
                    <div className="col-lg-6">
                        {preview ? (
                            <img
                                src={preview}
                                alt="preview"
                                width="150px"
                                height="150px"
                                style={{ objectFit: "cover" }}
                            />
                        ) : (
                            <img
                                src={toAbsoluteUrl("/media/ikure/blank.png")}
                                alt="preview"
                                width="150px"
                                height="150px"
                                style={{ objectFit: "cover" }}
                            />
                        )}
                    </div>
                    <div className="d-flex col-lg-6 justify-content-between align-items-center">
                        <input
                            data-testid="photo"
                            ref={fileRef}
                            hidden
                            type="file"
                            className="form-control form-control-lg  form-control-solid"
                            accept="image/*"
                            onChange={handleFileChange}
                            //   onChange={(event: any) => {
                            //     const file = event?.target?.files[0];
                            //     if (file && file.type.substr(0, 5) === "image") {
                            //       setFieldValue("photo", file);
                            //       setImage(file);
                            //     } else {
                            //       setImage(null);
                            //     }
                            //   }}
                        />
                        <button
                            className="btn btn-sm"
                            onClick={(event) => {
                                event.preventDefault();
                                fileRef?.current?.click();
                            }}
                            data-testid="upload-btn"
                        >
                            <i className="fs-3x fas fa-cloud-upload-alt"></i>
                        </button>
                    </div>
                </div>

                <div className="text-danger mt-2">
                    <ErrorMessage name="photo" />
                </div>
            </div>

            {/* Qualification */}
            <div className="fv-row mb-10">
                <label className="form-label required">Qualification</label>

                <Field
                    data-testid="qualification"
                    as="select"
                    name="qualification"
                    className="form-select form-select-lg form-select-solid"
                >
                    <option value="">Select your qualification</option>
                    {qualifications?.map((qualification: any) => {
                        return (
                            <>
                                <option value={qualification}>
                                    {qualification}
                                </option>
                            </>
                        );
                    })}
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="qualification" />
                </div>
            </div>

            {/* Birthdate */}
            <div className="fv-row col-lg-12 mb-10">
                <label className="form-label required">Birthdate</label>
                <Field
                    data-testid="birthdate"
                    name="birthdate"
                    id="birthdate"
                    as="input"
                    type="date"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="birthdate" />
                </div>
            </div>

            {/* Age */}
            <div className="fv-row mb-10">
                <label className="d-flex align-items-center form-label">
                    <span className="">Age</span>
                </label>

                <Field
                    data-testid="age"
                    name="age"
                    placeholder="Enter your age"
                    className="form-control form-control-lg form-control-solid"
                />
                <div className="text-danger mt-2">
                    <ErrorMessage name="age" />
                </div>
            </div>

            {/* Gender */}
            <div className="fv-row mb-10">
                <label className="form-label required">Gender</label>
                <Field
                    data-testid="sex"
                    as="select"
                    name="sex"
                    className="form-select form-select-lg form-select-solid"
                >
                    <option value="">Select your gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                </Field>

                <div className="text-danger mt-2">
                    <ErrorMessage name="sex" />
                </div>
            </div>

            {/* Language */}
            <div className="fv-row col-lg-12 mb-10">
                <label className="form-label required disabled">Language</label>
                <Field
                    data-testid="language"
                    as="select"
                    name="language"
                    className="form-select form-select-lg form-select-solid"
                >
                    <option value="">Select your language</option>
                    <option value="English">English</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Kannada">Kannada</option>
                    <option value="Telugu">Telugu </option>
                    <option value="Tamil">Tamil</option>
                    <option value="Bengali">Bengali</option>
                    <option value="Malayalam">Malayalam</option>
                    <option value="Vietnamese">Vietnamese</option>
                </Field>
                <div className="text-danger mt-2">
                    <ErrorMessage name="language" />
                </div>
            </div>
        </div>
    );
};

export { Step2 };
