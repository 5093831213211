import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import toast, { Toaster } from 'react-hot-toast';
import {
  getErrorMessage,
  isErrorMsgNotReadable,
} from '../../../../common/utils/genericErrors';

type Props = {
  mutate: Function;
  setApiData: Function;
  setPage: Function;
  setIsSearch: Function;
};

const SearchBox = ({ mutate, setApiData, setPage, setIsSearch }: Props) => {
  const successToastMsg = () => {
    toast.success(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center ">
          {/* {successMsg} */}
          Product found!
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'success-toast',
      }
    );
  };

  const errorToastMsg = (errorStr: string) => {
    toast.error(
      (t) => (
        <span className="p-2 fs-4 d-flex align-items-center justify-content-center w-100">
          {errorStr}
          <button
            className="ms-15"
            style={{ backgroundColor: 'transparent', border: '0' }}
            onClick={() => toast.dismiss(t.id)}
          >
            <i className="fs-4 fas fa-times text-hover-primary"></i>
          </button>
        </span>
      ),
      {
        duration: 5000,
        position: 'top-right',
        id: 'error-toast',
      }
    );
  };

  return (
    <div className="card-toolbar">
      <Toaster />
      <div className="py-2 me-20">
        <Formik
          initialValues={{
            searchBy: 'productName',
            searchName: '',
          }}
          // validationSchema={searchSchema}
          onSubmit={(values: any, onSubmitProps) => {
            const payload =
              values?.searchBy === 'productName'
                ? {
                    productName: values.searchName?.trim(),
                  }
                : values?.searchBy === 'genericName'
                ? {
                    genericName: values.searchName?.trim(),
                  }
                : {
                    productCode: values.searchName?.trim(),
                  };
            mutate(payload, {
              onSuccess: (data: any) => {
                if (data) {
                  setApiData(data);
                  setIsSearch(true);
                  setPage(1);
                  successToastMsg();
                  setTimeout(() => {
                    onSubmitProps.resetForm();
                  }, 1500);
                }
              },
              onError: (error: any) => {
                errorToastMsg(
                  isErrorMsgNotReadable(error?.response?.data?.message)
                    ? getErrorMessage(error?.response?.status)
                    : error?.response?.data?.message
                );
                onSubmitProps.resetForm();
              },
            });
          }}
        >
          {(formProps) => {
            const placeholderText =
              formProps?.values?.searchBy === 'productName'
                ? 'Product Name'
                : formProps?.values?.searchBy === 'genericName'
                ? 'Generic Name'
                : 'Product Code';
            return (
              <Form className="w-100 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex">
                  <div className="d-flex me-5">
                    <Field
                      name="searchBy"
                      as="select"
                      className="form-control form-select form-control-solid"
                    >
                      <option value="productName" selected>
                        Product Name
                      </option>
                      <option value="genericName">Generic Name</option>
                      <option value="productCode">Product Code</option>
                    </Field>
                  </div>
                  <div className="d-flex">
                    <Field
                      name="searchName"
                      placeholder={`Search by ${placeholderText}`}
                      className="form-control form-control-lg form-control-solid"
                    />
                    <button
                      type="submit"
                      className="ms-3 btn btn-sm btn-primary"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="w-100 d-flex flex-column align-items-center text-danger mt-1">
                  <ErrorMessage name="searchName" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SearchBox;
