import { useEffect, useMemo, useState } from 'react';
import { useNavbar } from '../../context/NavbarContext';
import useGetRequest from '../../hooks/getRequest.query';
import {
    getAllTaskByFacility,
    getIkureConnectList,
} from '../../services/TaskManagement.hook';
import { IkureConnectTable } from './table/IkureConnectTable';
import {
    getErrorMessage,
    isErrorMsgNotReadable,
} from '../../common/utils/genericErrors';
import { AxiosError } from 'axios';
import { ApiError } from '../../common/utils/types/axiosError';

type Filter = {
    chwId: string;
    status: string;
    fromDate: string;
    toDate: string;
    taskType: string;
};
const IkureConnectPage = () => {
    const { selectedFacility } = useNavbar();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [filterData, setFilterData] = useState<Filter>({
        chwId: '',
        status: '',
        fromDate: '',
        toDate: '',
        taskType: '',
    });

    const facilityCode = localStorage.getItem('facilityCode');

    const { data, isLoading, isFetching, isError, error, refetch } =
        useGetRequest(
            ['getIkureConnectList', pageNumber, pageLimit],
            () =>
                getIkureConnectList({
                    facilityCode,
                    page: pageNumber,
                    limit: pageLimit,
                }),
            { staleTime: 100000, refetchOnWindowFocus: false }
        );
    const TaskData = data?.data?.data?.tasks;
    const totalTaskEntites = data?.data?.results;
    const totalAvailablePages = Math.ceil(totalTaskEntites / pageLimit);

    const errorMsg = useMemo(() => {
        const axiosError = error as AxiosError<ApiError>;
        const errorMessage = axiosError?.response?.data?.message as string;
        return isErrorMsgNotReadable(errorMessage)
            ? getErrorMessage(axiosError?.response?.status as number)
            : errorMessage;
    }, [error]);

    const successMsg = data?.data?.data?.message;

    useEffect(() => {
        refetch();
    }, [filterData, selectedFacility]);
    return (
        <>
            <div className="mt-5">
                <IkureConnectTable
                    bodyProps={TaskData}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    isIkureConnectError={isError}
                    errorMsg={errorMsg}
                    currentPageNumber={pageNumber}
                    setCurrentPageNumber={setPageNumber}
                    totalIkureConnectPages={totalAvailablePages}
                    totalIkureConnectEntites={totalTaskEntites}
                    pageLimit={pageLimit}
                    setPageLimit={setPageLimit}
                    successMsg={successMsg}
                    refetch={refetch}
                />
            </div>
        </>
    );
};

export default IkureConnectPage;
